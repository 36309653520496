import Checkout from '../views/Checkout/Checkout';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { PromoActions } from '../redux/actions';
import { compose } from 'redux';
import WithAuthRedirect from '../hoc/withAuthRedirect.js';
import withLocation from '../hoc/withSettingLocation';

const mapStateToProps = ({ checkout, promo, user }) => ({
  selectedGood: checkout.selectedGood,
  invoiceError: checkout.invoiceError,
  promoValue: promo.promoValue,
  enablePromo: promo.enablePromo,
  validMail: checkout.validMail,
  mailForNotification: checkout.mailForNotification,
  promoCheck: promo.promoCheck,
  cancelSub: user.cancelSub,
  checkedPaySys: checkout.checkedPaySys
});

export default compose(withLocation, connect(mapStateToProps, PromoActions), WithAuthRedirect, withRouter)(Checkout);
