import { UserActions } from './';

const Actions = {
  initializedSucces: () => ({ type: 'APP:INITIALIZED_SUCCES' }),
  initializeApp: () => async (dispatch) => {
    await dispatch(UserActions.fetchUserData());
    dispatch(Actions.initializedSucces());
  }
};

export default Actions;
