// Core
import React from 'react';

// Sryles
import { Form, Jumbotron, Button } from 'react-bootstrap';
import { Container, Input } from '@material-ui/core';

// Components
import Manifest from './PaySistemUtils/Manifest';

import { useTranslation } from 'react-i18next';

const Intercassa = (props) => {
  const { checkedPaySys, invoiceID, selectedGood, isInvoiceIdLoading } = props;

  const { t } = useTranslation();

  return (
    <Jumbotron fluid>
      <Container>
        <Manifest checkedPaySys={checkedPaySys} invoiceID={invoiceID} isInvoiceIdLoading={isInvoiceIdLoading} />
        <Form name="payment" method="post" action="https://sci.interkassa.com/">
          <fieldset disabled={isInvoiceIdLoading}>
            <Input type="hidden" name="ik_co_id" value="5d4423f81ae1bdaf028b4567" />
            <Input type="hidden" name="ik_am" value={selectedGood.price} />
            <Input type="hidden" name="ik_cur" value="USD" />
            <Input type="hidden" name="ik_desc" value={selectedGood.license} />
            <Input type="hidden" name="ik_pm_no" value={invoiceID} />
            <Button className="pay_button" variant="success" type="submit" value="Pay">
              {t('Checkout.pay')}
            </Button>
          </fieldset>
        </Form>
      </Container>
    </Jumbotron>
  );
};

export default Intercassa;
