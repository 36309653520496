// Core
import React from 'react';

//Components
import MySwitch from '../../../UI/MySwith';

// Styles
import { Card, Col, Row, Form } from 'react-bootstrap';
import s from './Cookie.module.css';

import { useTranslation } from 'react-i18next';

const Cookie = ({ cookie, changeInputs, isUsingNetscape, setChangeCookieType }) => {
  const { t } = useTranslation();
  const ATTENTION_MESSAGE = (
    <div style={{ width: '90%', marginBottom: 0 }} className="alert alert-yellow alert-dismissible fade show" role="alert">
      <strong>{t('Dashboard.config.Attention.title')}</strong> {t('Dashboard.config.Attention.cookie_text')}
    </div>
  );

  return (
    <>
      <Row>
        <Col md={12}>
          <Card className={s.card}>
            <Row className="justify-content-center">
              <h5 style={{ fontWeight: 'bold' }}>IMPORT COOKIE </h5>
            </Row>
            {ATTENTION_MESSAGE}
            <Row className={s.swithContainer}>
              <Form.Label className={`d-flex align-items-center ${s.label}`}>JSON</Form.Label>
              <MySwitch onChange={() => setChangeCookieType(!isUsingNetscape)} checked={isUsingNetscape} />
              <Form.Label className={`d-flex align-items-center ${s.label}`}>NETSCAPE</Form.Label>
            </Row>
            <textarea placeholder="PASTE YOUR COOKIE HERE" rows="10" value={cookie} onChange={(e) => changeInputs(e)} id="cookie" type="text" />
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default Cookie;
