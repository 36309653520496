/* eslint-disable no-unused-expressions */
import React from 'react';
import Button from 'react-bootstrap/Button';
import PayMethods from './components/PayMethods';
import Jumbotron from 'react-bootstrap/Jumbotron';
import Container from '@material-ui/core/Container';

import { Redirect } from 'react-router-dom';
import { CheckoutActions } from '../../redux/actions';
import { connect } from 'react-redux';
import Promos from './components/Promos';
import Header from './components/Header';

import { useTranslation } from 'react-i18next';

const Checkout = ({ selectedGood, enablePromo, invoiceError, checkedPaySys, promoValue, promoCheck, setCanselPurchase, history, updatePromoValue, onPromo, createInvoice }) => {
  const { t } = useTranslation();
  if (selectedGood.license === undefined || selectedGood.license === null) {
    return <Redirect to="/account" />;
  }

  const handleCanselPurchase = () => {
    setCanselPurchase();
    history.push('/account');
  };

  const handleCheckout = async (paySys) => {
    history.push(`/checkout/${paySys}`);
    await createInvoice(selectedGood, checkedPaySys);
  };

  return (
    <div>
      <Jumbotron fluid>
        <Container>
          <Header license={selectedGood.license} error={invoiceError} price={selectedGood.price} desc={selectedGood.desc} />
          <PayMethods selectedGood={selectedGood} checkedPaySys={checkedPaySys} />
          <Promos
            checkedPaySys={checkedPaySys}
            onPromo={onPromo}
            enablePromo={enablePromo}
            promoValue={promoValue}
            selectedGood={selectedGood}
            promoCheck={promoCheck}
            updatePromoValue={updatePromoValue}
          />
          <Button variant="danger" onClick={() => handleCanselPurchase()}>
            {t('Checkout.checkoutButtons.danger')}
          </Button>
          <Button variant="success" onClick={() => handleCheckout(checkedPaySys)}>
            {t('Checkout.checkoutButtons.success')}
          </Button>
        </Container>
      </Jumbotron>
    </div>
  );
};

export default connect(null, CheckoutActions)(Checkout);
