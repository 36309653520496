// Core
import React from 'react';
import './Manifest.module.css';

import { useTranslation } from 'react-i18next';
import { Alert } from 'react-bootstrap';

const Manifest = ({ invoiceID, isInvoiceIdLoading, paddle = true }) => {
  const { t } = useTranslation();

  const isInvoiceIDLoaded = isInvoiceIdLoading ? <h1>{t('Checkout.wait')}</h1> : <h1>Invoice id #{invoiceID}</h1>;

  return (
    <>
      <Alert style={{ marginBottom: 20 }} variant="warning">
        <Alert.Heading>{t('Checkout.paymentErrors.title')}</Alert.Heading>
        <span>
          {t('Checkout.paymentErrors.text')}
          &nbsp;
          <a href="https://t.me/aezakmisupport" target="_blank">
            <b>@aezakmisupport</b>
          </a>
        </span>
      </Alert>

      {isInvoiceIDLoaded}
      <p>{t('Checkout.after')}</p>
      <br />
      <br />

      {paddle && <b>{t('Checkout.notice')}</b>}
    </>
  );
};

export default Manifest;
