import React, { useState } from 'react';
import axios from 'axios';
import * as Yup from 'yup';
import { Formik, Form } from 'formik';

import { MDBBtn, MDBInput } from 'mdbreact';
import AuthHeader from '../Auth/components/Header';
import Auth from '../Auth/components/Auth';

const REG_MAIL_RU = /(\b@mail.ru|@inbox.ru|@list.ru|@bk.ru\b)/;

const SignUp = (props) => {
  const [serverResponse, setServerResponse] = useState('');
  const PROFESSIONS_TYPES = [
    { value: '', name: '' },
    { value: 'Facebook', name: 'Facebook' },
    { value: 'Google', name: 'Google' },
    { value: 'Betting Arbitrage', name: 'Betting Arbitrage' },
    { value: 'Avito', name: 'Avito' },
    { value: 'Bonus Hunting', name: 'Bonus Hunting' },
    { value: 'Browser Games', name: 'Browser Games' },
    { value: 'Amazon', name: 'Amazon' },
    { value: 'Other', name: 'Other' }
  ];

  const { history } = props;

  const toSingIn = () => {
    history.push('/auth');
  };

  const handleRegister = async (values, setSubmitting) => {
    const { login, mail, telegram, password, profession } = values;
    let googleClientId = 5555;
    try {
      googleClientId = window.ga.getAll()[0].get('clientId');
    } catch (err) {
      console.log(err);
    }
    // 2 проверки перед отправкой на сервак
    if (REG_MAIL_RU.test(mail)) return setServerResponse('Mail.ru is not supported');
    if (!profession) return setServerResponse('Profession filed is required');

    setSubmitting(true);
    axios
      .post(
        process.env.REACT_APP_PUBLIC_API + '/api/sign_up',
        {
          login,
          mail,
          telegram,
          password,
          googleClientId,
          profession
        },
        {
          headers: {
            Authorization: 'REGISTER-REGISTER-REGISTER'
          }
        }
      )
      .then((res) => {
        window.location.href = 'https://aezakmi.run/succesregistration';
        setSubmitting(false);
        setServerResponse(res.data);
      })
      .catch((err) => {
        if (err.response) {
          if (err.response.status === 400) {
            setServerResponse(err.response.data.message);
            setSubmitting(false);
            return;
          }
          setSubmitting(false);
          return setServerResponse(err.response.data.message);
        }
        setSubmitting(false);
        setServerResponse('Server is unavailable');
      });
  };

  return (
    <div style={{ marginTop: 10 }}>
      <Formik
        initialValues={{
          login: '',
          mail: '',
          telegram: '',
          password: '',
          submitPassword: '',
          profession: ''
        }}
        validationSchema={Yup.object().shape({
          login: Yup.string().required('This field must be required').max(15, 'Max length is 15 simbols!'),
          mail: Yup.string().email('Mail must be a valid email').required('This field must be required'),
          telegram: Yup.string(),
          password: Yup.string().required('This field must be required'),
          profession: Yup.string(),
          submitPassword: Yup.string()
            .required('This field must be required')
            .when('password', {
              is: (val) => (val && val.length > 0 ? true : false),
              then: Yup.string().oneOf([Yup.ref('password')], 'Both password need to be the same')
            })
        })}
        onSubmit={async (values, { setSubmitting, props }) => {
          await handleRegister(values, setSubmitting);
        }}
      >
        {(props) => {
          const { login, mail, telegram, password, submitPassword, profession, errors, handleSubmit, handleChange, isSubmitting, touched, handleBlur } = props;

          let disabled = isSubmitting || errors.login || errors.mail || errors.telegram || errors.password || errors.submitPassword || errors.profession;

          return (
            <>
              <AuthHeader />
              <Auth text="Dont have account?" link="/signup" title={'SignUp'} isSignUp={true}>
                <Form onSubmit={handleSubmit}>
                  <MDBInput value={login} name="login" onChange={handleChange} type="text" id="login" label="Login" onBlur={handleBlur}>
                    {errors.login && touched.login && <div className={'error'}>{errors.login}</div>}
                  </MDBInput>

                  <MDBInput style={{ marginTop: 5 }} value={mail} name="mail" onChange={handleChange} type="text" id="mail" label="Mail" onBlur={handleBlur}>
                    {errors.mail && touched.mail && <div className={'error'}>{errors.mail}</div>}
                  </MDBInput>

                  <MDBInput style={{ marginTop: 5 }} name="telegram" value={telegram} onChange={handleChange} type="text" id="telegram" label="Telegram" onBlur={handleBlur}>
                    <div className="starsignup">*</div>
                    {errors.telegram && touched.telegram && <div className={'error'}>{errors.telegram}</div>}
                  </MDBInput>
                  <label
                    style={{
                      textAlign: 'left',
                      color: '#757575',
                      fontWeight: 300,
                      width: '100%',
                      position: 'relative'
                    }}
                  >
                    Why do you use AEZAKMI?
                    <select className="signupselect" name="profession" id="profession" value={profession} onChange={handleChange} onBlur={handleBlur}>
                      {PROFESSIONS_TYPES.map(({ value, name }, key) => (
                        <option key={key} value={value}>
                          {name}
                        </option>
                      ))}
                    </select>
                  </label>
                  {errors.profession && touched.profession && <div className={'error'}>{errors.profession}</div>}
                  <MDBInput style={{ marginTop: 5 }} name="password" value={password} onChange={handleChange} type="password" id="password" label="Password" onBlur={handleBlur}>
                    {errors.password && touched.password && <div className={'error'}>{errors.password}</div>}
                  </MDBInput>
                  <MDBInput
                    style={{ marginTop: 5 }}
                    name="submitPassword"
                    value={submitPassword}
                    onChange={handleChange}
                    type="password"
                    id="submitPassword"
                    label="Submit Password"
                    onBlur={handleBlur}
                  >
                    {errors.submitPassword && touched.submitPassword && <div className={'error'}>{errors.submitPassword}</div>}
                  </MDBInput>
                  <div className={'error'}>{serverResponse}</div>
                  <div style={{ fontSize: 11, color: '#757575' }}>*Receive 1 more day by filling that fields</div>
                  <MDBBtn type="submit" className="hover-button margin-top-10" color="yellow" style={{ width: 150, margin: '0 auto' }} disabled={disabled}>
                    Sign Up
                  </MDBBtn>
                </Form>
                <MDBBtn type="submit" className={'hover-button margin-top-10'} color="black" style={{ width: 150, margin: '0 auto' }} disabled={isSubmitting} onClick={toSingIn}>
                  Login
                </MDBBtn>
              </Auth>
            </>
          );
        }}
      </Formik>
    </div>
  );
};
export default SignUp;
