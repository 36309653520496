// Core
import React from 'react';

// Sryles
import { Form, Jumbotron, Button } from 'react-bootstrap';
import { Container, Input } from '@material-ui/core';

// Components
import Manifest from './PaySistemUtils/Manifest';

// Other
import md5 from 'js-md5';

import { useTranslation } from 'react-i18next';

const Enot = (props) => {
  const { t } = useTranslation();
  const { checkedPaySys, invoiceID, selectedGood, isInvoiceIdLoading } = props;
  const paymentHash = md5(`2528:${selectedGood.price}:qqj67Qkh5YA765sgG2zNSKQqcwCzgKfA:${invoiceID}`);

  return (
    <Jumbotron fluid>
      <Container>
        <Manifest checkedPaySys={checkedPaySys} invoiceID={invoiceID} isInvoiceIdLoading={isInvoiceIdLoading} />
        <form method='get' action='https://enot.io/pay'>
            <Input type='hidden' name='m' value={2528} />
            <Input type='hidden' name='oa' value={selectedGood.price} />
            <Input type='hidden' name='o' value={invoiceID} />
            <Input type='hidden' name='s' value={paymentHash} />
            <Input type='hidden' name='cr' value='USD' />
            <Input type='hidden' name='c' value="Aezakmi Tariff Payment" />
            <Input type='hidden' name='success_url' value="https://aezakmi.run/successpayment" />
            <Input type='hidden' name='fail_url' value="https://aezakmi.run/paymenterror" />
            <Input type='hidden' name='cf' value={invoiceID} />
            {invoiceID && !isInvoiceIdLoading && 
              <Button className="pay_button" variant="success" type="submit" value="Pay">
                {t('Checkout.pay')}
              </Button>
            }
        </form>
      </Container>
    </Jumbotron>
  );
};

export default Enot;
