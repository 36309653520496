import { randomIntFromInterval } from './';

export function randomSeed() {
  let result = '';
  for (let i = 0; i < 32; i++) {
    const now = result;
    if (i === 0) {
      let seedy = randomIntFromInterval(65, 165);
      result = `${seedy}`;
    } else if (i === 1) {
      let seedy = randomIntFromInterval(135, 185);
      result = `${now},${seedy}`;
    } else if (i === 2) {
      let seedy = randomIntFromInterval(5, 25);
      result = `${now},${seedy}`;
    } else {
      let seedy = randomIntFromInterval(32, 256);
      result = `${now},${seedy}`;
    }
  }
  let seedy1 = randomIntFromInterval(65, 165);
  let seedy2 = randomIntFromInterval(135, 185);
  let seedy3 = randomIntFromInterval(5, 25);
  let seedy4 = randomIntFromInterval(32, 256);
  let seedy5 = randomIntFromInterval(32, 256);
  let seedy6 = randomIntFromInterval(32, 256);
  let seedy7 = randomIntFromInterval(32, 256);
  let seedy8 = randomIntFromInterval(32, 256);
  let seedy9 = randomIntFromInterval(32, 256);
  let seedy10 = randomIntFromInterval(32, 256);
  let seedy11 = randomIntFromInterval(32, 256);
  let seedy12 = randomIntFromInterval(32, 256);
  let seedy13 = randomIntFromInterval(32, 256);
  let seedy14 = randomIntFromInterval(32, 256);
  let seedy15 = randomIntFromInterval(32, 256);
  let seedy16 = randomIntFromInterval(32, 256);
  let seedy17 = randomIntFromInterval(32, 256);
  let seedy18 = randomIntFromInterval(32, 256);
  let seedy19 = randomIntFromInterval(32, 256);
  let seedy20 = randomIntFromInterval(32, 256);
  let seedy21 = randomIntFromInterval(32, 256);
  let seedy22 = randomIntFromInterval(32, 256);
  let seedy23 = randomIntFromInterval(32, 256);
  let seedy24 = randomIntFromInterval(32, 256);
  let seedy25 = randomIntFromInterval(32, 256);
  let seedy26 = randomIntFromInterval(32, 256);
  let seedy27 = randomIntFromInterval(32, 256);
  let seedy28 = randomIntFromInterval(32, 256);
  let seedy29 = randomIntFromInterval(32, 256);
  let seedy30 = randomIntFromInterval(32, 256);
  let seedy31 = randomIntFromInterval(32, 256);
  let seedy32 = randomIntFromInterval(32, 256);
  let seed32 =
    seedy1 +
    ',' +
    seedy2 +
    ',' +
    seedy3 +
    ',' +
    seedy4 +
    ',' +
    seedy5 +
    ',' +
    seedy6 +
    ',' +
    seedy7 +
    ',' +
    seedy8 +
    ',' +
    seedy9 +
    ',' +
    seedy10 +
    ',' +
    seedy11 +
    ',' +
    seedy12 +
    ',' +
    seedy13 +
    ',' +
    seedy14 +
    ',' +
    seedy15 +
    ',' +
    seedy16 +
    ',' +
    seedy17 +
    ',' +
    seedy18 +
    ',' +
    seedy19 +
    ',' +
    seedy20 +
    ',' +
    seedy21 +
    ',' +
    seedy22 +
    ',' +
    seedy23 +
    ',' +
    seedy24 +
    ',' +
    seedy25 +
    ',' +
    seedy26 +
    ',' +
    seedy27 +
    ',' +
    seedy28 +
    ',' +
    seedy29 +
    ',' +
    seedy30 +
    ',' +
    seedy31 +
    ',' +
    seedy32;
  return seed32;
}
