import React from 'react';
import { Form, Col } from 'react-bootstrap';

const PayMethodItem = ({ name, height, image, setPaySys, checkedPaySys, margin, usePromocode, desc }) => {
  const isPromocodeDisabled = name === 'paypal' && usePromocode;

  let imgElement = <img height={`${height}px`} src={image} alt="logo" />;
  if (name === 'enot') {
    imgElement = <img width="35" src={image} alt="logo" />;
  }

  return (
    <Col style={{ marginBottom: 10 }} lg="6" md="6" sm="12">
      <Form.Check inline label="Paddle" type={'radio'}>
        <Form.Check.Input disabled={isPromocodeDisabled} type={'radio'} onChange={() => setPaySys(name)} checked={checkedPaySys === name} />
        <Form.Check.Label disabled={isPromocodeDisabled} style={{ marginLeft: margin, display: 'flex', alignItems: 'center' }} onClick={isPromocodeDisabled ? null : () => setPaySys(name)}>
          {imgElement}
          {desc && <div style={{marginLeft: 5, fontSize: 10 }}>{desc}</div>}
        </Form.Check.Label>
      </Form.Check>
    </Col>
  );
};

export default PayMethodItem;
