export const initialState = {
  selectedGood: {
    login: null,
    license: null,
    price: null,
    usePromocode: false,
    discount: 0,
    promocode: null
  },
  goods: [
    {
      license: 1,
      price: 69.0,
      code: null,
      discount: 0,
      paddle_id: 582116,
      usePromocode: false,
      promocode: null
    },
    {
      license: 2,
      price: 139.0,
      code: null,
      discount: 0,
      paddle_id: 582117,
      usePromocode: false,
      promocode: null
    },
    {
      license: 3,
      price: 249.0,
      code: null,
      discount: 0,
      paddle_id: 582118,
      usePromocode: false,
      promocode: null
    },
    {
      license: 4,
      price: 345.0,
      code: null,
      discount: 0,
      paddle_id: 597341,
      usePromocode: false,
      promocode: null
    },
    {
      license: 5,
      price: 695.0,
      code: null,
      discount: 0,
      paddle_id: 597342,
      usePromocode: false,
      promocode: null
    },
    {
      license: 6,
      price: 1245.0,
      code: null,
      discount: 0,
      paddle_id: 597343,
      usePromocode: false,
      promocode: null
    },
    {
      license: 7,
      price: 690.0,
      code: null,
      discount: 0,
      paddle_id: 597344,
      usePromocode: false,
      promocode: null
    },
    {
      license: 8,
      price: 1390.0,
      code: null,
      discount: 0,
      paddle_id: 597345,
      usePromocode: false,
      promocode: null
    },
    {
      license: 9,
      price: 2490.0,
      code: null,
      discount: 0,
      paddle_id: 597346,
      usePromocode: false,
      promocode: null
    }
  ],
  invoiceID: null,
  invoiceError: false,
  isInvoiceIdLoading: false,
  checkedPaySys: 'visa-ru'
};

const CheckoutReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'CHECKOUT:SET_PURCHASE':
      return {
        ...state,
        selectedGood: action.payload
      };

    case 'CHECKOUT:UPDATE_SELECTED_GOOD_WITH_PROMOCODE':
      return {
        ...state,
        selectedGood: action.payload
      };

    case 'CHECKOUT:SET_INVOICE_ID_LOADING':
      return {
        ...state,
        invoiceError: false,
        isInvoiceIdLoading: action.payload
      };
    case 'CHECKOUT:SET_INVOICE_ID_SUCCESS':
      return {
        ...state,
        invoiceID: action.payload
      };
    case 'CHECKOUT:SET_INVOICE_ID_FAILURE':
      return {
        ...state,
        invoiceError: true
      };
    case 'CHECKOUT:SET_CANSEL_PURCHASE':
      return {
        ...state,
        selectedGood: {
          login: null,
          license: null,
          price: null,
          usePromocode: false,
          discount: 0,
          promocode: null
        }
      };
    case 'SET:CHECKED_PAY_SYSTEM':
      return {
        ...state,
        checkedPaySys: action.payload
      };
    default:
      return {
        ...state
      };
  }
};

export default CheckoutReducer;
