// Core
import React from 'react';

// Sryles
import { Form, Jumbotron, Button } from 'react-bootstrap';
import { Container, Input } from '@material-ui/core';

// Assets
import promo from '../../images/Paddle/promo.png';

// Components
import Manifest from './PaySistemUtils/Manifest';

import { useTranslation } from 'react-i18next';

const Paddle = (props) => {
  const { checkedPaySys, invoiceID, selectedGood, payWithPaddle, isInvoiceIdLoading, notifyMail, updateNotifyMail, validateMail } = props;

  const { t } = useTranslation();

  const { license } = selectedGood;
  const defaultTarifs = license === 1 || license === 2 || license === 3;

  const PROMO_IMAGE = defaultTarifs && (
    <>
      <h3 style={{ marginTop: 30 }}>{t('Checkout.promos')}</h3>
      <img style={{ marginBottom: 30 }} src={promo} alt="promo" width="300px" />
    </>
  );

  const handlePay = () => {
    if (!validateMail || isInvoiceIdLoading) return null;
    payWithPaddle(invoiceID, selectedGood, notifyMail);
  };

  return (
    <Jumbotron fluid>
      <Container>
        <Manifest checkedPaySys={checkedPaySys} invoiceID={invoiceID} isInvoiceIdLoading={isInvoiceIdLoading} />
        <div>
          {PROMO_IMAGE}
          <Form>
            <Input type="hidden" name="ik_co_id" value="5d4423f81ae1bdaf028b4567" />
            <Input type="mail" onChange={(e) => updateNotifyMail(e)} placeholder={'e-mail to receive a check'} name="mail" value={notifyMail} />
            <Input type="hidden" name="ik_pm_no" value={!invoiceID ? '' : invoiceID} onChange={() => console.log('')} />
          </Form>
          <Button className="pay_button" variant="success" type="submit" value="Pay" onClick={handlePay}>
            {t('Checkout.pay')}
          </Button>
        </div>
      </Container>
    </Jumbotron>
  );
};

export default Paddle;
