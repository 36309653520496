// Core
import React from 'react';

// Redux
import { CheckoutActions } from '../redux/actions';

// Components
import Enot from '../views/PaySystems/Enot';
import { compose } from 'redux';

// HOC
import { connect } from 'react-redux';
import withAuthRedirect from '../hoc/withAuthRedirect';
import withLocation from '../hoc/withSettingLocation';

const EnotContainer = (props) => {
  return <Enot {...props} />;
};

const mapStateToProps = ({ user, checkout }) => ({
  selectedGood: checkout.selectedGood,
  invoiceID: checkout.invoiceID,
  mail: user.mail,
  isInvoiceIdLoading: checkout.isInvoiceIdLoading,
  checkedPaySys: checkout.checkedPaySys
});

export default compose(withLocation, connect(mapStateToProps, CheckoutActions), withAuthRedirect)(EnotContainer);
