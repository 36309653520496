import { Card, Col, Form, Row } from 'react-bootstrap';
import { MDBBtn } from 'mdbreact';
import React from 'react';

import { useTranslation } from 'react-i18next';

const YourProfileTabs = ({
  userProfileName,
  changeForm,
  userProfileInfo,
  userProfileReady,
  fromCheckerLoaded,
  becomeUserProfile,
  deleteProfileFromBase,
  serverUserError,
  fromUserLoaded,
  loadingUserProcess
}) => {
  const status = serverUserError ? (
    <span
      style={{
        display: 'flex',
        alignItems: 'center'
      }}
      className="text-danger"
    >
      {' '}
      server is unavailable
    </span>
  ) : fromUserLoaded ? (
    <span
      style={{
        display: 'flex',
        alignItems: 'center'
      }}
      className="text-success"
    >
      <i className="material-icons" style={{ color: 'green' }}>
        check_box
      </i>
    </span>
  ) : loadingUserProcess ? (
    <span
      style={{
        display: 'flex',
        alignItems: 'center'
      }}
      className="text-warning"
    >
      <i className="material-icons" style={{ color: 'orange' }}>
        sync
      </i>
    </span>
  ) : (
    <span
      style={{
        display: 'flex',
        alignItems: 'center'
      }}
      className="text-danger"
    >
      <i className="material-icons" style={{ color: 'red' }}>
        sync_disabled
      </i>
    </span>
  );
  const { t } = useTranslation();
  return (
    <Row>
      <Col md={12}>
        <Card>
          <Row className={'d-flex align-items-center'}>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                width: '100%',
                justifyContent: 'flex-end',
                padding: 20
              }}
            >
              <Form.Control style={{ witdh: '100%' }} className="prof-select" value={userProfileName} id="users-profiles" as="select" onChange={(e) => changeForm(e)}>
                <option></option>
                {userProfileInfo.length !== 0
                  ? userProfileInfo.map((item, i) => (
                      <option value={item.profileName} key={i}>
                        {i + 1}.{item.profileName}
                      </option>
                    ))
                  : null}
              </Form.Control>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <MDBBtn
                  style={{ width: 200 }}
                  color="yellow"
                  className={'hover-button'}
                  disabled={!userProfileReady || fromCheckerLoaded || userProfileName === ''}
                  onClick={() => becomeUserProfile()}
                >
                  {t('Dashboard.edit.edit')}
                </MDBBtn>
                {status}
              </div>
              <MDBBtn
                style={{ width: 200 }}
                className={'hover-button'}
                color="danger"
                disabled={!userProfileReady || fromCheckerLoaded || userProfileName === ''}
                onClick={() => deleteProfileFromBase()}
              >
                {t('Dashboard.edit.remove')}
              </MDBBtn>
            </div>
          </Row>
        </Card>
      </Col>
    </Row>
  );
};

export default YourProfileTabs;
