import React from 'react';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import { withCookies } from 'react-cookie';

let mapStateToPropsForRedirect = ({ user }) => ({
  isAuth: user.isAuth
});

const withAuthRedirect = (Component) => {
  class RedirectComponent extends React.Component {
    render() {
      if (!this.props.isAuth) return <Redirect to={'/auth'} />;

      return <Component {...this.props} />;
    }
  }
  let ConnectedAuthRedirectComponent = compose(connect(mapStateToPropsForRedirect), withCookies, withRouter)(RedirectComponent);
  return ConnectedAuthRedirectComponent;
};

export default withAuthRedirect;
