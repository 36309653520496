import React from 'react';

const Agreement = (props) => (
  <div className={'text-center'} style={{ margin: '0 auto' }}>
    <p>
      By signing up you agree with{' '}
      <a rel="noopener noreferrer" href="https://aezakmi.run/termsofservices" target="_blank">
        Terms of Service
      </a>{' '}
      and{' '}
      <a rel="noopener noreferrer" href="https://aezakmi.run/privacypolicy" target="_blank">
        Privacy Policy
      </a>
    </p>
  </div>
);

export default Agreement;
