import axios from 'axios';
import { CheckoutActions } from './';

const Actions = {
  setPromoDataSuccess: (enablePromo) => ({
    type: 'PROMO:SET_DATA_SUCCESS',
    enablePromo
  }),
  setUsedPromo: () => ({ type: 'PROMO:SET_USED_PROMO' }),
  setServerBaseError: () => ({
    type: 'PROMO:SET_SERVER_BASE_ERROR',
    payload: true
  }),
  updatePromoValue: (e) => ({
    type: 'PROMO:UPDATE_PROMO_VALUE',
    payload: e.currentTarget.value
  }),
  onPromo: (enablePromo, code, selectedGood) => (dispatch) => {
    if (enablePromo) {
      const token = localStorage.getItem('token');
      axios
        .post(
          process.env.REACT_APP_PUBLIC_API + '/api/use_promo',
          { code },
          {
            headers: { authorization: token }
          }
        )
        .then((res) => {
          if (res.data.discount == null) {
            alert('Invalid Promo Code');
          } else {
            const discount = selectedGood.price - (selectedGood.price / 100) * res.data.discount;
            res.data.discount = Number(res.data.discount);

            const updatedGood = {
              license: selectedGood.license,
              price: +discount.toFixed(2),
              usePromocode: true,
              promocode: code,
              discount: res.data.discount,
              login: selectedGood.login,
              paddleDiscountId: res.data.promocode,
              paddle_id: selectedGood.paddle_id
            };

            dispatch(CheckoutActions.updateSelectedGoodWithPromocode(updatedGood));
            dispatch(Actions.setUsedPromo());
          }
        })
        .catch((err) => {
          dispatch(Actions.setServerBaseError(true));
        });
    } else {
      alert('Promocode disable for your account');
    }
  }
};

export default Actions;
