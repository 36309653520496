import React from 'react';
import { useLocation } from 'react-router-dom';

import { useTranslation } from 'react-i18next';

const withLocation = (Component) => {
  const RedirectComponent = (props) => {
    const { t } = useTranslation();
    const prevLocation = localStorage.getItem('location');
    if (
      prevLocation === '/checkout/paddle' ||
      prevLocation === '/checkout/paypal' ||
      prevLocation === '/checkout/alipay' ||
      prevLocation === '/checkout/bitcoin' ||
      prevLocation === '/checkout/qiwi' ||
      prevLocation === '/checkout/advcash' ||
      prevLocation === '/checkout/perfectmoney'
    ) {
    }
    const location = useLocation();
    localStorage.setItem('location', location.pathname);

    return <Component {...props} />;
  };
  return RedirectComponent;
};

export default withLocation;
