import React from 'react';
import { Card, Col, Form, Row } from 'react-bootstrap';

import { useTranslation } from 'react-i18next';

const GEOSettings = ({ initProfile, changeInputs, accept_language, language_Const1, languageConst1, sysTimeRegion, gpslon, gpslat }) => {
  const { t } = useTranslation();
  return (
    <>
      <div className="alert alert-yellow alert-dismissible fade show" role="alert">
        <strong>{t('Dashboard.config.Attention.title')}</strong> {t('Dashboard.config.Attention.geo_text')}
      </div>
      <Row>
        <Col md={12}>
          <Card>
            <h4 className={'text-center matrix-text'}>{t('Dashboard.config.advanced.geo')}</h4>
            <Row>
              <Form.Group as={Col} md="3">
                <Form.Label>Accept language</Form.Label>
                <Form.Control onChange={(e) => changeInputs(e, initProfile)} value={accept_language} id="proxy-accept-language" type="text" />
              </Form.Group>
              <Form.Group as={Col} md="2">
                <Form.Label>Lang 1</Form.Label>
                <Form.Control onChange={(e) => changeInputs(e, initProfile)} value={language_Const1} id="proxy-lang-1" type="text" />
              </Form.Group>
              <Form.Group as={Col} md="3">
                <Form.Label>Lang 2</Form.Label>
                <Form.Control onChange={(e) => changeInputs(e, initProfile)} value={languageConst1} id="proxy-lang-2" type="text" />
              </Form.Group>
              <Form.Group as={Col} md="3">
                <Form.Label>Time region</Form.Label>
                <Form.Control onChange={(e) => changeInputs(e, initProfile)} value={sysTimeRegion} id="proxy-time-region" type="text" />
              </Form.Group>
            </Row>
            <Row>
              <Form.Group as={Col} md="6">
                <Form.Label>Gps latitude</Form.Label>
                <Form.Control onChange={(e) => changeInputs(e, initProfile)} value={gpslat} id="proxy-lat" type="text" />
              </Form.Group>
              <Form.Group as={Col} md="6">
                <Form.Label>Gps longitude</Form.Label>
                <Form.Control onChange={(e) => changeInputs(e, initProfile)} value={gpslon} id="proxy-long" type="text" />
              </Form.Group>
            </Row>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default GEOSettings;
