import React from 'react';

import s from './License.module.css';
import { Alert } from 'react-bootstrap';

import { useTranslation } from 'react-i18next';

const License = ({ isLicenseActive, licenseStop, licenseType, cancelSub, removeSub, isLoading }) => {
  const { t } = useTranslation();
  // Формируем стили для кнопки
  let buttonStyles = `${s.info__button}`;
  if (!cancelSub) buttonStyles += ` ${s.button_disable}`;

  // Перевел дату в нужный формат
  const convertedLicense = new Date(licenseStop).toLocaleString().split(',')[0];

  // Сообщения при нормальной лицензии и если истекла
  const textWarning = <p className="mb-0">{t('License.expired.text')}</p>;
  const textSucecss = (
    <p className="mb-0">
      {t('License.active.start')} <b>{convertedLicense}</b> {t('License.active.with')}
      <b>{` ${licenseType.toUpperCase()}`}</b>
    </p>
  );

  // Загрузка данных о лицензии
  let renderTable = isLoading && (
    <Alert variant="warning">
      <hr />
      <Alert.Heading>{t('License.load')}</Alert.Heading>
    </Alert>
  );

  return (
    <>
      {renderTable}
      <Alert variant={isLicenseActive ? 'success' : 'warning'}>
        <Alert.Heading>{isLicenseActive ? t('License.active.header') : t('License.expired.header')}</Alert.Heading>
        <hr />

        <div className={s.info__container}>
          {isLicenseActive ? textSucecss : textWarning}
          {isLicenseActive && (
            <button className={buttonStyles} onClick={() => removeSub()} disabled={!cancelSub}>
              {!cancelSub ? t('License.renewal.off') : t('License.renewal.on')}
            </button>
          )}
        </div>
      </Alert>
    </>
  );
};

export default License;
