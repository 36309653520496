import React from 'react';

const AuthHeader = () => {
  return (
    <div className={'text-center'} style={{ margin: '0 auto' }}>
      <div className={'logo-div'} style={{ marginBottom: 20 }} />
      <h3 style={{ marginBottom: 50 }}>Cheat code for your anonymity</h3>
    </div>
  );
};

export default AuthHeader;
