import React from 'react';
import SignUpRu from '../views/SignUp/SignUpRu';
import SignUp from '../views/SignUp/SignUp';
import { withRouter } from 'react-router-dom';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { compose } from 'redux';
import withLocation from '../hoc/withSettingLocation';

class SignUpContainer extends React.Component {
  render() {
    if (this.props.isAuth) return <Redirect to={'/dashboard'} />;
    if (this.props.location.pathname === '/signupru') {
      return <SignUpRu {...this.props} />;
    }

    return <SignUp {...this.props} />;
  }
}

const mapStateToPropsForRedirect = ({ user }) => ({
  isAuth: user.isAuth
});

export default compose(withLocation, withRouter, connect(mapStateToPropsForRedirect))(SignUpContainer);
