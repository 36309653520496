import React from 'react';
import AuthForm from '../views/Auth/AuthForm';
import { withRouter } from 'react-router-dom';
import { withCookies } from 'react-cookie';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { compose } from 'redux';
import withLocation from '../hoc/withSettingLocation';

class AuthContainer extends React.Component {
  render() {
    if (this.props.isAuth) return <Redirect to={'/dashboard'} />;

    return <AuthForm {...this.props} />;
  }
}

const mapStateToPropsForRedirect = ({ user }) => ({
  isAuth: user.isAuth
});

export default compose(withLocation, withRouter, withCookies, connect(mapStateToPropsForRedirect))(AuthContainer);
