import axios from 'axios';
import { UserActions } from './';

const Paddle = window.Paddle;

const Actions = {
  setPurchase: (selectedGood) => ({
    type: 'CHECKOUT:SET_PURCHASE',
    payload: selectedGood
  }),
  updateSelectedGoodWithPromocode: (good) => ({
    type: 'CHECKOUT:UPDATE_SELECTED_GOOD_WITH_PROMOCODE',
    payload: good
  }),
  setInvoiceIDLoading: (payload) => ({
    type: 'CHECKOUT:SET_INVOICE_ID_LOADING',
    payload
  }),
  toPurchase: (goodID, goods, login) => (dispatch) => {
    let currentGood = goods[goodID];
    currentGood.login = login;

    dispatch(Actions.setPurchase(currentGood));
  },
  setCreateInvoiceIDSuccess: (invoiceID) => ({
    type: 'CHECKOUT:SET_INVOICE_ID_SUCCESS',
    payload: invoiceID
  }),
  setCreateInvoiceIDFailure: () => ({
    type: 'CHECKOUT:SET_INVOICE_ID_FAILURE'
  }),
  setCanselPurchase: () => ({
    type: 'CHECKOUT:SET_CANSEL_PURCHASE'
  }),
  setCheckedPaySystem: (payload) => ({
    type: 'SET:CHECKED_PAY_SYSTEM',
    payload
  }),
  createInvoice: (selectedGood, paySys) => async (dispatch) => {
    let currentPaySysMethod = null;
    switch (paySys) {
      case 'advcash':
        currentPaySysMethod = '/api/create_invoice';
        break;
      case 'qiwi':
        currentPaySysMethod = '/api/create_invoice';
        break;
      case 'perfectmoney':
        currentPaySysMethod = '/api/create_invoice';
        break;
        // case 'bitcoin':
        //   currentPaySysMethod = '/api/create_invoice_payssion';
        break;
      case 'alipay':
        currentPaySysMethod = '/api/create_invoice_payssion';
        break;
      case 'capitalist':
        currentPaySysMethod = '/api/create_invoice_capitalist';
      case 'visa':
      case "paypal":
        currentPaySysMethod = '/api/create_invoice_paddle';
        break;
      case 'visa-ru':
      case 'sbp':
      case 'usdt':
      case 'bitcoin':
        currentPaySysMethod = '/api/create_invoice_enot';
      default:
        break;
    }
    const token = localStorage.getItem('token');
    dispatch(Actions.setInvoiceIDLoading(true));
    await axios
      .post(`${process.env.REACT_APP_PUBLIC_API}${currentPaySysMethod}`, selectedGood, {
        headers: {
          authorization: token
        }
      })
      .then((res) => {
        dispatch(Actions.setCreateInvoiceIDSuccess(res.data));
        dispatch(Actions.setInvoiceIDLoading(false));
      })
      .catch((err) => {
        dispatch(Actions.setCreateInvoiceIDFailure());
      });
  },
  payWithPaddle: (invoiceID, selectedGood, mail) => (dispatch) => {
    Paddle.Checkout.open({
      passthrough: invoiceID,
      product: selectedGood.paddle_id,
      email: mail,
      successCallback: (_, err) => {
        if (err) {
          return window.open('https://aezakmi.run/paymenterror');
        }
        dispatch(UserActions.fetchUserData());
        window.open('https://aezakmi.run/successpayment');
      }
    });
  }
};

export default Actions;
