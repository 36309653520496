import React from 'react';
import ReactDOM from 'react-dom';
import './css/index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { HashRouter } from 'react-router-dom';
import store from './redux';
import { Provider } from 'react-redux';
import { I18nextProvider } from 'react-i18next';
import i18n from './i18n';

// Sentry
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';

const isProd = process.env === 'production';

if (isProd) {
  Sentry.init({
    dsn: 'https://1318da14161845ee9ee810034ad36e4b@o461429.ingest.sentry.io/5598118',
    autoSessionTracking: true,
    autoSessionTracking: true,
    integrations: [new Integrations.BrowserTracing()],

    tracesSampleRate: 1.0
  });
}

ReactDOM.render(
  <Provider store={store}>
    <HashRouter>
      <I18nextProvider i18n={i18n}>
        <App />
      </I18nextProvider>
    </HashRouter>
  </Provider>,
  document.getElementById('root')
);

serviceWorker.unregister();
