export function randomSha256() {
  const chrs = 'ABDEFGHKMNPQRSTWXZ123456789';
  let str = '';
  for (var h = 0; h < 32; h++) {
    for (var i = 0; i < 2; i++) {
      var pos = Math.floor(Math.random() * chrs.length);
      str += chrs.substring(pos, pos + 1);
    }
    str = str + ':';
  }
  return str.slice(0, -1).toString();
}
