// Core
import React from 'react';

// Sryles
import { Form, Jumbotron, Button } from 'react-bootstrap';
import { Container, Input } from '@material-ui/core';

// Components
import Manifest from './PaySistemUtils/Manifest';

import CryptoJS from 'crypto-js';

import { useTranslation } from 'react-i18next';

const Capitalist = (props) => {
  const { checkedPaySys, invoiceID, selectedGood, isInvoiceIdLoading } = props;

  const { t } = useTranslation();

  const hash = CryptoJS.HmacMD5(`${selectedGood.price}:USD:${selectedGood.license}:100526:${invoiceID}`, 'sSy-FaR-eKx-Ca3');

  return (
    <Jumbotron fluid>
      <Container>
        <Manifest checkedPaySys={checkedPaySys} invoiceID={invoiceID} isInvoiceIdLoading={isInvoiceIdLoading} paddle={false} />
        <Form name="payment" method="post" action="https://capitalist.net/merchant/pay?lang=ru" acceptCharset="UTF-8">
          <Input type="hidden" name="merchantid" value="100526" />
          <Input type="hidden" name="number" value={invoiceID || ''} />
          <Input type="hidden" name="amount" value={selectedGood.price || ''} />
          <Input type="hidden" name="currency" value="USD" />
          <Input type="hidden" name="description" value={selectedGood.license || ''} />
          <Input type="hidden" name="sign" value={hash || ''} />
          <Button className="pay_button" variant="success" type="submit" value="Pay">
            {t('Checkout.pay')}
          </Button>
        </Form>
      </Container>
    </Jumbotron>
  );
};

export default Capitalist;
