import { notification } from 'antd';

const CreateNotification = ({ type, title, description }) => {
  notification[type]({
    message: title,
    description
  });
};

export default CreateNotification;
