import React from 'react';
import { Col, Form } from 'react-bootstrap';
import { Label } from '../../UI';

import { useTranslation } from 'react-i18next';

const TabItem = ({ onChange, disabled, value, title, data, id, transtaionType }) => {
  const { t } = useTranslation();
  return (
    <Col md={12}>
      <Label title={t(`Helpers.config.${transtaionType}.title`)} text={t(`Helpers.config.${transtaionType}.text`)}>
        {title}
      </Label>
      <Form.Control className="prof-select" value={value} id={id} disabled={disabled} as="select" onChange={(e) => onChange(e)}>
        <option></option>
        {data.length !== 0
          ? data.map((item, i) => (
              <option value={item} key={i}>
                {i + 1}.{item}
              </option>
            ))
          : null}
      </Form.Control>
    </Col>
  );
};

export default TabItem;
