import React from 'react';
import { Card, Col, Form, Row } from 'react-bootstrap';
import MySwitch from '../../UI/MySwith';
import { useTranslation } from 'react-i18next';

const Noise = ({
  changeInputs,
  canvasNoiseSwitchChecked,
  canvasNoiseSwitch,
  fontsSpoofSwitchChecked,
  fontsSpoofSwitch,
  webglNoiseSwitchChecked,
  webglNoiseSwitch,
  audioNoiseSwitchChecked,
  audioNoiseSwitch,
  doNotTrackSwitchChecked,
  doNotTrackSwitch,
  audiohash,
  rects,
  webglNoiseR,
  webglNoiseG,
  webglNoiseB,
  webglNoiseA,
  webglNoiseString1,
  webglNoiseString2,
  audioNoiseConst1,
  audioNoiseConst2,
  audioNoiseConst3,
  audioNoiseConst4,
  seed,
  ObserverWebRTCSwitch,
  ObserverWebRTCSwitchChecked,
  WebRTCIP,
  WebRTClocalIP
}) => {
  const { t } = useTranslation();
  return (
    <>
      <div className="alert alert-yellow alert-dismissible fade show" role="alert">
        <strong>{t('Dashboard.config.Attention.title')}</strong> {t('Dashboard.config.Attention.noise_text')}
      </div>

      <Row>
        <Col md={12}>
          <Card>
            <h4 className={'text-center matrix-text'}>{t('Dashboard.config.advanced.noise')}</h4>
            <Row>
              <Col md={2}>
                <Form.Label className="d-flex align-items-center">canvasNoise</Form.Label>
                <MySwitch onChange={() => canvasNoiseSwitchChecked()} checked={canvasNoiseSwitch} />
              </Col>
              <Col md={2}>
                <Form.Label className="d-flex align-items-center">webglNoise</Form.Label>
                <MySwitch onChange={() => webglNoiseSwitchChecked()} checked={webglNoiseSwitch} />
              </Col>
              <Col md={2}>
                <Form.Label className="d-flex align-items-center">audioNoise</Form.Label>
                <MySwitch onChange={() => audioNoiseSwitchChecked()} checked={audioNoiseSwitch} />
              </Col>
              <Col md={2}>
                <Form.Label className="d-flex align-items-center">doNotTrack</Form.Label>
                <MySwitch onChange={() => doNotTrackSwitchChecked()} checked={doNotTrackSwitch} />
              </Col>
              <Col md={2}>
                <Form.Label className="d-flex align-items-center">WebRTC Observer</Form.Label>
                <MySwitch onChange={() => ObserverWebRTCSwitchChecked()} checked={ObserverWebRTCSwitch} />
              </Col>
              <Col md={2}>
                <Form.Label className="d-flex align-items-center">Fonts Spoofing</Form.Label>
                <MySwitch onChange={() => fontsSpoofSwitchChecked()} checked={fontsSpoofSwitch} />
              </Col>
            </Row>
            <Row>
              <Col md={4}>
                <Form.Label>Audiohash</Form.Label>
                <Form.Control onChange={(e) => changeInputs(e)} value={audiohash} id="hash-audiohash" type="text" />
              </Col>
              <Col md={4}>
                <Form.Label>Rects</Form.Label>
                <Form.Control onChange={(e) => changeInputs(e)} value={rects} id="hash-rects" type="text" />
              </Col>
              <Col md={4}>
                <Form.Label>webglNoiseR</Form.Label>
                <Form.Control onChange={(e) => changeInputs(e)} value={webglNoiseR} id="hash-webglNoiseR" type="text" />
              </Col>
            </Row>
            <Row>
              <Col md={4}>
                <Form.Label>webglNoiseG</Form.Label>
                <Form.Control onChange={(e) => changeInputs(e)} value={webglNoiseG} id="hash-webglNoiseG" type="text" />
              </Col>
              <Col md={4}>
                <Form.Label>webglNoiseB</Form.Label>
                <Form.Control onChange={(e) => changeInputs(e)} value={webglNoiseB} id="hash-webglNoiseB" type="text" />
              </Col>
              <Col md={4}>
                <Form.Label>webglNoiseA</Form.Label>
                <Form.Control onChange={(e) => changeInputs(e)} value={webglNoiseA} id="hash-webglNoiseA" type="text" />
              </Col>
            </Row>
            <Row>
              <Col md={4}>
                <Form.Label>webglNoiseString1</Form.Label>
                <Form.Control onChange={(e) => changeInputs(e)} value={webglNoiseString1} id="hash-webglNoiseString1" type="text" />
              </Col>
              <Col md={4}>
                <Form.Label>webglNoiseString2</Form.Label>
                <Form.Control onChange={(e) => changeInputs(e)} value={webglNoiseString2} id="hash-webglNoiseString2" type="text" />
              </Col>
              <Col md={4}>
                <Form.Label>audioNoiseConst1</Form.Label>
                <Form.Control onChange={(e) => changeInputs(e)} value={audioNoiseConst1} id="hash-audioNoiseConst1" type="text" />
              </Col>
            </Row>
            <Row>
              <Col md={4}>
                <Form.Label>audioNoiseConst2</Form.Label>
                <Form.Control onChange={(e) => changeInputs(e)} value={audioNoiseConst2} id="hash-audioNoiseConst2" type="text" />
              </Col>
              <Col md={4}>
                <Form.Label>audioNoiseConst3</Form.Label>
                <Form.Control onChange={(e) => changeInputs(e)} value={audioNoiseConst3} id="hash-audioNoiseConst3" type="text" />
              </Col>
              <Col md={4}>
                <Form.Label>audioNoiseConst4</Form.Label>
                <Form.Control onChange={(e) => changeInputs(e)} value={audioNoiseConst4} id="hash-audioNoiseConst4" type="text" />
              </Col>
            </Row>
            <Row>
              <Col md={4}>
                <Form.Label>Seed</Form.Label>
                <Form.Control onChange={(e) => changeInputs(e)} value={seed} id="hash-seed" type="text" />
              </Col>
              <Col md={4}>
                <Form.Label>WebRTC Local IP </Form.Label>
                <Form.Control onChange={(e) => changeInputs(e)} value={WebRTClocalIP} id="webrtc-local-ip" type="text" />
              </Col>
              <Col md={4}>
                <Form.Label>WebRTC Public IP</Form.Label>
                <Form.Control onChange={(e) => changeInputs(e)} value={WebRTCIP} id="webrtc-public-ip" type="text" />
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default Noise;
