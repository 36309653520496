import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { Tab, Tabs } from 'react-bootstrap';
import axios from 'axios';
import DashboardTabs from './Tabs/Dashboard-tabs';
import preProfiles from '../../json/preProfiles.json';
import s from './Dashboard.module.css';
import CreateNotification from '../UI/Notification';
import { Cookie, GEOSettings, NavigationWebKit, Noise, DeviceHardware, Scren, WebGLCanvas, Comment } from './AdvancedMode';
import { regExpPort, regExpProfileName, regExpProxyAddress } from '../common/regExps';
import ProfileSettings from './ProfileSettings';
import Proxy from './Proxy';
import uaNew from '../../json/uaNew.json';

import { randomIntFromInterval, randomHash, randomSeed, getRandomString, randomSha256, randomString, randomInteger } from '../../utils/helpers';

import { withTranslation } from 'react-i18next';

class Dashboard extends Component {
  state = {
    oses: [],
    browsers: [],
    baseProfileInfo: [],
    serverUserError: false,
    userProfileInfo: [],

    chPreProfileID: '',
    chos: '',
    chunmaskedRendererWebgl: '',
    chbrowser: '',
    unmaskedRendererWebgls: [],
    chwidthXheight: '',
    widthXheight: [],
    currentProfile: {},

    initProfile: {},
    currentProfileReady: false,
    profileReady: false,
    profileSaved: false,

    profileName: '',

    userProfileName: '',
    userProfileReady: false,

    userAgentConst: '',
    appVersionConst: '',
    appNameConst: '',
    appCodeNameConst: '',
    productConst: '',
    productSubConst: '',
    vendorConst: '',
    vendorSubConst: '',
    buildIDConst: '',
    platformConst: '',
    oscpuConst: '',

    deviceMemoryConst: '',
    hardwareConcurrencyConst: '',

    widthvar: '',
    heightvar: '',
    availWidthvar: '',
    availHeighthvar: '',
    colorDepthvar: '',
    pixelDepthvar: '',
    availLeftvar: '',
    availTopvar: '',
    innerWidthvar: '',
    innerHeightvar: '',
    outerWidthvar: '',
    outerHeight: '',
    divWidthvar: '',
    divHeightvar: '',
    devicePixelRatio: '',

    webglFloatPrecision: '',

    unmaskedVendorWebgl: '',
    unmaskedRendererWebgl: '',
    webglRenderer: '',
    webglVendor: '',
    shadinglanguageversion: '',
    glVersion: '',
    aliasedLineWidthRange: '',
    aliasedPointSizeRange: '',
    alphaBits: '',
    blueBits: '',
    greenBits: '',
    redBits: '',
    stencilBits: '',
    depthBits: '',

    maxTextureMaxAnisotropyExt: '',
    maxVertexattribs: '',
    maxCombinedTextureImageUnits: '',
    maxCubeMapTextureSize: '',
    maxRenderBufferSize: '',
    maxTextureSize: '',
    maxFragmentUniformVectors: '',
    maxVertexuniformvectors: '',
    maxTextureImageUnits: '',
    maxVaryingVectors: '',
    maxVertexTextureImageUnits: '',

    maxViewportDims: '',

    maxVertexUniformComponents: '',
    maxVertexUniformBlocks: '',
    maxVertexOutputComponents: '',
    maxVaryingComponents: '',
    maxTransformFeedbackInterleavedComponents: '',
    maxTransformFeedbackSeparateAttribs: '',
    maxTransformFeedbackSeparateComponents: '',
    maxFragmentUniformComponents: '',
    maxFragmentUniformBlocks: '',
    maxFragmentInputComponents: '',
    minProgramTexelOffset: '',
    maxProgramTexelOffset: '',
    maxDrawBuffers: '',
    maxColorAttachments: '',
    maxSamples: '',
    max3dTextureSize: '',
    maxArrayTextureLayers: '',
    maxTextureLodBias: '',
    maxUniformBufferBindings: '',
    maxUniformBlockSize: '',
    uniformBufferOffsetAlignment: '',
    maxCombinedUniformBlocks: '',
    maxCombinedVertexUniformComponents: '',
    maxCombinedFragmentUniformComponents: '',

    proxyProtocolValid: false,
    proxyPortValid: false,
    proxyIpValid: false,
    profileNameValid: false,
    proxyReady: false,

    proxyProtocol: '',
    proxyIp: '',
    proxyPort: '',
    proxyUser: '',
    proxyPassword: '',
    chProxy: false,
    checkerip: '',
    checkercode: '',

    accept_language: '',
    language_Const1: '',
    languageConst1: '',
    sysTimeRegion: '',
    sysTimeConst: '',
    gpslat: '',
    gpslon: '',

    fonts: '',
    fontsSpoof: '',
    availablePlugins: [],

    canvasNoise: '',
    webglNoise: '',
    audioNoise: '',
    doNotTrack: '',
    enable911: '',

    seed: '',
    audiohash: '',
    rects: '',

    webrtc1: '',
    webrtc2: '',
    webrtc3: '',
    webrtc4: '',
    webrtc6: '',
    webrtc7: '',
    webrtc8: '',
    webrtc9: '',
    WebRTClocalIP: '',
    ObserverWebRTC: true,

    WebRTCIP: '',
    mediaDevices: '',

    webglNoiseR: '',
    webglNoiseG: '',
    webglNoiseB: '',
    webglNoiseA: '',
    webglNoiseString1: '',
    webglNoiseString2: '',
    audioNoiseConst1: '',
    audioNoiseConst2: '',
    audioNoiseConst3: '',
    audioNoiseConst4: '',

    moreSettings: false,

    fontsSpoofSwitch: true,
    canvasNoiseSwitch: true,
    webglNoiseSwitch: true,
    audioNoiseSwitch: true,
    doNotTrackSwitch: false,
    ObserverWebRTCSwitch: true,
    spoofingScreenSwitch: true,
    enable911Switch: false,

    fromUserLoaded: false,
    fromCheckerLoaded: false,
    loadingBaseFilter: false,
    loadingBaseProcess: false,
    loadingUserProcess: false,
    isUserProfileLoading: false,

    serverBaseError: false,

    daylightOffset: '',
    cookieLoad: false,

    cookie: '',
    convertedCookie: '',
    isUsingNetscape: false,

    note: '',

    uaFullversion: '',
    brands: '',
    platformUaData: '',
    platformVersionUaData: ''
  };

  componentDidMount() {
    this.becomeAllOses();
    this.becomeAllUserProfiles();
  }

  becomeAllOses = () => {
    let oses = [];
    preProfiles.forEach((item) => {
      if (!!item.headers.os.name && !oses.includes(item.headers.os.name)) {
        oses.push(item.headers.os.name);
      }
      this.setState({
        baseProfileInfo: preProfiles,
        oses: oses
      });
    });
  };

  handleSetNote = (note) => {
    this.setState({ note });
  };

  becomeAllUserProfiles = () => {
    this.setState({ serverUserError: false });
    const token = localStorage.getItem('token');
    axios
      .post(
        process.env.REACT_APP_PUBLIC_API + '/api/get_all_user_profiles',
        {
          login: this.props.login
        },
        {
          headers: {
            authorization: token
          }
        }
      )
      .then((res) => {
        this.setState({ userProfileInfo: res.data });
      })
      .catch((err) => {
        this.setState({ serverUserError: true });
      });
  };

  becomeUserProfile = () => {
    this.setState({
      profileReady: false,
      loadingUserProcess: true,
      serverUserError: false,
      fromUserLoaded: false,
      checkercode: '',
      isUsingNetscape: false
    });

    const token = localStorage.getItem('token');
    axios
      .post(
        process.env.REACT_APP_PUBLIC_API + '/api/get_current_user_profile',
        {
          login: this.props.login,
          profileName: this.state.userProfileName
        },
        {
          headers: {
            authorization: token
          }
        }
      )
      .then((res) => {
        if (res.data[0].cookie === undefined) {
          res.data[0].cookie = '';
        }
        if (res.data[0].cookie !== '') {
          res.data[0].cookie = Buffer.from(res.data[0].cookie, 'base64').toString('ascii');
        }
        this.setState({ initProfile: res.data[0] });

        let d = res.data[0];

        for (let key in d) {
          if (key === 'userProfileName') {
            this.setState({ profileName: d.userProfileName });
          }
          if (key === 'brands') {
            this.setState({ brands: JSON.stringify(d.brands) });
          } else {
            if (d[key] instanceof Array) {
              // Данная проверка ожидает mediaDevices и записывает их оригинальное значение
              // Сделанно так потому что, мы не пожем преобразовать в строку через join(",")
              // Но мы и не показываем этру строку - вывод мы не будем ее парсить
              // Данное рпавило применимо только к этому параметру
              if (key === 'mediaDevices') {
                this.setState({ [key]: d[key] });
              } else {
                let target = d[key].join(',');
                this.setState({ [key]: target });
              }
            } else {
              this.setState({ [key]: d[key] });
            }
          }
        }

        if (d.canvasNoise == '1') {
          this.setState({
            canvasNoise: '1',
            canvasNoiseSwitch: true
          });
        } else {
          this.setState({
            canvasNoise: '0',
            canvasNoiseSwitch: false
          });
        }

        if (d.webglNoise == '1') {
          this.setState({
            webglNoise: '1',
            webglNoiseSwitch: true
          });
        } else {
          this.setState({
            webglNoise: '0',
            webglNoiseSwitch: false
          });
        }

        if (d.fontsSpoof == '1') {
          this.setState({
            fontsSpoof: '1',
            fontsSpoofSwitch: true
          });
        } else {
          this.setState({
            fontsSpoof: '0',
            fontsSpoofSwitch: false
          });
        }

        if (d.audioNoise == '1') {
          this.setState({
            audioNoise: '1',
            audioNoiseSwitch: true
          });
        } else {
          this.setState({
            audioNoise: '0',
            audioNoiseSwitch: false
          });
        }

        if (d.doNotTrack == '1') {
          this.setState({
            doNotTrack: '1',
            doNotTrackSwitch: true
          });
        } else {
          this.setState({
            doNotTrack: '0',
            doNotTrackSwitch: false
          });
        }
        if (d.ObserverWebRTC == true) {
          this.setState({
            ObserverWebRTC: true,
            ObserverWebRTCSwitch: true
          });
        } else {
          this.setState({
            ObserverWebRTC: false,
            ObserverWebRTCSwitch: false
          });
        }
        if (d.enable911 == '1') {
          this.setState({
            enable911: '1',
            enable911Switch: true
          });
        } else {
          this.setState({
            enable911: '0',
            enable911Switch: false
          });
        }

        if (d.proxyIp !== '' || d.enable911 == '1') {
          this.setState({
            chProxy: true,
            proxyReady: true,
            proxyIpValid: true,
            proxyProtocolValid: true,
            proxyPortValid: true
          });
        }
        this.setState(
          () => ({ userProfileName: d.profileName }),
          () => {
            this.setState({
              profileReady: true,
              profileSaved: true,
              fromUserLoaded: true,
              loadingUserProcess: false,
              profileNameValid: true
            });
          }
        );
      })
      .catch((err) => {
        this.setState({ serverUserError: true });
      });
  };

  createUserProfile = () => {
    this.setState({
      serverUserError: false,
      isUserProfileLoading: true,
      profileSaved: false
    });
    let userProfile = {};

    userProfile.profileName = this.state.profileName;
    userProfile.login = this.props.login;

    userProfile.uaFullversion = this.state.uaFullversion;
    userProfile.brands = this.state.brands;
    userProfile.platformUaData = this.state.platformUaData;
    userProfile.platformVersionUaData = this.state.platformVersionUaData;

    userProfile.os = this.state.os;
    userProfile.browser = this.state.browser;
    userProfile.removed = false;

    userProfile.userAgentConst = this.state.userAgentConst;
    userProfile.appVersionConst = this.state.appVersionConst;
    userProfile.appNameConst = this.state.appNameConst;
    userProfile.appCodeNameConst = this.state.appCodeNameConst;
    userProfile.productConst = this.state.productConst;
    userProfile.productSubConst = this.state.productSubConst;
    userProfile.vendorConst = this.state.vendorConst;
    userProfile.vendorSubConst = this.state.vendorSubConst;
    userProfile.buildIDConst = this.state.buildIDConst;
    userProfile.platformConst = this.state.platformConst;

    userProfile.oscpuConst = this.state.oscpuConst;

    userProfile.deviceMemoryConst = this.state.deviceMemoryConst;
    userProfile.hardwareConcurrencyConst = this.state.hardwareConcurrencyConst;

    userProfile.fonts = this.state.fonts;
    userProfile.availablePlugins = this.state.availablePlugins;
    userProfile.seed = this.state.seed;
    userProfile.audiohash = this.state.audiohash;
    userProfile.rects = this.state.rects;

    userProfile.webrtc1 = this.state.webrtc1;
    userProfile.webrtc2 = this.state.webrtc2;
    userProfile.webrtc3 = this.state.webrtc3;
    userProfile.webrtc4 = this.state.webrtc4;
    userProfile.webrtc6 = this.state.webrtc6;
    userProfile.webrtc7 = this.state.webrtc7;
    userProfile.webrtc8 = this.state.webrtc8;
    userProfile.webrtc9 = this.state.webrtc9;
    userProfile.WebRTClocalIP = this.state.WebRTClocalIP;
    userProfile.ObserverWebRTC = this.state.ObserverWebRTC;
    userProfile.WebRTCIP = this.state.WebRTCIP;
    userProfile.mediaDevices = this.state.mediaDevices;

    userProfile.webglNoiseR = this.state.webglNoiseR;
    userProfile.webglNoiseG = this.state.webglNoiseG;
    userProfile.webglNoiseB = this.state.webglNoiseB;
    userProfile.webglNoiseA = this.state.webglNoiseA;
    userProfile.webglNoiseString1 = this.state.webglNoiseString1;
    userProfile.webglNoiseString2 = this.state.webglNoiseString2;
    userProfile.audioNoiseConst1 = this.state.audioNoiseConst1;
    userProfile.audioNoiseConst2 = this.state.audioNoiseConst2;
    userProfile.audioNoiseConst3 = this.state.audioNoiseConst3;
    userProfile.audioNoiseConst4 = this.state.audioNoiseConst4;

    userProfile.widthvar = this.state.widthvar;
    userProfile.heightvar = this.state.heightvar;
    userProfile.availWidthvar = this.state.availWidthvar;
    userProfile.availHeighthvar = this.state.availHeighthvar;
    userProfile.colorDepthvar = this.state.pixelDepthvar;
    userProfile.pixelDepthvar = this.state.pixelDepthvar;
    userProfile.availLeftvar = this.state.availLeftvar;
    userProfile.availTopvar = this.state.availTopvar;
    userProfile.innerWidthvar = this.state.innerWidthvar;
    userProfile.innerHeightvar = this.state.innerHeightvar;
    userProfile.outerWidthvar = this.state.availWidthvar;
    userProfile.outerHeight = this.state.availHeighthvar;
    userProfile.divWidthvar = this.state.divWidthvar;
    userProfile.divHeightvar = this.state.divHeightvar;
    userProfile.devicePixelRatio = this.state.devicePixelRatio;

    userProfile.webglFloatPrecision = this.state.webglFloatPrecision.toString().replace(/['""]/g, '');

    userProfile.canvasNoise = this.state.canvasNoise;
    userProfile.webglNoise = this.state.webglNoise;
    userProfile.audioNoise = this.state.audioNoise;
    userProfile.doNotTrack = this.state.doNotTrack;
    userProfile.enable911 = this.state.enable911;
    userProfile.fontsSpoof = this.state.fontsSpoof;
    userProfile.spoofingScreenSwitch = this.state.spoofingScreenSwitch;

    userProfile.blueBits = this.state.blueBits;
    userProfile.greenBits = this.state.greenBits;
    userProfile.alphaBits = this.state.alphaBits;
    userProfile.redBits = this.state.redBits;
    userProfile.stencilBits = this.state.stencilBits;
    userProfile.depthBits = this.state.depthBits;
    userProfile.max3dTextureSize = this.state.max3dTextureSize;
    userProfile.maxArrayTextureLayers = this.state.maxArrayTextureLayers;
    userProfile.maxColorAttachments = this.state.maxColorAttachments;
    userProfile.maxCombinedFragmentUniformComponents = this.state.maxCombinedFragmentUniformComponents;
    userProfile.maxCombinedTextureImageUnits = this.state.maxCombinedTextureImageUnits;
    userProfile.maxCombinedUniformBlocks = this.state.maxCombinedUniformBlocks;
    userProfile.maxCombinedVertexUniformComponents = this.state.maxCombinedVertexUniformComponents;
    userProfile.maxCubeMapTextureSize = this.state.maxCubeMapTextureSize;
    userProfile.maxDrawBuffers = this.state.maxDrawBuffers;
    userProfile.maxFragmentInputComponents = this.state.maxFragmentInputComponents;
    userProfile.maxFragmentUniformBlocks = this.state.maxFragmentUniformBlocks;
    userProfile.maxFragmentUniformComponents = this.state.maxFragmentUniformComponents;
    userProfile.maxFragmentUniformVectors = this.state.maxFragmentUniformVectors;
    userProfile.maxProgramTexelOffset = this.state.maxProgramTexelOffset;
    userProfile.maxRenderBufferSize = this.state.maxRenderBufferSize;
    userProfile.maxSamples = this.state.maxSamples;
    userProfile.maxTextureImageUnits = this.state.maxTextureImageUnits;
    userProfile.maxTextureLodBias = this.state.maxTextureLodBias;
    userProfile.maxTextureSize = this.state.maxTextureSize;
    userProfile.maxTransformFeedbackInterleavedComponents = this.state.maxTransformFeedbackInterleavedComponents;
    userProfile.maxTransformFeedbackSeparateAttribs = this.state.maxTransformFeedbackSeparateAttribs;
    userProfile.maxTransformFeedbackSeparateComponents = this.state.maxTransformFeedbackSeparateComponents;
    userProfile.maxUniformBlockSize = this.state.maxUniformBlockSize;
    userProfile.maxUniformBufferBindings = this.state.maxUniformBufferBindings;
    userProfile.maxVaryingComponents = this.state.maxVaryingComponents;
    userProfile.maxVaryingVectors = this.state.maxVaryingVectors;
    userProfile.maxVertexOutputComponents = this.state.maxVertexOutputComponents;
    userProfile.maxVertexTextureImageUnits = this.state.maxVertexTextureImageUnits;
    userProfile.maxVertexUniformBlocks = this.state.maxVertexUniformBlocks;
    userProfile.maxVertexUniformComponents = this.state.maxVertexUniformComponents;
    userProfile.maxVertexattribs = this.state.maxVertexattribs;
    userProfile.maxVertexuniformvectors = this.state.maxVertexuniformvectors;
    userProfile.minProgramTexelOffset = this.state.minProgramTexelOffset;
    userProfile.maxTextureMaxAnisotropyExt = this.state.maxTextureMaxAnisotropyExt;
    userProfile.maxViewportDims = this.state.maxViewportDims;
    userProfile.aliasedLineWidthRange = this.state.aliasedLineWidthRange;
    userProfile.aliasedPointSizeRange = this.state.aliasedPointSizeRange;
    userProfile.shadinglanguageversion = this.state.shadinglanguageversion;
    userProfile.uniformBufferOffsetAlignment = this.state.uniformBufferOffsetAlignment;
    userProfile.unmaskedRendererWebgl = this.state.unmaskedRendererWebgl;
    userProfile.unmaskedVendorWebgl = this.state.unmaskedVendorWebgl;
    userProfile.webglRenderer = this.state.webglRenderer;
    userProfile.webglVendor = this.state.webglVendor;
    userProfile.glVersion = this.state.glVersion;
    try {
      userProfile.WEBGL2_Extentions = this.state.WEBGL2_Extentions.toString().replace(/['""]/g, '');
      userProfile.WEBGL1_Extentions = this.state.WEBGL1_Extentions.toString().replace(/['""]/g, '');
      userProfile.WEBGL2_ARRAY_BUFFER_BINDING = this.state.WEBGL2_ARRAY_BUFFER_BINDING;
      userProfile.WEBGL2_ACTIVE_TEXTURE = this.state.WEBGL2_ACTIVE_TEXTURE;
      userProfile.WEBGL2_BLEND = this.state.WEBGL2_BLEND;
      userProfile.WEBGL2_BLEND_COLOR = this.state.WEBGL2_BLEND_COLOR;
      userProfile.WEBGL2_BLEND_DST_ALPHA = this.state.WEBGL2_BLEND_DST_ALPHA;
      userProfile.WEBGL2_BLEND_DST_RGB = this.state.WEBGL2_BLEND_DST_RGB;
      userProfile.WEBGL2_BLEND_EQUATION_ALPHA = this.state.WEBGL2_BLEND_EQUATION_ALPHA;
      userProfile.WEBGL2_BLEND_EQUATION_RGB = this.state.WEBGL2_BLEND_EQUATION_RGB;
      userProfile.WEBGL2_BLEND_SRC_ALPHA = this.state.WEBGL2_BLEND_SRC_ALPHA;
      userProfile.WEBGL2_BLEND_SRC_RGB = this.state.WEBGL2_BLEND_SRC_RGB;
      userProfile.WEBGL2_COLOR_CLEAR_VALUE = this.state.WEBGL2_COLOR_CLEAR_VALUE;
      userProfile.WEBGL2_COLOR_WRITEMASK = this.state.WEBGL2_COLOR_WRITEMASK;
      userProfile.WEBGL2_CULL_FACE = this.state.WEBGL2_CULL_FACE;
      userProfile.WEBGL2_CULL_FACE_MODE = this.state.WEBGL2_CULL_FACE_MODE;
      userProfile.WEBGL2_CURRENT_PROGRAM = this.state.WEBGL2_CURRENT_PROGRAM;
      userProfile.WEBGL2_DEPTH_CLEAR_VALUE = this.state.WEBGL2_DEPTH_CLEAR_VALUE;
      userProfile.WEBGL2_DEPTH_FUNC = this.state.WEBGL2_DEPTH_FUNC;
      userProfile.WEBGL2_DEPTH_RANGE = this.state.WEBGL2_DEPTH_RANGE;
      userProfile.WEBGL2_DEPTH_TEST = this.state.WEBGL2_DEPTH_TEST;
      userProfile.WEBGL2_DEPTH_WRITEMASK = this.state.WEBGL2_DEPTH_WRITEMASK;
      userProfile.WEBGL2_DITHER = this.state.WEBGL2_DITHER;
      userProfile.WEBGL2_ELEMENT_ARRAY_BUFFER_BINDING = this.state.WEBGL2_ELEMENT_ARRAY_BUFFER_BINDING;
      userProfile.WEBGL2_FRAMEBUFFER_BINDING = this.state.WEBGL2_FRAMEBUFFER_BINDING;
      userProfile.WEBGL2_FRONT_FACE = this.state.WEBGL2_FRONT_FACE;
      userProfile.WEBGL2_GENERATE_MIPMAP_HINT = this.state.WEBGL2_GENERATE_MIPMAP_HINT;
      userProfile.WEBGL2_LINE_WIDTH = this.state.WEBGL2_LINE_WIDTH;
      userProfile.WEBGL2_PACK_ALIGNMENT = this.state.WEBGL2_PACK_ALIGNMENT;
      userProfile.WEBGL2_POLYGON_OFFSET_FACTOR = this.state.WEBGL2_POLYGON_OFFSET_FACTOR;
      userProfile.WEBGL2_POLYGON_OFFSET_FILL = this.state.WEBGL2_POLYGON_OFFSET_FILL;
      userProfile.WEBGL2_POLYGON_OFFSET_UNITS = this.state.WEBGL2_POLYGON_OFFSET_UNITS;
      userProfile.WEBGL2_RENDERBUFFER_BINDING = this.state.WEBGL2_RENDERBUFFER_BINDING;
      userProfile.WEBGL2_SAMPLE_BUFFERS = this.state.WEBGL2_SAMPLE_BUFFERS;
      userProfile.WEBGL2_SAMPLE_COVERAGE_INVERT = this.state.WEBGL2_SAMPLE_COVERAGE_INVERT;
      userProfile.WEBGL2_SAMPLE_COVERAGE_VALUE = this.state.WEBGL2_SAMPLE_COVERAGE_VALUE;
      userProfile.WEBGL2_SAMPLES = this.state.WEBGL2_SAMPLES;
      userProfile.WEBGL2_STENCIL_BACK_FAIL = this.state.WEBGL2_STENCIL_BACK_FAIL;
      userProfile.WEBGL2_STENCIL_BACK_FUNC = this.state.WEBGL2_STENCIL_BACK_FUNC;
      userProfile.WEBGL2_STENCIL_BACK_PASS_DEPTH_FAIL = this.state.WEBGL2_STENCIL_BACK_PASS_DEPTH_FAIL;
      userProfile.WEBGL2_STENCIL_BACK_PASS_DEPTH_PASS = this.state.WEBGL2_STENCIL_BACK_PASS_DEPTH_PASS;
      userProfile.WEBGL2_STENCIL_BACK_REF = this.state.WEBGL2_STENCIL_BACK_REF;
      userProfile.WEBGL2_STENCIL_BACK_VALUE_MASK = this.state.WEBGL2_STENCIL_BACK_VALUE_MASK;
      userProfile.WEBGL2_STENCIL_BACK_WRITEMASK = this.state.WEBGL2_STENCIL_BACK_WRITEMASK;
      userProfile.WEBGL2_STENCIL_CLEAR_VALUE = this.state.WEBGL2_STENCIL_CLEAR_VALUE;
      userProfile.WEBGL2_STENCIL_FAIL = this.state.WEBGL2_STENCIL_FAIL;
      userProfile.WEBGL2_STENCIL_FUNC = this.state.WEBGL2_STENCIL_FUNC;
      userProfile.WEBGL2_STENCIL_PASS_DEPTH_FAIL = this.state.WEBGL2_STENCIL_PASS_DEPTH_FAIL;
      userProfile.WEBGL2_STENCIL_PASS_DEPTH_PASS = this.state.WEBGL2_STENCIL_PASS_DEPTH_PASS;
      userProfile.WEBGL2_STENCIL_REF = this.state.WEBGL2_STENCIL_REF;
      userProfile.WEBGL2_STENCIL_TEST = this.state.WEBGL2_STENCIL_TEST;
      userProfile.WEBGL2_STENCIL_VALUE_MASK = this.state.WEBGL2_STENCIL_VALUE_MASK;
      userProfile.WEBGL2_STENCIL_WRITEMASK = this.state.WEBGL2_STENCIL_WRITEMASK;
      userProfile.WEBGL2_SUBPIXEL_BITS = this.state.WEBGL2_SUBPIXEL_BITS;
      userProfile.WEBGL2_TEXTURE_BINDING_2D = this.state.WEBGL2_TEXTURE_BINDING_2D;
      userProfile.WEBGL2_TEXTURE_BINDING_CUBE_MAP = this.state.WEBGL2_TEXTURE_BINDING_CUBE_MAP;
      userProfile.WEBGL2_UNPACK_ALIGNMENT = this.state.WEBGL2_UNPACK_ALIGNMENT;
      userProfile.WEBGL2_UNPACK_COLORSPACE_CONVERSION_WEBGL = this.state.WEBGL2_UNPACK_COLORSPACE_CONVERSION_WEBGL;
      userProfile.WEBGL2_UNPACK_FLIP_Y_WEBGL = this.state.WEBGL2_UNPACK_FLIP_Y_WEBGL;
      userProfile.WEBGL2_UNPACK_PREMULTIPLY_ALPHA_WEBGL = this.state.WEBGL2_UNPACK_PREMULTIPLY_ALPHA_WEBGL;
      userProfile.WEBGL1_blueBits = this.state.WEBGL1_blueBits;
      userProfile.WEBGL1_greenBits = this.state.WEBGL1_greenBits;
      userProfile.WEBGL1_alphaBits = this.state.WEBGL1_alphaBits;
      userProfile.WEBGL1_redBits = this.state.WEBGL1_redBits;
      userProfile.WEBGL1_stencilBits = this.state.WEBGL1_stencilBits;
      userProfile.WEBGL1_depthBits = this.state.WEBGL1_depthBits;
      userProfile.WEBGL1_maxCombinedTextureImageUnits = this.state.WEBGL1_maxCombinedTextureImageUnits;
      userProfile.WEBGL1_maxCubeMapTextureSize = this.state.WEBGL1_maxCubeMapTextureSize;
      userProfile.WEBGL1_maxFragmentUniformVectors = this.state.WEBGL1_maxFragmentUniformVectors;
      userProfile.WEBGL1_maxRenderBufferSize = this.state.WEBGL1_maxRenderBufferSize;
      userProfile.WEBGL1_maxTextureImageUnits = this.state.WEBGL1_maxTextureImageUnits;
      userProfile.WEBGL1_maxTextureSize = this.state.WEBGL1_maxTextureSize;
      userProfile.WEBGL1_maxVaryingVectors = this.state.WEBGL1_maxVaryingVectors;
      userProfile.WEBGL1_maxVertexTextureImageUnits = this.state.WEBGL1_maxVertexTextureImageUnits;
      userProfile.WEBGL1_maxVertexattribs = this.state.WEBGL1_maxVertexattribs;
      userProfile.WEBGL1_maxVertexuniformvectors = this.state.WEBGL1_maxVertexuniformvectors;
      userProfile.WEBGL1_maxTextureMaxAnisotropyExt = this.state.WEBGL1_maxTextureMaxAnisotropyExt;
      userProfile.WEBGL1_maxViewportDims = this.state.WEBGL1_maxViewportDims;
      userProfile.WEBGL1_aliasedLineWidthRange = this.state.WEBGL1_aliasedLineWidthRange;
      userProfile.WEBGL1_aliasedPointSizeRange = this.state.WEBGL1_aliasedPointSizeRange;
      userProfile.WEBGL1_shadinglanguageversion = this.state.WEBGL1_shadinglanguageversion;
      userProfile.WEBGL1_unmaskedRendererWebgl = this.state.WEBGL1_unmaskedRendererWebgl;
      userProfile.WEBGL1_unmaskedVendorWebgl = this.state.WEBGL1_unmaskedVendorWebgl;
      userProfile.WEBGL1_webglRenderer = this.state.WEBGL1_webglRenderer;
      userProfile.WEBGL1_webglVendor = this.state.WEBGL1_webglVendor;
      userProfile.WEBGL1_glVersion = this.state.WEBGL1_glVersion;
      userProfile.WEBGL1_ARRAY_BUFFER_BINDING = this.state.WEBGL1_ARRAY_BUFFER_BINDING;
      userProfile.WEBGL1_ACTIVE_TEXTURE = this.state.WEBGL1_ACTIVE_TEXTURE;
      userProfile.WEBGL1_BLEND = this.state.WEBGL1_BLEND;
      userProfile.WEBGL1_BLEND_COLOR = this.state.WEBGL1_BLEND_COLOR;
      userProfile.WEBGL1_BLEND_DST_ALPHA = this.state.WEBGL1_BLEND_DST_ALPHA;
      userProfile.WEBGL1_BLEND_DST_RGB = this.state.WEBGL1_BLEND_DST_RGB;
      userProfile.WEBGL1_BLEND_EQUATION_ALPHA = this.state.WEBGL1_BLEND_EQUATION_ALPHA;
      userProfile.WEBGL1_BLEND_EQUATION_RGB = this.state.WEBGL1_BLEND_EQUATION_RGB;
      userProfile.WEBGL1_BLEND_SRC_ALPHA = this.state.WEBGL1_BLEND_SRC_ALPHA;
      userProfile.WEBGL1_BLEND_SRC_RGB = this.state.WEBGL1_BLEND_SRC_RGB;
      userProfile.WEBGL1_COLOR_CLEAR_VALUE = this.state.WEBGL1_COLOR_CLEAR_VALUE;
      userProfile.WEBGL1_COLOR_WRITEMASK = this.state.WEBGL1_COLOR_WRITEMASK;
      userProfile.WEBGL1_CULL_FACE = this.state.WEBGL1_CULL_FACE;
      userProfile.WEBGL1_CULL_FACE_MODE = this.state.WEBGL1_CULL_FACE_MODE;
      userProfile.WEBGL1_CURRENT_PROGRAM = this.state.WEBGL1_CURRENT_PROGRAM;
      userProfile.WEBGL1_DEPTH_CLEAR_VALUE = this.state.WEBGL1_DEPTH_CLEAR_VALUE;
      userProfile.WEBGL1_DEPTH_FUNC = this.state.WEBGL1_DEPTH_FUNC;
      userProfile.WEBGL1_DEPTH_RANGE = this.state.WEBGL1_DEPTH_RANGE;
      userProfile.WEBGL1_DEPTH_TEST = this.state.WEBGL1_DEPTH_TEST;
      userProfile.WEBGL1_DEPTH_WRITEMASK = this.state.WEBGL1_DEPTH_WRITEMASK;
      userProfile.WEBGL1_DITHER = this.state.WEBGL1_DITHER;
      userProfile.WEBGL1_ELEMENT_ARRAY_BUFFER_BINDING = this.state.WEBGL1_ELEMENT_ARRAY_BUFFER_BINDING;
      userProfile.WEBGL1_FRAMEBUFFER_BINDING = this.state.WEBGL1_FRAMEBUFFER_BINDING;
      userProfile.WEBGL1_FRONT_FACE = this.state.WEBGL1_FRONT_FACE;
      userProfile.WEBGL1_GENERATE_MIPMAP_HINT = this.state.WEBGL1_GENERATE_MIPMAP_HINT;
      userProfile.WEBGL1_LINE_WIDTH = this.state.WEBGL1_LINE_WIDTH;
      userProfile.WEBGL1_PACK_ALIGNMENT = this.state.WEBGL1_PACK_ALIGNMENT;
      userProfile.WEBGL1_POLYGON_OFFSET_FACTOR = this.state.WEBGL1_POLYGON_OFFSET_FACTOR;
      userProfile.WEBGL1_POLYGON_OFFSET_FILL = this.state.WEBGL1_POLYGON_OFFSET_FILL;
      userProfile.WEBGL1_POLYGON_OFFSET_UNITS = this.state.WEBGL1_POLYGON_OFFSET_UNITS;
      userProfile.WEBGL1_RENDERBUFFER_BINDING = this.state.WEBGL1_RENDERBUFFER_BINDING;
      userProfile.WEBGL1_SAMPLE_BUFFERS = this.state.WEBGL1_SAMPLE_BUFFERS;
      userProfile.WEBGL1_SAMPLE_COVERAGE_INVERT = this.state.WEBGL1_SAMPLE_COVERAGE_INVERT;
      userProfile.WEBGL1_SAMPLE_COVERAGE_VALUE = this.state.WEBGL1_SAMPLE_COVERAGE_VALUE;
      userProfile.WEBGL1_SAMPLES = this.state.WEBGL1_SAMPLES;
      userProfile.WEBGL1_STENCIL_BACK_FAIL = this.state.WEBGL1_STENCIL_BACK_FAIL;
      userProfile.WEBGL1_STENCIL_BACK_FUNC = this.state.WEBGL1_STENCIL_BACK_FUNC;
      userProfile.WEBGL1_STENCIL_BACK_PASS_DEPTH_FAIL = this.state.WEBGL1_STENCIL_BACK_PASS_DEPTH_FAIL;
      userProfile.WEBGL1_STENCIL_BACK_PASS_DEPTH_PASS = this.state.WEBGL1_STENCIL_BACK_PASS_DEPTH_PASS;
      userProfile.WEBGL1_STENCIL_BACK_REF = this.state.WEBGL1_STENCIL_BACK_REF;
      userProfile.WEBGL1_STENCIL_BACK_VALUE_MASK = this.state.WEBGL1_STENCIL_BACK_VALUE_MASK;
      userProfile.WEBGL1_STENCIL_BACK_WRITEMASK = this.state.WEBGL1_STENCIL_BACK_WRITEMASK;
      userProfile.WEBGL1_STENCIL_CLEAR_VALUE = this.state.WEBGL1_STENCIL_CLEAR_VALUE;
      userProfile.WEBGL1_STENCIL_FAIL = this.state.WEBGL1_STENCIL_FAIL;
      userProfile.WEBGL1_STENCIL_FUNC = this.state.WEBGL1_STENCIL_FUNC;
      userProfile.WEBGL1_STENCIL_PASS_DEPTH_FAIL = this.state.WEBGL1_STENCIL_PASS_DEPTH_FAIL;
      userProfile.WEBGL1_STENCIL_PASS_DEPTH_PASS = this.state.WEBGL1_STENCIL_PASS_DEPTH_PASS;
      userProfile.WEBGL1_STENCIL_REF = this.state.WEBGL1_STENCIL_REF;
      userProfile.WEBGL1_STENCIL_TEST = this.state.WEBGL1_STENCIL_TEST;
      userProfile.WEBGL1_STENCIL_VALUE_MASK = this.state.WEBGL1_STENCIL_VALUE_MASK;
      userProfile.WEBGL1_STENCIL_WRITEMASK = this.state.WEBGL1_STENCIL_WRITEMASK;
      userProfile.WEBGL1_SUBPIXEL_BITS = this.state.WEBGL1_SUBPIXEL_BITS;
      userProfile.WEBGL1_TEXTURE_BINDING_2D = this.state.WEBGL1_TEXTURE_BINDING_2D;
      userProfile.WEBGL1_TEXTURE_BINDING_CUBE_MAP = this.state.WEBGL1_TEXTURE_BINDING_CUBE_MAP;
      userProfile.WEBGL1_UNPACK_ALIGNMENT = this.state.WEBGL1_UNPACK_ALIGNMENT;
      userProfile.WEBGL1_UNPACK_COLORSPACE_CONVERSION_WEBGL = this.state.WEBGL1_UNPACK_COLORSPACE_CONVERSION_WEBGL;
      userProfile.WEBGL1_UNPACK_FLIP_Y_WEBGL = this.state.WEBGL1_UNPACK_FLIP_Y_WEBGL;
      userProfile.WEBGL1_UNPACK_PREMULTIPLY_ALPHA_WEBGL = this.state.WEBGL1_UNPACK_PREMULTIPLY_ALPHA_WEBGL;
    } catch (err) {}
    if (this.state.cookie === '') {
      userProfile.cookieLoad = '';
      userProfile.cookie = '';
    } else if (this.state.initProfile.cookie !== this.state.cookie) {
      userProfile.cookieLoad = false;
      if (this.state.isUsingNetscape) {
        userProfile.cookie = Buffer.from(this.state.convertedCookie).toString('base64');
      } else {
        userProfile.cookie = Buffer.from(this.state.cookie).toString('base64');
      }
    } else {
      userProfile.cookieLoad = this.state.cookieLoad;
      userProfile.cookie = Buffer.from(this.state.cookie).toString('base64');
    }

    if (this.state.chProxy && this.state.checkercode === 0) {
      userProfile.proxyProtocol = this.state.proxyProtocol;
      userProfile.proxyIp = this.state.proxyIp;
      userProfile.proxyPort = this.state.proxyPort;
      userProfile.proxyUser = this.state.proxyUser;
      userProfile.proxyPassword = this.state.proxyPassword;
      var ipforchecker = this.state.checkerip;
    }

    if (this.state.chProxy && this.state.checkercode !== 0) {
      userProfile.proxyProtocol = this.state.proxyProtocol;
      userProfile.proxyIp = this.state.proxyIp;
      userProfile.proxyPort = this.state.proxyPort;
      userProfile.proxyUser = this.state.proxyUser;
      userProfile.proxyPassword = this.state.proxyPassword;
      var ipforchecker = this.state.proxyIp;
    }

    if (!this.state.chProxy) {
      userProfile.proxyProtocol = '';
      userProfile.proxyIp = '';
      userProfile.proxyPort = '';
      userProfile.proxyUser = '';
      userProfile.proxyPassword = '';
      var ipforchecker = '';
    }

    axios
      .post(
        process.env.REACT_APP_PUBLIC_API + '/api/get_ip_info',
        {
          ip: ipforchecker,
          dateNow: Date.now()
        },
        {
          headers: {
            Authorization: 'F490BD70F30ACA66A2BA8D00F40479E41CE945FC939A6919A95C73C7BBC26853'
          }
        }
      )
      .then((res) => {
        userProfile.accept_language = this.state.accept_language === '' ? res.data.lang.value : this.state.accept_language;
        userProfile.language_Const1 = this.state.language_Const1 === '' ? res.data.lang.lang : this.state.language_Const1;
        userProfile.languageConst1 = this.state.languageConst1 === '' ? res.data.lang.langs : this.state.languageConst1.split(',');
        userProfile.sysTimeRegion = this.state.sysTimeRegion === '' ? res.data.region : this.state.sysTimeRegion;
        userProfile.sysTimeConst = this.state.sysTimeConst === '' ? (res.data.time > 0 ? '+' + res.data.time.toString() : res.data.time.toString()) : this.state.sysTimeConst;
        userProfile.gpslat = this.state.gpslat === '' ? res.data.geo[0] : +this.state.gpslat;
        userProfile.gpslon = this.state.gpslon === '' ? res.data.geo[1] : +this.state.gpslon;
        userProfile.daylightOffset = this.state.daylightOffset === '' ? res.data.offset : this.state.daylightOffset;
        //userProfile.daylightOffset = moment.tz(Date.now(), userProfile.sysTimeRegion).utcOffset() * -1;
        if (this.state.WebRTCIP == 'your real ip') {
          userProfile.WebRTCIP = res.data.returnIP;
          this.setState(() => ({ WebRTCIP: res.data.returnIP }));
        }
      })
      .then(() => {
        this.addProfileToBase(userProfile);
      })
      .catch((_) => {
        this.setState({ serverUserError: true });
      });
  };

  becomeProfile = async (googleMode, realScreen) => {
    this.setState({
      profileReady: false,
      loadingBaseProcess: true,
      serverBaseError: false,
      fromUserLoaded: false
    });

    const token = localStorage.getItem('token');

    await axios
      .post(
        process.env.REACT_APP_PUBLIC_API + '/api/get_current_profile',
        {
          id: this.state.chPreProfileID
        },
        {
          headers: {
            authorization: token
          }
        }
      )
      .then((res) => {
        const memorylist = [4, 6, 8];
        const hardwarecpulist = [2, 4, 8];

        const memory = memorylist[Math.floor(Math.random() * memorylist.length)];
        const hardwarecpu = hardwarecpulist[Math.floor(Math.random() * hardwarecpulist.length)];

        const broOsVer = `${res.data.headers.browser.name}|${res.data.headers.os.name}|${res.data.headers.os.version}`;
        const broOs = `${res.data.headers.browser.name}|${res.data.headers.os.name}`;

        const version = res.data.headers.os.version;

        switch (true) {
          case 'Opera|Windows|7' == broOsVer:
            this.setState(() => ({
              appCodeNameConst: uaNew.Windows.v7.Opera.appCodeName,
              appNameConst: uaNew.Windows.v7.Opera.appName,
              appVersionConst: uaNew.Windows.v7.Opera.appVersion,
              platformConst: uaNew.Windows.v7.Opera.platform,
              productConst: uaNew.Windows.v7.Opera.product,
              productSubConst: uaNew.Windows.v7.Opera.productSub,
              userAgentConst: uaNew.Windows.v7.Opera.userAgent,
              vendorConst: uaNew.Windows.v7.Opera.vendor,
              vendorSubConst: uaNew.Windows.v7.Opera.vendorSub,
              buildIDConst: '',
              oscpuConst: '',
              uaFullversion: uaNew.Windows.v7.Opera.uaFullversion,
              brands: JSON.stringify(uaNew.Windows.v7.Opera.brands),
              platformUaData: uaNew.Windows.v7.Opera.platformUaData,
              platformVersionUaData: uaNew.Windows.v7.Opera.platformVersionUaData
            }));
            break;
          case 'Opera|Windows|8.1' == broOsVer:
            this.setState(() => ({
              appCodeNameConst: uaNew.Windows.v8_1.Opera.appCodeName,
              appNameConst: uaNew.Windows.v8_1.Opera.appName,
              appVersionConst: uaNew.Windows.v8_1.Opera.appVersion,
              platformConst: uaNew.Windows.v8_1.Opera.platform,
              productConst: uaNew.Windows.v8_1.Opera.product,
              productSubConst: uaNew.Windows.v8_1.Opera.productSub,
              userAgentConst: uaNew.Windows.v8_1.Opera.userAgent,
              vendorConst: uaNew.Windows.v8_1.Opera.vendor,
              vendorSubConst: uaNew.Windows.v8_1.Opera.vendorSub,
              buildIDConst: '',
              oscpuConst: '',
              uaFullversion: uaNew.Windows.v8_1.Opera.uaFullversion,
              brands: JSON.stringify(uaNew.Windows.v8_1.Opera.brands),
              platformUaData: uaNew.Windows.v8_1.Opera.platformUaData,
              platformVersionUaData: uaNew.Windows.v8_1.Opera.platformVersionUaData
            }));
            break;
          case 'Opera|Windows|10' == broOsVer:
            this.setState(() => ({
              appCodeNameConst: uaNew.Windows.v10.Opera.appCodeName,
              appNameConst: uaNew.Windows.v10.Opera.appName,
              appVersionConst: uaNew.Windows.v10.Opera.appVersion,
              platformConst: uaNew.Windows.v10.Opera.platform,
              productConst: uaNew.Windows.v10.Opera.product,
              productSubConst: uaNew.Windows.v10.Opera.productSub,
              userAgentConst: uaNew.Windows.v10.Opera.userAgent,
              vendorConst: uaNew.Windows.v10.Opera.vendor,
              vendorSubConst: uaNew.Windows.v10.Opera.vendorSub,
              buildIDConst: '',
              oscpuConst: '',
              uaFullversion: uaNew.Windows.v10.Opera.uaFullversion,
              brands: JSON.stringify(uaNew.Windows.v10.Opera.brands),
              platformUaData: uaNew.Windows.v10.Opera.platformUaData,
              platformVersionUaData: uaNew.Windows.v10.Opera.platformVersionUaData
            }));
            break;
          case 'Chrome|Windows|7' == broOsVer:
            this.setState(() => ({
              appCodeNameConst: uaNew.Windows.v7.Chrome.appCodeName,
              appNameConst: uaNew.Windows.v7.Chrome.appName,
              appVersionConst: uaNew.Windows.v7.Chrome.appVersion,
              platformConst: uaNew.Windows.v7.Chrome.platform,
              productConst: uaNew.Windows.v7.Chrome.product,
              productSubConst: uaNew.Windows.v7.Chrome.productSub,
              userAgentConst: uaNew.Windows.v7.Chrome.userAgent,
              vendorConst: uaNew.Windows.v7.Chrome.vendor,
              vendorSubConst: uaNew.Windows.v7.Chrome.vendorSub,
              buildIDConst: '',
              oscpuConst: '',
              uaFullversion: uaNew.Windows.v7.Chrome.uaFullversion,
              brands: JSON.stringify(uaNew.Windows.v7.Chrome.brands),
              platformUaData: uaNew.Windows.v7.Chrome.platformUaData,
              platformVersionUaData: uaNew.Windows.v7.Chrome.platformVersionUaData
            }));
            break;
          case 'Chrome|Windows|8.1' == broOsVer:
            this.setState(() => ({
              appCodeNameConst: uaNew.Windows.v8_1.Chrome.appCodeName,
              appNameConst: uaNew.Windows.v8_1.Chrome.appName,
              appVersionConst: uaNew.Windows.v8_1.Chrome.appVersion,
              platformConst: uaNew.Windows.v8_1.Chrome.platform,
              productConst: uaNew.Windows.v8_1.Chrome.product,
              productSubConst: uaNew.Windows.v8_1.Chrome.productSub,
              userAgentConst: uaNew.Windows.v8_1.Chrome.userAgent,
              vendorConst: uaNew.Windows.v8_1.Chrome.vendor,
              vendorSubConst: uaNew.Windows.v8_1.Chrome.vendorSub,
              buildIDConst: '',
              oscpuConst: '',
              uaFullversion: uaNew.Windows.v8_1.Chrome.uaFullversion,
              brands: JSON.stringify(uaNew.Windows.v8_1.Chrome.brands),
              platformUaData: uaNew.Windows.v8_1.Chrome.platformUaData,
              platformVersionUaData: uaNew.Windows.v8_1.Chrome.platformVersionUaData
            }));
            break;
          case 'Chrome|Windows|10' == broOsVer:
            this.setState(() => ({
              appCodeNameConst: uaNew.Windows.v10.Chrome.appCodeName,
              appNameConst: uaNew.Windows.v10.Chrome.appName,
              appVersionConst: uaNew.Windows.v10.Chrome.appVersion,
              platformConst: uaNew.Windows.v10.Chrome.platform,
              productConst: uaNew.Windows.v10.Chrome.product,
              productSubConst: uaNew.Windows.v10.Chrome.productSub,
              userAgentConst: uaNew.Windows.v10.Chrome.userAgent,
              vendorConst: uaNew.Windows.v10.Chrome.vendor,
              vendorSubConst: uaNew.Windows.v10.Chrome.vendorSub,
              buildIDConst: '',
              oscpuConst: '',
              uaFullversion: uaNew.Windows.v10.Chrome.uaFullversion,
              brands: JSON.stringify(uaNew.Windows.v10.Chrome.brands),
              platformUaData: uaNew.Windows.v10.Chrome.platformUaData,
              platformVersionUaData: uaNew.Windows.v10.Chrome.platformVersionUaData
            }));
            break;
          case 'Chrome|Mac OS' == broOs:
            this.setState(() => ({
              appCodeNameConst: uaNew.MacOS.Chrome.appCodeName,
              appNameConst: uaNew.MacOS.Chrome.appName,
              appVersionConst: uaNew.MacOS.Chrome.appVersion,
              platformConst: uaNew.MacOS.Chrome.platform,
              productConst: uaNew.MacOS.Chrome.product,
              productSubConst: uaNew.MacOS.Chrome.productSub,
              userAgentConst: uaNew.MacOS.Chrome.userAgent,
              vendorConst: uaNew.MacOS.Chrome.vendor,
              vendorSubConst: uaNew.MacOS.Chrome.vendorSub,
              buildIDConst: '',
              oscpuConst: '',
              uaFullversion: uaNew.MacOS.Chrome.uaFullversion,
              brands: JSON.stringify(uaNew.MacOS.Chrome.brands),
              platformUaData: uaNew.MacOS.Chrome.platformUaData,
              platformVersionUaData: version
            }));
            break;
          case 'Opera|Mac OS' == broOs:
            this.setState(() => ({
              appCodeNameConst: uaNew.MacOS.Opera.appCodeName,
              appNameConst: uaNew.MacOS.Opera.appName,
              appVersionConst: uaNew.MacOS.Opera.appVersion,
              platformConst: uaNew.MacOS.Opera.platform,
              productConst: uaNew.MacOS.Opera.product,
              productSubConst: uaNew.MacOS.Opera.productSub,
              userAgentConst: uaNew.MacOS.Opera.userAgent,
              vendorConst: uaNew.MacOS.Opera.vendor,
              vendorSubConst: uaNew.MacOS.Opera.vendorSub,
              buildIDConst: '',
              oscpuConst: '',
              uaFullversion: uaNew.MacOS.Opera.uaFullversion,
              brands: JSON.stringify(uaNew.MacOS.Opera.brands),
              platformUaData: uaNew.MacOS.Opera.platformUaData,
              platformVersionUaData: version
            }));
            break;
          default:
            if (res.data.navigator.buildID !== undefined) {
              this.setState(() => ({
                buildIDConst: res.data.navigator.buildID
              }));
            } else {
              this.setState(() => ({ buildIDConst: '' }));
            }
            if (res.data.navigator.oscpu !== undefined) {
              this.setState(() => ({
                oscpuConst: res.data.navigator.oscpu
              }));
            } else {
              this.setState(() => ({ oscpuConst: '' }));
            }
            this.setState(() => ({
              appCodeNameConst: res.data.navigator.appCodeName,
              appNameConst: res.data.navigator.appName,
              appVersionConst: res.data.navigator.appVersion,
              platformConst: res.data.navigator.platform,
              productConst: res.data.navigator.product,
              productSubConst: res.data.navigator.productSub,
              userAgentConst: res.data.navigator.userAgent,
              vendorConst: res.data.navigator.vendor,
              vendorSubConst: res.data.navigator.vendorSub
            }));
        }

        this.setState(() => ({
          os: res.data.headers.os.name,
          browser: res.data.headers.browser.name,

          availHeighthvar: res.data.screen.availHeight,
          availLeftvar: res.data.screen.availLeft,
          availTopvar: res.data.screen.availTop,
          availWidthvar: res.data.screen.availWidth,
          heightvar: res.data.screen.height,
          widthvar: res.data.screen.width,
          colorDepthvar: res.data.screen.colorDepth,
          pixelDepthvar: res.data.screen.pixelDepth,

          fonts: String(res.data.fonts.js_fonts).replace(/"/g, ''),

          blueBits: res.data.webglparams.webgl2.BLUE_BITS,
          greenBits: res.data.webglparams.webgl2.GREEN_BITS,
          alphaBits: res.data.webglparams.webgl2.ALPHA_BITS,
          redBits: res.data.webglparams.webgl2.RED_BITS,
          stencilBits: res.data.webglparams.webgl2.STENCIL_BITS,
          depthBits: res.data.webglparams.webgl2.DEPTH_BITS,
          max3dTextureSize: res.data.webglparams.webgl2.MAX_3D_TEXTURE_SIZE,
          maxArrayTextureLayers: res.data.webglparams.webgl2.MAX_ARRAY_TEXTURE_LAYERS,
          maxColorAttachments: res.data.webglparams.webgl2.MAX_COLOR_ATTACHMENTS,
          maxCombinedFragmentUniformComponents: res.data.webglparams.webgl2.MAX_COMBINED_FRAGMENT_UNIFORM_COMPONENTS,
          maxCombinedTextureImageUnits: res.data.webglparams.webgl2.MAX_COMBINED_TEXTURE_IMAGE_UNITS,
          maxCombinedUniformBlocks: res.data.webglparams.webgl2.MAX_COMBINED_UNIFORM_BLOCKS,
          maxCombinedVertexUniformComponents: res.data.webglparams.webgl2.MAX_COMBINED_VERTEX_UNIFORM_COMPONENTS,
          maxCubeMapTextureSize: res.data.webglparams.webgl2.MAX_CUBE_MAP_TEXTURE_SIZE,
          maxDrawBuffers: res.data.webglparams.webgl2.MAX_DRAW_BUFFERS,
          maxFragmentInputComponents: res.data.webglparams.webgl2.MAX_FRAGMENT_INPUT_COMPONENTS,
          maxFragmentUniformBlocks: res.data.webglparams.webgl2.MAX_FRAGMENT_UNIFORM_BLOCKS,
          maxFragmentUniformComponents: res.data.webglparams.webgl2.MAX_FRAGMENT_UNIFORM_COMPONENTS,
          maxFragmentUniformVectors: res.data.webglparams.webgl2.MAX_FRAGMENT_UNIFORM_VECTORS,
          maxProgramTexelOffset: res.data.webglparams.webgl2.MAX_PROGRAM_TEXEL_OFFSET,
          maxRenderBufferSize: res.data.webglparams.webgl2.MAX_RENDERBUFFER_SIZE,
          maxSamples: res.data.webglparams.webgl2.MAX_SAMPLES,
          maxTextureImageUnits: res.data.webglparams.webgl2.MAX_TEXTURE_IMAGE_UNITS,
          maxTextureLodBias: res.data.webglparams.webgl2.MAX_TEXTURE_LOD_BIAS,
          maxTextureSize: res.data.webglparams.webgl2.MAX_TEXTURE_SIZE,
          maxTransformFeedbackInterleavedComponents: res.data.webglparams.webgl2.MAX_TRANSFORM_FEEDBACK_INTERLEAVED_COMPONENTS,
          maxTransformFeedbackSeparateAttribs: res.data.webglparams.webgl2.MAX_TRANSFORM_FEEDBACK_SEPARATE_ATTRIBS,
          maxTransformFeedbackSeparateComponents: res.data.webglparams.webgl2.MAX_TRANSFORM_FEEDBACK_SEPARATE_COMPONENTS,
          maxUniformBlockSize: res.data.webglparams.webgl2.MAX_UNIFORM_BLOCK_SIZE,
          maxUniformBufferBindings: res.data.webglparams.webgl2.MAX_UNIFORM_BUFFER_BINDINGS,
          maxVaryingComponents: res.data.webglparams.webgl2.MAX_VARYING_COMPONENTS,
          maxVaryingVectors: res.data.webglparams.webgl2.MAX_VARYING_VECTORS,
          maxVertexOutputComponents: res.data.webglparams.webgl2.MAX_VERTEX_OUTPUT_COMPONENTS,
          maxVertexTextureImageUnits: res.data.webglparams.webgl2.MAX_VERTEX_TEXTURE_IMAGE_UNITS,
          maxVertexUniformBlocks: res.data.webglparams.webgl2.MAX_VERTEX_UNIFORM_BLOCKS,
          maxVertexUniformComponents: res.data.webglparams.webgl2.MAX_VERTEX_UNIFORM_COMPONENTS,
          maxVertexattribs: res.data.webglparams.webgl2.MAX_VERTEX_ATTRIBS,
          maxVertexuniformvectors: res.data.webglparams.webgl2.MAX_VERTEX_UNIFORM_VECTORS,
          minProgramTexelOffset: res.data.webglparams.webgl2.MIN_PROGRAM_TEXEL_OFFSET,
          maxTextureMaxAnisotropyExt: res.data.webglparams.webgl2.EXTENSIONS.EXT_texture_filter_anisotropic.MAX_TEXTURE_MAX_ANISOTROPY_EXT,
          maxViewportDims: '[' + res.data.webglparams.webgl2.MAX_VIEWPORT_DIMS[0] + ',' + res.data.webglparams.webgl2.MAX_VIEWPORT_DIMS[1] + ']',
          aliasedLineWidthRange: '[' + res.data.webglparams.webgl2.ALIASED_LINE_WIDTH_RANGE[0] + ',' + res.data.webglparams.webgl2.ALIASED_LINE_WIDTH_RANGE[1] + ']',
          aliasedPointSizeRange: '[' + res.data.webglparams.webgl2.ALIASED_POINT_SIZE_RANGE[0] + ',' + res.data.webglparams.webgl2.ALIASED_LINE_WIDTH_RANGE[1] + ']',
          shadinglanguageversion: res.data.webglparams.webgl2.SHADING_LANGUAGE_VERSION,
          uniformBufferOffsetAlignment: res.data.webglparams.webgl2.UNIFORM_BUFFER_OFFSET_ALIGNMENT,
          unmaskedRendererWebgl: res.data.webglparams.webgl2.EXTENSIONS.WEBGL_debug_renderer_info.UNMASKED_RENDERER_WEBGL,
          unmaskedVendorWebgl: res.data.webglparams.webgl2.EXTENSIONS.WEBGL_debug_renderer_info.UNMASKED_VENDOR_WEBGL,
          webglRenderer: res.data.webglparams.webgl2.RENDERER,
          webglVendor: res.data.webglparams.webgl2.VENDOR,
          glVersion: res.data.webglparams.webgl2.VERSION,
          WEBGL2_ARRAY_BUFFER_BINDING: res.data.webglparams.webgl2.ARRAY_BUFFER_BINDING,
          WEBGL2_ACTIVE_TEXTURE: res.data.webglparams.webgl2.ACTIVE_TEXTURE,
          WEBGL2_BLEND: res.data.webglparams.webgl2.BLEND,
          WEBGL2_BLEND_COLOR:
            '[' +
            res.data.webglparams.webgl2.BLEND_COLOR[0] +
            ',' +
            res.data.webglparams.webgl2.BLEND_COLOR[1] +
            ',' +
            res.data.webglparams.webgl2.BLEND_COLOR[2] +
            ',' +
            res.data.webglparams.webgl2.BLEND_COLOR[3] +
            ']',
          WEBGL2_BLEND_DST_ALPHA: res.data.webglparams.webgl2.BLEND_DST_ALPHA,
          WEBGL2_BLEND_DST_RGB: res.data.webglparams.webgl2.BLEND_DST_RGB,
          WEBGL2_BLEND_EQUATION_ALPHA: res.data.webglparams.webgl2.BLEND_EQUATION_ALPHA,
          WEBGL2_BLEND_EQUATION_RGB: res.data.webglparams.webgl2.BLEND_EQUATION_RGB,
          WEBGL2_BLEND_SRC_ALPHA: res.data.webglparams.webgl2.BLEND_SRC_ALPHA,
          WEBGL2_BLEND_SRC_RGB: res.data.webglparams.webgl2.BLEND_SRC_RGB,
          WEBGL2_COLOR_CLEAR_VALUE:
            '[' +
            res.data.webglparams.webgl2.COLOR_CLEAR_VALUE[0] +
            ',' +
            res.data.webglparams.webgl2.COLOR_CLEAR_VALUE[1] +
            ',' +
            res.data.webglparams.webgl2.COLOR_CLEAR_VALUE[2] +
            ',' +
            res.data.webglparams.webgl2.COLOR_CLEAR_VALUE[3] +
            ']',
          WEBGL2_COLOR_WRITEMASK: res.data.webglparams.webgl2.COLOR_WRITEMASK,
          WEBGL2_CULL_FACE: res.data.webglparams.webgl2.CULL_FACE,
          WEBGL2_CULL_FACE_MODE: res.data.webglparams.webgl2.CULL_FACE_MODE,
          WEBGL2_CURRENT_PROGRAM: res.data.webglparams.webgl2.CURRENT_PROGRAM,
          WEBGL2_DEPTH_CLEAR_VALUE: res.data.webglparams.webgl2.DEPTH_CLEAR_VALUE,
          WEBGL2_DEPTH_FUNC: res.data.webglparams.webgl2.DEPTH_FUNC,
          WEBGL2_DEPTH_RANGE: '[' + res.data.webglparams.webgl2.DEPTH_RANGE[0] + ',' + res.data.webglparams.webgl2.DEPTH_RANGE[1] + ']',
          WEBGL2_DEPTH_TEST: res.data.webglparams.webgl2.DEPTH_TEST,
          WEBGL2_DEPTH_WRITEMASK: res.data.webglparams.webgl2.DEPTH_WRITEMASK,
          WEBGL2_DITHER: res.data.webglparams.webgl2.DITHER,
          WEBGL2_ELEMENT_ARRAY_BUFFER_BINDING: res.data.webglparams.webgl2.ELEMENT_ARRAY_BUFFER_BINDING,
          WEBGL2_FRAMEBUFFER_BINDING: res.data.webglparams.webgl2.FRAMEBUFFER_BINDING,
          WEBGL2_FRONT_FACE: res.data.webglparams.webgl2.FRONT_FACE,
          WEBGL2_GENERATE_MIPMAP_HINT: res.data.webglparams.webgl2.GENERATE_MIPMAP_HINT,
          WEBGL2_LINE_WIDTH: res.data.webglparams.webgl2.LINE_WIDTH,
          WEBGL2_PACK_ALIGNMENT: res.data.webglparams.webgl2.PACK_ALIGNMENT,
          WEBGL2_POLYGON_OFFSET_FACTOR: res.data.webglparams.webgl2.POLYGON_OFFSET_FACTOR,
          WEBGL2_POLYGON_OFFSET_FILL: res.data.webglparams.webgl2.POLYGON_OFFSET_FILL,
          WEBGL2_POLYGON_OFFSET_UNITS: res.data.webglparams.webgl2.POLYGON_OFFSET_UNITS,
          WEBGL2_RENDERBUFFER_BINDING: res.data.webglparams.webgl2.RENDERBUFFER_BINDING,
          WEBGL2_SAMPLE_BUFFERS: res.data.webglparams.webgl2.SAMPLE_BUFFERS,
          WEBGL2_SAMPLE_COVERAGE_INVERT: res.data.webglparams.webgl2.SAMPLE_COVERAGE_INVERT,
          WEBGL2_SAMPLE_COVERAGE_VALUE: res.data.webglparams.webgl2.SAMPLE_COVERAGE_VALUE,
          WEBGL2_SAMPLES: res.data.webglparams.webgl2.SAMPLES,
          WEBGL2_STENCIL_BACK_FAIL: res.data.webglparams.webgl2.STENCIL_BACK_FAIL,
          WEBGL2_STENCIL_BACK_FUNC: res.data.webglparams.webgl2.STENCIL_BACK_FUNC,
          WEBGL2_STENCIL_BACK_PASS_DEPTH_FAIL: res.data.webglparams.webgl2.STENCIL_BACK_PASS_DEPTH_FAIL,
          WEBGL2_STENCIL_BACK_PASS_DEPTH_PASS: res.data.webglparams.webgl2.STENCIL_BACK_PASS_DEPTH_PASS,
          WEBGL2_STENCIL_BACK_REF: res.data.webglparams.webgl2.STENCIL_BACK_REF,
          WEBGL2_STENCIL_BACK_VALUE_MASK: res.data.webglparams.webgl2.STENCIL_BACK_VALUE_MASK,
          WEBGL2_STENCIL_BACK_WRITEMASK: res.data.webglparams.webgl2.STENCIL_BACK_WRITEMASK,
          WEBGL2_STENCIL_CLEAR_VALUE: res.data.webglparams.webgl2.STENCIL_CLEAR_VALUE,
          WEBGL2_STENCIL_FAIL: res.data.webglparams.webgl2.STENCIL_FAIL,
          WEBGL2_STENCIL_FUNC: res.data.webglparams.webgl2.STENCIL_FUNC,
          WEBGL2_STENCIL_PASS_DEPTH_FAIL: res.data.webglparams.webgl2.STENCIL_PASS_DEPTH_FAIL,
          WEBGL2_STENCIL_PASS_DEPTH_PASS: res.data.webglparams.webgl2.STENCIL_PASS_DEPTH_PASS,
          WEBGL2_STENCIL_REF: res.data.webglparams.webgl2.STENCIL_REF,
          WEBGL2_STENCIL_TEST: res.data.webglparams.webgl2.STENCIL_TEST,
          WEBGL2_STENCIL_VALUE_MASK: res.data.webglparams.webgl2.STENCIL_VALUE_MASK,
          WEBGL2_STENCIL_WRITEMASK: res.data.webglparams.webgl2.STENCIL_WRITEMASK,
          WEBGL2_SUBPIXEL_BITS: res.data.webglparams.webgl2.SUBPIXEL_BITS,
          WEBGL2_TEXTURE_BINDING_2D: res.data.webglparams.webgl2.TEXTURE_BINDING_2D,
          WEBGL2_TEXTURE_BINDING_CUBE_MAP: res.data.webglparams.webgl2.TEXTURE_BINDING_CUBE_MAP,
          WEBGL2_UNPACK_ALIGNMENT: res.data.webglparams.webgl2.UNPACK_ALIGNMENT,
          WEBGL2_UNPACK_COLORSPACE_CONVERSION_WEBGL: res.data.webglparams.webgl2.UNPACK_COLORSPACE_CONVERSION_WEBGL,
          WEBGL2_UNPACK_FLIP_Y_WEBGL: res.data.webglparams.webgl2.UNPACK_FLIP_Y_WEBGL,
          WEBGL2_UNPACK_PREMULTIPLY_ALPHA_WEBGL: res.data.webglparams.webgl2.UNPACK_PREMULTIPLY_ALPHA_WEBGL,

          WEBGL1_blueBits: res.data.webglparams.webgl.BLUE_BITS,
          WEBGL1_greenBits: res.data.webglparams.webgl.GREEN_BITS,
          WEBGL1_alphaBits: res.data.webglparams.webgl.ALPHA_BITS,
          WEBGL1_redBits: res.data.webglparams.webgl.RED_BITS,
          WEBGL1_stencilBits: res.data.webglparams.webgl.STENCIL_BITS,
          WEBGL1_depthBits: res.data.webglparams.webgl.DEPTH_BITS,
          WEBGL1_maxCombinedTextureImageUnits: res.data.webglparams.webgl.MAX_COMBINED_TEXTURE_IMAGE_UNITS,
          WEBGL1_maxCubeMapTextureSize: res.data.webglparams.webgl.MAX_CUBE_MAP_TEXTURE_SIZE,
          WEBGL1_maxFragmentUniformVectors: res.data.webglparams.webgl.MAX_FRAGMENT_UNIFORM_VECTORS,
          WEBGL1_maxRenderBufferSize: res.data.webglparams.webgl.MAX_RENDERBUFFER_SIZE,
          WEBGL1_maxTextureImageUnits: res.data.webglparams.webgl.MAX_TEXTURE_IMAGE_UNITS,
          WEBGL1_maxTextureSize: res.data.webglparams.webgl.MAX_TEXTURE_SIZE,
          WEBGL1_maxVaryingVectors: res.data.webglparams.webgl.MAX_VARYING_VECTORS,
          WEBGL1_maxVertexTextureImageUnits: res.data.webglparams.webgl.MAX_VERTEX_TEXTURE_IMAGE_UNITS,
          WEBGL1_maxVertexattribs: res.data.webglparams.webgl.MAX_VERTEX_ATTRIBS,
          WEBGL1_maxVertexuniformvectors: res.data.webglparams.webgl.MAX_VERTEX_UNIFORM_VECTORS,
          WEBGL1_maxTextureMaxAnisotropyExt: res.data.webglparams.webgl.EXTENSIONS.EXT_texture_filter_anisotropic.MAX_TEXTURE_MAX_ANISOTROPY_EXT,
          WEBGL1_maxViewportDims: '[' + res.data.webglparams.webgl.MAX_VIEWPORT_DIMS[0] + ',' + res.data.webglparams.webgl.MAX_VIEWPORT_DIMS[1] + ']',
          WEBGL1_aliasedLineWidthRange: '[' + res.data.webglparams.webgl.ALIASED_LINE_WIDTH_RANGE[0] + ',' + res.data.webglparams.webgl.ALIASED_LINE_WIDTH_RANGE[1] + ']',
          WEBGL1_aliasedPointSizeRange: '[' + res.data.webglparams.webgl.ALIASED_POINT_SIZE_RANGE[0] + ',' + res.data.webglparams.webgl.ALIASED_LINE_WIDTH_RANGE[1] + ']',
          WEBGL1_shadinglanguageversion: res.data.webglparams.webgl.SHADING_LANGUAGE_VERSION,
          WEBGL1_unmaskedRendererWebgl: res.data.webglparams.webgl.EXTENSIONS.WEBGL_debug_renderer_info.UNMASKED_RENDERER_WEBGL,
          WEBGL1_unmaskedVendorWebgl: res.data.webglparams.webgl.EXTENSIONS.WEBGL_debug_renderer_info.UNMASKED_VENDOR_WEBGL,
          WEBGL1_webglRenderer: res.data.webglparams.webgl.RENDERER,
          WEBGL1_webglVendor: res.data.webglparams.webgl.VENDOR,
          WEBGL1_glVersion: res.data.webglparams.webgl.VERSION,
          WEBGL1_ARRAY_BUFFER_BINDING: res.data.webglparams.webgl.ARRAY_BUFFER_BINDING,
          WEBGL1_ACTIVE_TEXTURE: res.data.webglparams.webgl.ACTIVE_TEXTURE,
          WEBGL1_BLEND: res.data.webglparams.webgl.BLEND,
          WEBGL1_BLEND_COLOR:
            '[' +
            res.data.webglparams.webgl.BLEND_COLOR[0] +
            ',' +
            res.data.webglparams.webgl.BLEND_COLOR[1] +
            ',' +
            res.data.webglparams.webgl.BLEND_COLOR[2] +
            ',' +
            res.data.webglparams.webgl.BLEND_COLOR[3] +
            ']',
          WEBGL1_BLEND_DST_ALPHA: res.data.webglparams.webgl.BLEND_DST_ALPHA,
          WEBGL1_BLEND_DST_RGB: res.data.webglparams.webgl.BLEND_DST_RGB,
          WEBGL1_BLEND_EQUATION_ALPHA: res.data.webglparams.webgl.BLEND_EQUATION_ALPHA,
          WEBGL1_BLEND_EQUATION_RGB: res.data.webglparams.webgl.BLEND_EQUATION_RGB,
          WEBGL1_BLEND_SRC_ALPHA: res.data.webglparams.webgl.BLEND_SRC_ALPHA,
          WEBGL1_BLEND_SRC_RGB: res.data.webglparams.webgl.BLEND_SRC_RGB,
          WEBGL1_COLOR_CLEAR_VALUE:
            '[' +
            res.data.webglparams.webgl.COLOR_CLEAR_VALUE[0] +
            ',' +
            res.data.webglparams.webgl.COLOR_CLEAR_VALUE[1] +
            ',' +
            res.data.webglparams.webgl.COLOR_CLEAR_VALUE[2] +
            ',' +
            res.data.webglparams.webgl.COLOR_CLEAR_VALUE[3] +
            ']',
          WEBGL1_COLOR_WRITEMASK: res.data.webglparams.webgl.COLOR_WRITEMASK,
          WEBGL1_CULL_FACE: res.data.webglparams.webgl.CULL_FACE,
          WEBGL1_CULL_FACE_MODE: res.data.webglparams.webgl.CULL_FACE_MODE,
          WEBGL1_CURRENT_PROGRAM: res.data.webglparams.webgl.CURRENT_PROGRAM,
          WEBGL1_DEPTH_CLEAR_VALUE: res.data.webglparams.webgl.DEPTH_CLEAR_VALUE,
          WEBGL1_DEPTH_FUNC: res.data.webglparams.webgl.DEPTH_FUNC,
          WEBGL1_DEPTH_RANGE: '[' + res.data.webglparams.webgl.DEPTH_RANGE[0] + ',' + res.data.webglparams.webgl.DEPTH_RANGE[1] + ']',
          WEBGL1_DEPTH_TEST: res.data.webglparams.webgl.DEPTH_TEST,
          WEBGL1_DEPTH_WRITEMASK: res.data.webglparams.webgl.DEPTH_WRITEMASK,
          WEBGL1_DITHER: res.data.webglparams.webgl.DITHER,
          WEBGL1_ELEMENT_ARRAY_BUFFER_BINDING: res.data.webglparams.webgl.ELEMENT_ARRAY_BUFFER_BINDING,
          WEBGL1_FRAMEBUFFER_BINDING: res.data.webglparams.webgl.FRAMEBUFFER_BINDING,
          WEBGL1_FRONT_FACE: res.data.webglparams.webgl.FRONT_FACE,
          WEBGL1_GENERATE_MIPMAP_HINT: res.data.webglparams.webgl.GENERATE_MIPMAP_HINT,
          WEBGL1_LINE_WIDTH: res.data.webglparams.webgl.LINE_WIDTH,
          WEBGL1_PACK_ALIGNMENT: res.data.webglparams.webgl.PACK_ALIGNMENT,
          WEBGL1_POLYGON_OFFSET_FACTOR: res.data.webglparams.webgl.POLYGON_OFFSET_FACTOR,
          WEBGL1_POLYGON_OFFSET_FILL: res.data.webglparams.webgl.POLYGON_OFFSET_FILL,
          WEBGL1_POLYGON_OFFSET_UNITS: res.data.webglparams.webgl.POLYGON_OFFSET_UNITS,
          WEBGL1_RENDERBUFFER_BINDING: res.data.webglparams.webgl.RENDERBUFFER_BINDING,
          WEBGL1_SAMPLE_BUFFERS: res.data.webglparams.webgl.SAMPLE_BUFFERS,
          WEBGL1_SAMPLE_COVERAGE_INVERT: res.data.webglparams.webgl.SAMPLE_COVERAGE_INVERT,
          WEBGL1_SAMPLE_COVERAGE_VALUE: res.data.webglparams.webgl.SAMPLE_COVERAGE_VALUE,
          WEBGL1_SAMPLES: res.data.webglparams.webgl.SAMPLES,
          WEBGL1_STENCIL_BACK_FAIL: res.data.webglparams.webgl.STENCIL_BACK_FAIL,
          WEBGL1_STENCIL_BACK_FUNC: res.data.webglparams.webgl.STENCIL_BACK_FUNC,
          WEBGL1_STENCIL_BACK_PASS_DEPTH_FAIL: res.data.webglparams.webgl.STENCIL_BACK_PASS_DEPTH_FAIL,
          WEBGL1_STENCIL_BACK_PASS_DEPTH_PASS: res.data.webglparams.webgl.STENCIL_BACK_PASS_DEPTH_PASS,
          WEBGL1_STENCIL_BACK_REF: res.data.webglparams.webgl.STENCIL_BACK_REF,
          WEBGL1_STENCIL_BACK_VALUE_MASK: res.data.webglparams.webgl.STENCIL_BACK_VALUE_MASK,
          WEBGL1_STENCIL_BACK_WRITEMASK: res.data.webglparams.webgl.STENCIL_BACK_WRITEMASK,
          WEBGL1_STENCIL_CLEAR_VALUE: res.data.webglparams.webgl.STENCIL_CLEAR_VALUE,
          WEBGL1_STENCIL_FAIL: res.data.webglparams.webgl.STENCIL_FAIL,
          WEBGL1_STENCIL_FUNC: res.data.webglparams.webgl.STENCIL_FUNC,
          WEBGL1_STENCIL_PASS_DEPTH_FAIL: res.data.webglparams.webgl.STENCIL_PASS_DEPTH_FAIL,
          WEBGL1_STENCIL_PASS_DEPTH_PASS: res.data.webglparams.webgl.STENCIL_PASS_DEPTH_PASS,
          WEBGL1_STENCIL_REF: res.data.webglparams.webgl.STENCIL_REF,
          WEBGL1_STENCIL_TEST: res.data.webglparams.webgl.STENCIL_TEST,
          WEBGL1_STENCIL_VALUE_MASK: res.data.webglparams.webgl.STENCIL_VALUE_MASK,
          WEBGL1_STENCIL_WRITEMASK: res.data.webglparams.webgl.STENCIL_WRITEMASK,
          WEBGL1_SUBPIXEL_BITS: res.data.webglparams.webgl.SUBPIXEL_BITS,
          WEBGL1_TEXTURE_BINDING_2D: res.data.webglparams.webgl.TEXTURE_BINDING_2D,
          WEBGL1_TEXTURE_BINDING_CUBE_MAP: res.data.webglparams.webgl.TEXTURE_BINDING_CUBE_MAP,
          WEBGL1_UNPACK_ALIGNMENT: res.data.webglparams.webgl.UNPACK_ALIGNMENT,
          WEBGL1_UNPACK_COLORSPACE_CONVERSION_WEBGL: res.data.webglparams.webgl.UNPACK_COLORSPACE_CONVERSION_WEBGL,
          WEBGL1_UNPACK_FLIP_Y_WEBGL: res.data.webglparams.webgl.UNPACK_FLIP_Y_WEBGL,
          WEBGL1_UNPACK_PREMULTIPLY_ALPHA_WEBGL: res.data.webglparams.webgl.UNPACK_PREMULTIPLY_ALPHA_WEBGL,

          webglFloatPrecision: '[' + Object.keys(res.data.webglparams.webgl2.EXTENSIONS) + ']',
          WEBGL2_Extentions: '[' + Object.keys(res.data.webglparams.webgl2.EXTENSIONS) + ']',
          WEBGL1_Extentions: '[' + Object.keys(res.data.webglparams.webgl.EXTENSIONS) + ']'
        }));

        this.setState({ deviceMemoryConst: '' + memory });
        this.setState({ hardwareConcurrencyConst: '' + hardwarecpu });

        this.setState({
          canvasNoise: canvasNoise,
          webglNoise: webglNoise,
          audioNoise: audioNoise,
          doNotTrack: doNotTrack,
          fontsSpoof: fontsSpoof,
          ObserverWebRTC: ObserverWebRTC,
          enable911: enable911,
          spoofingScreenSwitch: true,
          seed: seed,
          audiohash: audiohash,
          rects: rects,
          webglNoiseR: webglNoiseR,
          webglNoiseG: webglNoiseG,
          webglNoiseB: webglNoiseB,
          webglNoiseA: webglNoiseA,
          webglNoiseString1: webglNoiseString1,
          webglNoiseString2: webglNoiseString2,
          audioNoiseConst1: audioNoiseConst1,
          audioNoiseConst2: audioNoiseConst2,
          audioNoiseConst3: audioNoiseConst3,
          audioNoiseConst4: audioNoiseConst4
        });

        let seed = randomSeed();
        let audiohash = randomHash();
        let rects = randomHash();

        let webrtc1 = randomInteger(2645310188363806756, 8629887588005908620);
        let webrtc2 = randomInteger(27004, 61592);
        let webrtc3 = randomInteger(118, 119);
        let webrtc4 = randomInteger(112508026, 3916767017);
        let webrtc6 = randomString(4);
        let webrtc7 = randomString(24);
        let webrtc8 = randomSha256();
        let webrtc9 = randomString(16);

        let WebRTClocalIP = '192.168.1.' + randomInteger(5, 230);
        let ObserverWebRTC = true;
        let WebRTCIP = 'your real ip';

        let mediaDevices = res.data.navigator.mediaDevices;

        let webglNoiseR = 30 - randomIntFromInterval(0, 40);
        let webglNoiseG = 30 - randomIntFromInterval(0, 40);
        let webglNoiseB = 30 - randomIntFromInterval(0, 40);
        let webglNoiseA = 30 - randomIntFromInterval(0, 40);
        let webglNoiseString1 = getRandomString();
        let webglNoiseString2 = getRandomString();
        let audioNoiseConst1 = Math.random();
        let audioNoiseConst2 = Math.random();
        let audioNoiseConst3 = Math.random();
        let audioNoiseConst4 = Math.random();
        let canvasNoise = googleMode ? 0 : 1;
        let webglNoise = 1;
        let audioNoise = 1;
        let doNotTrack = 0;
        let enable911 = 0;
        let fontsSpoof = googleMode ? 0 : 1;

        googleMode && this.setState({ fontsSpoofSwitch: false, canvasNoiseSwitch: false });
        realScreen && this.setState({ spoofingScreenSwitch: false });

        this.setState({
          webrtc1: webrtc1,
          webrtc2: webrtc2,
          webrtc3: webrtc3,
          webrtc4: webrtc4,
          webrtc6: webrtc6,
          webrtc7: webrtc7,
          webrtc8: webrtc8,
          webrtc9: webrtc9,
          WebRTClocalIP: WebRTClocalIP,
          WebRTCIP: WebRTCIP,
          mediaDevices: mediaDevices,
          canvasNoise: canvasNoise,
          webglNoise: webglNoise,
          audioNoise: audioNoise,
          doNotTrack: doNotTrack,
          fontsSpoof: fontsSpoof,
          ObserverWebRTC: ObserverWebRTC,
          enable911: enable911,
          seed: seed,
          audiohash: audiohash,
          rects: rects,
          webglNoiseR: webglNoiseR,
          webglNoiseG: webglNoiseG,
          webglNoiseB: webglNoiseB,
          webglNoiseA: webglNoiseA,
          webglNoiseString1: webglNoiseString1,
          webglNoiseString2: webglNoiseString2,
          audioNoiseConst1: audioNoiseConst1,
          audioNoiseConst2: audioNoiseConst2,
          audioNoiseConst3: audioNoiseConst3,
          audioNoiseConst4: audioNoiseConst4
        });

        this.setState(
          () => ({}),
          () => {
            this.setState({
              profileReady: true,
              profileSaved: false,
              profileName: '',
              chProxy: false,
              fromBaseLoaded: true,
              fromUserLoaded: false,
              loadingBaseProcess: false,
              enable911Switch: false
            });
          }
        );
      })
      .catch((err) => {
        console.log('error');
        this.setState({ serverBaseError: true, loadingBaseProcess: false });
      });
  };

  fillLangTime = (ip) => {
    axios
      .post(
        process.env.REACT_APP_PUBLIC_API + '/api/get_ip_info',
        {
          ip: ip,
          dateNow: Date.now()
        },
        {
          headers: {
            Authorization: 'F490BD70F30ACA66A2BA8D00F40479E41CE945FC939A6919A95C73C7BBC26853'
          }
        }
      )
      .then((res) => {
        this.setState({
          accept_language: res.data.lang.value,
          language_Const1: res.data.lang.lang,
          languageConst1: res.data.lang.langs.join(','),
          sysTimeRegion: res.data.region,
          sysTimeConst: res.data.time.toString() * -1,
          gpslat: res.data.geo[0],
          gpslon: res.data.geo[1],
          daylightOffset: res.data.offset
        });
      });
  };

  ProxyChecker = () => {
    this.setState({
      fromCheckerLoaded: true,
      proxyReady: false,
      checkercode: ''
    });

    const token = localStorage.getItem('token');

    axios
      .post(
        process.env.REACT_APP_PUBLIC_API + '/api/check_proxy',
        {
          login: this.state.chProxy ? this.props.login : '',
          Protocol: this.state.chProxy ? this.state.proxyProtocol : '',
          ip: this.state.chProxy ? this.state.proxyIp : '',
          Port: this.state.chProxy ? this.state.proxyPort : '',
          proxyUser: this.state.chProxy ? this.state.proxyUser : '',
          proxyPassword: this.state.chProxy ? this.state.proxyPassword : ''
        },
        {
          headers: {
            authorization: token
          }
        }
      )
      .then((res) => {
        this.setState(
          () => ({
            checkerip: res.data.ip,
            checkercode: res.data.code
          }),
          () => {
            this.setState({
              proxyReady: true,
              fromCheckerLoaded: false
            });
            if (this.state.ObserverWebRTC) {
              this.setState({ WebRTCIP: res.data.ip });
            }
            if (this.state.checkercode === 0) {
              this.fillLangTime(this.state.checkerip);
            } else {
              this.fillLangTime(this.state.proxyIp);
            }
          }
        );
      })
      .catch((err) => {
        if (err.response) {
          this.setState({
            proxyReady: false,
            fromCheckerLoaded: false
          });
        }
      });
  };

  addProfileToBase = (userProfile) => {
    let newProfile = userProfile;
    newProfile.note = this.state.note;
    newProfile.brands = JSON.parse(userProfile.brands);

    const token = localStorage.getItem('token');
    axios
      .post(
        process.env.REACT_APP_PUBLIC_API + '/api/save_user_profile',
        {
          userProfile: newProfile
        },
        {
          headers: {
            authorization: token
          }
        }
      )
      .then((data) => {
        CreateNotification({
          title: this.props.t('Dashboard.SUCCESS_TITLE'),
          description: this.props.t('Dashboard.GENERATE_SUCCESS'),
          type: 'success'
        });
        this.setState({
          profileSaved: true,
          initProfile: userProfile,
          isUserProfileLoading: false,
          profileSaved: true
        });
        this.becomeAllUserProfiles();
      })
      .catch((err) => {
        if (err.response.status === 409) {
          CreateNotification({
            title: this.props.t('Dashboard.ERROR_TITLE'),
            description: this.props.t('Dashboard.GENERATE_ERROR_COUNT'),
            type: 'error'
          });
          this.setState({ serverUserError: true, isUserProfileLoading: false });
          return;
        }
        CreateNotification({
          title: this.props.t('Dashboard.ERROR_TITLE'),
          description: this.props.t('Dashboard.GENERATE_ERROR'),
          type: 'error'
        });
      });
  };

  deleteProfileFromBase = () => {
    this.setState({ profileReady: false });
    const token = localStorage.getItem('token');
    const idProfile = this.state.userProfileInfo.find((o) => o.profileName === this.state.userProfileName);
    axios
      .post(
        process.env.REACT_APP_PUBLIC_API + '/api/remove_profiles',
        {
          login: this.props.login,
          profiles: [idProfile._id]
        },
        {
          headers: {
            authorization: token
          }
        }
      )
      .then((res) => {
        this.becomeAllUserProfiles();
        this.setState({
          profileReady: true,
          profileSaved: false,
          userProfileName: '',
          profileName: ''
        });
      });
  };

  toRandomProfile = () => {
    this.setState({
      currentProfile: {},
      chos: '',
      chbrowser: '',
      chwidthXheight: '',
      chunmaskedRendererWebgl: '',
      currentProfileReady: false
    });
  };

  changeForm = (e, random, realResolution) => {
    let id = e.currentTarget.id;
    let value = e.currentTarget.value;
    let currentProfile = this.state.currentProfile;

    switch (id) {
      case 'os':
        currentProfile = this.state.currentProfile;
        currentProfile['headers.os.name'] = value;

        this.setState(
          () => ({
            chos: value,
            chunmaskedRendererWebgl: '',
            chbrowser: '',
            browsers: [],
            unmaskedRendererWebgls: [],
            chwidthXheight: '',
            widthXheight: [],
            currentProfile: currentProfile
          }),
          () => {
            this.propsFilter(id, value);
            this.checkCurrentProfile();
          }
        );

        break;
      case 'browser':
        currentProfile = this.state.currentProfile;
        currentProfile['headers.browser.name'] = value;
        this.setState(
          () => ({
            chbrowser: value,
            chunmaskedRendererWebgl: '',
            unmaskedRendererWebgls: [],
            chwidthXheight: '',
            widthXheight: [],
            currentProfile: currentProfile
          }),
          () => {
            this.propsFilter(id, value);
            this.checkCurrentProfile();
          }
        );
        break;
      case 'ScreenResolution':
        currentProfile = this.state.currentProfile;
        currentProfile['screen.width'] = Number(value.split('x')[0]);
        currentProfile['screen.height'] = Number(value.split('x')[1]);

        this.setState(
          () => ({
            chunmaskedRendererWebgl: '',
            unmaskedRendererWebgls: [],
            chwidthXheight: value,
            currentProfile: currentProfile
          }),
          () => {
            this.propsFilter(id, value);
            if (random) {
              // TODO:  Здесь мы получается грузим для Random Profile
              const widthXheight = this.state.chwidthXheight.split('x');
              const obj = {
                height: +widthXheight[1],
                width: +widthXheight[0]
              };
              const profile = this.getCurrentManualJSONProfileID(obj, 'random');
              const randomItem = profile[Math.floor(Math.random() * profile.length)];
              this.setState({
                chPreProfileID: randomItem._id,
                currentProfileReady: true,
                serverBaseError: false,
                profileReady: false
              });
            } else {
              this.checkCurrentProfile();
            }
          }
        );

        break;
      case 'unmaskedRendererWebgl':
        currentProfile = this.state.currentProfile;
        currentProfile['webglparams.webgl2.EXTENSIONS.WEBGL_debug_renderer_info.UNMASKED_RENDERER_WEBGL'] = value;

        this.setState(
          () => ({
            chunmaskedRendererWebgl: value,
            currentProfile: currentProfile
          }),
          () => {
            this.propsFilter(id, value);
            if (realResolution) {
              const obj = {
                browser: this.state.chbrowser,
                os: this.state.chos,
                UNMASKED_RENDERER_WEBGL: this.state.chunmaskedRendererWebgl
              };
              const profile = this.getCurrentManualJSONProfileID(obj, 'realScreen');
              const randomItem = profile[Math.floor(Math.random() * profile.length)];
              this.setState({
                chPreProfileID: randomItem._id,
                currentProfileReady: true,
                serverBaseError: false,
                profileReady: false
              });
            } else {
              this.checkCurrentProfile();
            }
          }
        );

        break;
      case 'users-profiles':
        this.setState(
          () => ({ userProfileName: value }),
          () => {
            let ready = value !== '';
            this.setState({ userProfileReady: ready });
          }
        );
        break;
      default:
    }
  };

  propsFilter = (type, value) => {
    let profilesProps = [];
    // !!item.headers.browser.name - проверяет не равна ли строка, null, undefiend, 0 и тп через двойное отрицание.
    // !profilesProps.includes(item.headers.browser.name) - проверяет если в profilesProp нет значения item.headers.browser.name.
    // !!item.headers.os.name - проверяет не равна ли строка, null, undefiend, 0 и тп через двойное отрицание.
    // item.headers.os.name === value - проверяет равна ли строка аргументу value.
    // profilesProps.push(item.headers.browser.name) - добавляет элемент в конец массива и возвращает новую длину массива.
    if (type === 'os') {
      this.state.baseProfileInfo.forEach((item) => {
        if (!!item.headers.browser.name && !profilesProps.includes(item.headers.browser.name) && !!item.headers.os.name && item.headers.os.name === value) {
          profilesProps.push(item.headers.browser.name);
        }
      });
      this.setState({ browsers: profilesProps });
    } else if (type === 'browser') {
      const profileUnmaskedRendererWebgls = [];
      this.state.baseProfileInfo.forEach((item) => {
        if (
          !!item.screen.width &&
          !!item.screen.height &&
          !profilesProps.includes(item.screen.width + 'x' + item.screen.height) &&
          !!item.headers.browser.name &&
          item.headers.browser.name === value &&
          item.headers.os.name === this.state.chos
        ) {
          profilesProps.push(item.screen.width + 'x' + item.screen.height);
          profileUnmaskedRendererWebgls.push(item.webglparams.webgl2.EXTENSIONS.WEBGL_debug_renderer_info.UNMASKED_RENDERER_WEBGL);
        }
      });
      this.setState({ widthXheight: profilesProps.sort(), unmaskedRendererWebgls: profileUnmaskedRendererWebgls });
    } else if (type === 'ScreenResolution') {
      this.state.baseProfileInfo.forEach((item) => {
        if (
          !!item.webglparams.webgl2.EXTENSIONS.WEBGL_debug_renderer_info.UNMASKED_RENDERER_WEBGL &&
          !profilesProps.includes(item.webglparams.webgl2.EXTENSIONS.WEBGL_debug_renderer_info.UNMASKED_RENDERER_WEBGL) &&
          !!item.screen.width &&
          !!item.screen.height &&
          item.screen.width + 'x' + item.screen.height === value &&
          item.headers.os.name === this.state.chos &&
          item.headers.browser.name === this.state.chbrowser
        ) {
          profilesProps.push(item.webglparams.webgl2.EXTENSIONS.WEBGL_debug_renderer_info.UNMASKED_RENDERER_WEBGL);
        }
      });
      this.setState({ unmaskedRendererWebgls: profilesProps });
    }
  };

  // TODO: Отсюда мы делаем запрос на получание профиля из JSON

  getCurrentManualJSONProfileID(PROFILE_DATA, type) {
    const str = JSON.stringify(preProfiles);
    const data = JSON.parse(str);
    if (type === 'default') {
      return data.find((el) => {
        return (
          el.headers.browser.name === PROFILE_DATA.browser &&
          el.headers.os.name === PROFILE_DATA.os &&
          el.screen.height === PROFILE_DATA.height &&
          el.screen.width === PROFILE_DATA.width &&
          el.webglparams.webgl2.EXTENSIONS.WEBGL_debug_renderer_info.UNMASKED_RENDERER_WEBGL === PROFILE_DATA.UNMASKED_RENDERER_WEBGL
        );
      });
    } else if (type === 'random') {
      return data.filter((el) => {
        return el.screen.height === PROFILE_DATA.height && el.screen.width === PROFILE_DATA.width;
      });
    } else if (type === 'realScreen') {
      const payload = data.filter((el) => {
        return (
          el.headers.browser.name === PROFILE_DATA.browser &&
          el.headers.os.name === PROFILE_DATA.os &&
          el.webglparams.webgl2.EXTENSIONS.WEBGL_debug_renderer_info.UNMASKED_RENDERER_WEBGL === PROFILE_DATA.UNMASKED_RENDERER_WEBGL
        );
      });
      return payload;
    }
  }

  checkCurrentProfile = () => {
    if (this.state.chos === '' || this.state.chbrowser === '' || this.state.chunmaskedRendererWebgl === '' || this.state.chwidthXheight === '') {
      this.setState({ currentProfileReady: false });
    } else {
      const widthXheight = this.state.chwidthXheight.split('x');
      const obj = {
        browser: this.state.chbrowser,
        os: this.state.chos,
        height: +widthXheight[1],
        width: +widthXheight[0],
        UNMASKED_RENDERER_WEBGL: this.state.chunmaskedRendererWebgl
      };
      const profile = this.getCurrentManualJSONProfileID(obj, 'default');

      this.setState({
        chPreProfileID: profile._id,
        currentProfileReady: true,
        serverBaseError: false,
        profileReady: false
      });
    }
  };

  changeProxyInputs = (e) => {
    let id = e.currentTarget.id;
    let value = e.currentTarget.value;

    switch (id) {
      case 'proxy-protocol':
        if (this.state.initProfile.proxyProtocol === value) {
          this.setState({ profileSaved: true });
        } else {
          this.setState({ profileSaved: false });
          this.state.checkercode = '';
        }
        let validProtocol = this.validateProxy(e);
        this.setState(
          () => ({
            proxyProtocol: value,
            proxyProtocolValid: validProtocol
          }),
          () => {
            this.proxyCheckReady();
          }
        );
        break;
      case 'proxy-ip':
        if (this.state.initProfile.proxyIp === value) {
          this.setState({ profileSaved: true });
        } else {
          this.setState({ profileSaved: false });
          this.state.checkercode = '';
        }

        let validIp = this.validateProxy(e) && regExpProxyAddress.test(e.currentTarget.value);

        this.setState(
          () => ({ proxyIp: value, proxyIpValid: validIp }),
          () => {
            this.proxyCheckReady();
          }
        );
        break;
      case 'proxy-port':
        if (this.state.initProfile.proxyPort === value) {
          this.setState({ profileSaved: true });
        } else {
          this.setState({ profileSaved: false });
          this.state.checkercode = '';
        }
        let validPort = this.validateProxy(e) && regExpPort.test(e.currentTarget.value);
        this.setState(
          () => ({ proxyPort: value, proxyPortValid: validPort }),
          () => {
            this.proxyCheckReady();
          }
        );
        break;
      case 'proxy-user':
        if (this.state.initProfile.proxyUser === value) {
          this.setState({ profileSaved: true });
        } else {
          this.setState({ profileSaved: false });
          this.state.checkercode = '';
        }
        this.setState({ proxyUser: value });
        break;
      case 'proxy-password':
        if (this.state.initProfile.proxyPassword === value) {
          this.setState({ profileSaved: true });
        } else {
          this.setState({ profileSaved: false });
          this.state.checkercode = '';
        }
        this.setState({ proxyPassword: value });
        break;
      default:
        break;
    }
  };

  changeCookieInput = (e) => {
    let value = e.currentTarget.value;
    if (this.state.initProfile.cookie === value) {
      this.setState({ profileSaved: true });
    } else {
      this.setState({ profileSaved: false });
    }
    if (this.state.isUsingNetscape) {
      this.setState({ cookie: value }, () => {
        this.netscapeToJson();
      });
    } else {
      this.setState({ cookie: value });
    }
  };

  changeScreenInputs = (e) => {
    let id = e.currentTarget.id;
    let value = e.currentTarget.value;
    switch (id) {
      case 'screen-width':
        if (this.state.initProfile.widthvar === value) {
          this.setState({ profileSaved: true });
        } else {
          this.setState({ profileSaved: false });
        }
        this.setState({ widthvar: value });
        break;
      case 'screen-height':
        if (this.state.initProfile.heightvar === value) {
          this.setState({ profileSaved: true });
        } else {
          this.setState({ profileSaved: false });
        }
        this.setState({ heightvar: value });
        break;
      case 'screen-avail-height':
        if (this.state.initProfile.availHeighthvar === value) {
          this.setState({ profileSaved: true });
        } else {
          this.setState({ profileSaved: false });
        }
        this.setState({ availHeighthvar: value });
        break;
      case 'screen-avail-width':
        if (this.state.initProfile.availWidthvar === value) {
          this.setState({ profileSaved: true });
        } else {
          this.setState({ profileSaved: false });
        }
        this.setState({ availWidthvar: value });
        break;
      case 'screen-color-depth':
        if (this.state.initProfile.colorDepthvar === value) {
          this.setState({ profileSaved: true });
        } else {
          this.setState({ profileSaved: false });
        }
        this.setState({ colorDepthvar: value });
        break;
      case 'screen-pixel-depth':
        if (this.state.initProfile.pixelDepthvar === value) {
          this.setState({ profileSaved: true });
        } else {
          this.setState({ profileSaved: false });
        }
        this.setState({ pixelDepthvar: value });
        break;
      case 'screen-avail-left':
        if (this.state.initProfile.availLeftvar === value) {
          this.setState({ profileSaved: true });
        } else {
          this.setState({ profileSaved: false });
        }
        this.setState({ availLeftvar: value });
        break;
      case 'screen-avail-top':
        if (this.state.initProfile.availTopvar === value) {
          this.setState({ profileSaved: true });
        } else {
          this.setState({ profileSaved: false });
        }
        this.setState({ availTopvar: value });
        break;
      case 'screen-outer-width':
        if (this.state.initProfile.outerWidthvar === value) {
          this.setState({ profileSaved: true });
        } else {
          this.setState({ profileSaved: false });
        }
        this.setState({ outerWidthvar: value });
        break;
      case 'screen-outer-height':
        if (this.state.initProfile.outerHeight === value) {
          this.setState({ profileSaved: true });
        } else {
          this.setState({ profileSaved: false });
        }
        this.setState({ outerHeight: value });
        break;
      default:
        break;
    }
  };

  changeNavigationInputs = (e) => {
    let id = e.currentTarget.id;
    let value = e.currentTarget.value;

    switch (id) {
      case 'navigator-user-agent':
        if (this.state.initProfile.userAgentConst === value) {
          this.setState({ profileSaved: true });
        } else {
          this.setState({ profileSaved: false });
        }
        this.setState({ userAgentConst: value });
        break;
      case 'navigator-app-version':
        if (this.state.initProfile.appVersionConst === value) {
          this.setState({ profileSaved: true });
        } else {
          this.setState({ profileSaved: false });
        }
        this.setState({ appVersionConst: value });
        break;
      case 'navigator-app-name':
        if (this.state.initProfile.appNameConst === value) {
          this.setState({ profileSaved: true });
        } else {
          this.setState({ profileSaved: false });
        }
        this.setState({ appNameConst: value });
        break;
      case 'navigator-app-code-name':
        if (this.state.initProfile.appCodeNameConst === value) {
          this.setState({ profileSaved: true });
        } else {
          this.setState({ profileSaved: false });
        }
        this.setState({ appCodeNameConst: value });
        break;
      case 'navigator-product':
        if (this.state.initProfile.productConst === value) {
          this.setState({ profileSaved: true });
        } else {
          this.setState({ profileSaved: false });
        }
        this.setState({ productConst: value });
        break;
      case 'navigator-product-sub':
        if (this.state.initProfile.productSubConst === value) {
          this.setState({ profileSaved: true });
        } else {
          this.setState({ profileSaved: false });
        }
        this.setState({ productSubConst: value });
        break;
      case 'navigator-vendor':
        if (this.state.initProfile.vendorConst === value) {
          this.setState({ profileSaved: true });
        } else {
          this.setState({ profileSaved: false });
        }
        this.setState({ vendorConst: value });
        break;
      case 'navigator-vendor-sub':
        if (this.state.initProfile.vendorSubConst === value) {
          this.setState({ profileSaved: true });
        } else {
          this.setState({ profileSaved: false });
        }
        this.setState({ vendorSubConst: value });
        break;
      case 'navigator-build-id':
        if (this.state.initProfile.buildIdConst === value) {
          this.setState({ profileSaved: true });
        } else {
          this.setState({ profileSaved: false });
        }
        this.setState({ buildIdConst: value });
        break;
      case 'navigator-platform':
        if (this.state.initProfile.platformConst === value) {
          this.setState({ profileSaved: true });
        } else {
          this.setState({ profileSaved: false });
        }
        this.setState({ platformConst: value });
        break;
      case 'navigator-oscpu':
        if (this.state.initProfile.oscpuConst === value) {
          this.setState({ profileSaved: true });
        } else {
          this.setState({ profileSaved: false });
        }
        this.setState({ oscpuConst: value });
        break;
      case 'navigator-uaFullversion':
        if (this.state.initProfile.uaFullversion === value) {
          this.setState({ profileSaved: true });
        } else {
          this.setState({ profileSaved: false });
        }
        this.setState({ uaFullversion: value });
        break;
      case 'navigator-brands':
        if (this.state.initProfile.brands === value) {
          this.setState({ profileSaved: true });
        } else {
          this.setState({ profileSaved: false });
        }
        this.setState({ brands: value });
        break;
      case 'navigator-platformUaData':
        if (this.state.initProfile.platformUaData === value) {
          this.setState({ profileSaved: true });
        } else {
          this.setState({ profileSaved: false });
        }
        this.setState({ platformUaData: value });
        break;
      case 'navigator-platformVersionUaData':
        if (this.state.initProfile.platformVersionUaData === value) {
          this.setState({ profileSaved: true });
        } else {
          this.setState({ profileSaved: false });
        }
        this.setState({ platformVersionUaData: value });
        break;
      default:
        break;
    }
  };

  changeDeviceInputs = (e) => {
    let id = e.currentTarget.id;
    let value = e.currentTarget.value;

    switch (id) {
      case 'navigator-hardware-concurrency':
        if (this.state.initProfile.hardwareConcurrencyConst === value) {
          this.setState({ profileSaved: true });
        } else {
          this.setState({ profileSaved: false });
        }
        this.setState({ hardwareConcurrencyConst: value });
        break;
      case 'device-memory':
        if (this.state.initProfile.deviceMemoryConst === value) {
          this.setState({ profileSaved: true });
        } else {
          this.setState({ profileSaved: false });
        }
        this.setState({ deviceMemoryConst: value });
        break;
      case 'device-fonts':
        if (this.state.initProfile.fonts === value) {
          this.setState({ profileSaved: true });
        } else {
          this.setState({ profileSaved: false });
        }
        this.setState({ fonts: value });
        break;
      default:
        break;
    }
  };

  changeProfileName = (e) => {
    let value = e.currentTarget.value;
    if (this.state.initProfile.profileName === value) {
      this.setState({ profileSaved: true });
    } else {
      this.setState({ profileSaved: false });
    }
    let validProfileName = this.validateProfileName(e) && regExpProfileName.test(e.currentTarget.value);
    this.setState(
      () => ({ profileName: value, profileNameValid: validProfileName }),
      () => {}
    );
  };

  changeNoiseInputs = (e) => {
    let id = e.currentTarget.id;
    let value = e.currentTarget.value;
    switch (id) {
      case 'hash-audiohash':
        if (this.state.initProfile.audiohash === value) {
          this.setState({ profileSaved: true });
        } else {
          this.setState({ profileSaved: false });
        }
        this.setState({ audiohash: value });
        break;
      case 'hash-rects':
        if (this.state.initProfile.rects === value) {
          this.setState({ profileSaved: true });
        } else {
          this.setState({ profileSaved: false });
        }
        this.setState({ rects: value });
        break;
      case 'hash-webglNoiseR':
        if (this.state.initProfile.webglNoiseR === value) {
          this.setState({ profileSaved: true });
        } else {
          this.setState({ profileSaved: false });
        }
        this.setState({ webglNoiseR: value });
        break;
      case 'hash-webglNoiseG':
        if (this.state.initProfile.webglNoiseG === value) {
          this.setState({ profileSaved: true });
        } else {
          this.setState({ profileSaved: false });
        }
        this.setState({ webglNoiseG: value });
        break;
      case 'hash-webglNoiseB':
        if (this.state.initProfile.webglNoiseB === value) {
          this.setState({ profileSaved: true });
        } else {
          this.setState({ profileSaved: false });
        }
        this.setState({ webglNoiseB: value });
        break;
      case 'hash-webglNoiseA':
        if (this.state.initProfile.webglNoiseA === value) {
          this.setState({ profileSaved: true });
        } else {
          this.setState({ profileSaved: false });
        }
        this.setState({ webglNoiseA: value });
        break;
      case 'hash-webglNoiseString1':
        if (this.state.initProfile.webglNoiseString1 === value) {
          this.setState({ profileSaved: true });
        } else {
          this.setState({ profileSaved: false });
        }
        this.setState({ webglNoiseString1: value });
        break;
      case 'hash-webglNoiseString2':
        if (this.state.initProfile.webglNoiseString2 === value) {
          this.setState({ profileSaved: true });
        } else {
          this.setState({ profileSaved: false });
        }
        this.setState({ webglNoiseString2: value });
        break;
      case 'hash-audioNoiseConst1':
        if (this.state.initProfile.audioNoiseConst1 === value) {
          this.setState({ profileSaved: true });
        } else {
          this.setState({ profileSaved: false });
        }
        this.setState({ audioNoiseConst1: value });
        break;
      case 'hash-audioNoiseConst2':
        if (this.state.initProfile.audioNoiseConst2 === value) {
          this.setState({ profileSaved: true });
        } else {
          this.setState({ profileSaved: false });
        }
        this.setState({ audioNoiseConst2: value });
        break;
      case 'hash-audioNoiseConst3':
        if (this.state.initProfile.audioNoiseConst3 === value) {
          this.setState({ profileSaved: true });
        } else {
          this.setState({ profileSaved: false });
        }
        this.setState({ audioNoiseConst3: value });
        break;
      case 'hash-audioNoiseConst4':
        if (this.state.initProfile.audioNoiseConst4 === value) {
          this.setState({ profileSaved: true });
        } else {
          this.setState({ profileSaved: false });
        }
        this.setState({ audioNoiseConst4: value });
        break;
      case 'hash-seed':
        if (this.state.initProfile.seed === value) {
          this.setState({ profileSaved: true });
        } else {
          this.setState({ profileSaved: false });
        }
        this.setState({ seed: value });
        break;
      case 'webrtc-local-ip':
        if (this.state.initProfile.WebRTClocalIP === value) {
          this.setState({ profileSaved: true });
        } else {
          this.setState({ profileSaved: false });
        }
        this.setState({ WebRTClocalIP: value });
        break;
      case 'webrtc-public-ip':
        if (this.state.initProfile.WebRTCIP === value) {
          this.setState({ profileSaved: true });
        } else {
          this.setState({ profileSaved: false });
        }
        this.setState({ WebRTCIP: value });
        break;
      default:
        break;
    }
  };

  changeWebGLInputs = (e) => {
    let id = e.currentTarget.id;
    let value = e.currentTarget.value;
    switch (id) {
      case 'webgl-support':
        if (this.state.initProfile.webglFloatPrecision === value) {
          this.setState({ profileSaved: true });
        } else {
          this.setState({ profileSaved: false });
        }
        this.setState({ webglFloatPrecision: value });
        break;
      case 'webgl-unmasked-vendor':
        if (this.state.initProfile.unmaskedVendorWebgl === value) {
          this.setState({ profileSaved: true });
        } else {
          this.setState({ profileSaved: false });
        }
        this.setState({ unmaskedVendorWebgl: value });
        break;
      case 'webgl-unmasked-renderer':
        if (this.state.initProfile.unmaskedRendererWebgl === value) {
          this.setState({ profileSaved: true });
        } else {
          this.setState({ profileSaved: false });
        }
        this.setState({ unmaskedRendererWebgl: value });
        break;
      case 'webgl-renderer-info':
        if (this.state.initProfile.webglRenderer === value) {
          this.setState({ profileSaved: true });
        } else {
          this.setState({ profileSaved: false });
        }
        this.setState({ webglRenderer: value });
        break;
      case 'webgl-vendor-info':
        if (this.state.initProfile.webglVendor === value) {
          this.setState({ profileSaved: true });
        } else {
          this.setState({ profileSaved: false });
        }
        this.setState({ webglVendor: value });
        break;
      case 'webgl-shading-language-version':
        if (this.state.initProfile.shadinglanguageversion === value) {
          this.setState({ profileSaved: true });
        } else {
          this.setState({ profileSaved: false });
        }
        this.setState({ shadinglanguageversion: value });
        break;
      case 'webgl-gl-version':
        if (this.state.initProfile.glVersion === value) {
          this.setState({ profileSaved: true });
        } else {
          this.setState({ profileSaved: false });
        }
        this.setState({ glVersion: value });
        break;
      case 'webgl-aliased-line-width-range':
        if (this.state.initProfile.aliasedLineWidthRange === value) {
          this.setState({ profileSaved: true });
        } else {
          this.setState({ profileSaved: false });
        }
        this.setState({ aliasedLineWidthRange: value });
        break;
      case 'webgl-aliased-point-size-range':
        if (this.state.initProfile.aliasedPointSizeRange === value) {
          this.setState({ profileSaved: true });
        } else {
          this.setState({ profileSaved: false });
        }
        this.setState({ aliasedPointSizeRange: value });
        break;
      case 'webgl-alpha-bits':
        if (this.state.initProfile.alphaBits === value) {
          this.setState({ profileSaved: true });
        } else {
          this.setState({ profileSaved: false });
        }
        this.setState({ alphaBits: value });
        break;
      case 'webgl-blue-bits':
        if (this.state.initProfile.blueBits === value) {
          this.setState({ profileSaved: true });
        } else {
          this.setState({ profileSaved: false });
        }
        this.setState({ blueBits: value });
        break;
      case 'webgl-max-draw-buffers':
        if (this.state.initProfile.maxDrawBuffers === value) {
          this.setState({ profileSaved: true });
        } else {
          this.setState({ profileSaved: false });
        }
        this.setState({ maxDrawBuffers: value });
        break;
      case 'webgl-max-anisotropy':
        if (this.state.initProfile.maxTextureMaxAnisotropyExt === value) {
          this.setState({ profileSaved: true });
        } else {
          this.setState({ profileSaved: false });
        }
        this.setState({ maxTextureMaxAnisotropyExt: value });
        break;
      case 'webgl-max-vertex-attributes':
        if (this.state.initProfile.maxVertexattribs === value) {
          this.setState({ profileSaved: true });
        } else {
          this.setState({ profileSaved: false });
        }
        this.setState({ maxVertexattribs: value });
        break;
      case 'webgl-max-combined-texture-image-units':
        if (this.state.initProfile.maxCombinedTextureImageUnits === value) {
          this.setState({ profileSaved: true });
        } else {
          this.setState({ profileSaved: false });
        }
        this.setState({ maxCombinedTextureImageUnits: value });
        break;
      case 'webgl-max-cube-map-texture-size':
        if (this.state.initProfile.maxCubeMapTextureSize === value) {
          this.setState({ profileSaved: true });
        } else {
          this.setState({ profileSaved: false });
        }
        this.setState({ maxCubeMapTextureSize: value });
        break;
      case 'webgl-max-render-buffer-size':
        if (this.state.initProfile.maxRenderBufferSize === value) {
          this.setState({ profileSaved: true });
        } else {
          this.setState({ profileSaved: false });
        }
        this.setState({ maxRenderBufferSize: value });
        break;
      case 'webgl-max-texture-size':
        if (this.state.initProfile.maxTextureSize === value) {
          this.setState({ profileSaved: true });
        } else {
          this.setState({ profileSaved: false });
        }
        this.setState({ maxTextureSize: value });
        break;
      case 'webgl-max-fragment-uniform-vectors':
        if (this.state.initProfile.maxFragmentUniformVectors === value) {
          this.setState({ profileSaved: true });
        } else {
          this.setState({ profileSaved: false });
        }
        this.setState({ maxFragmentUniformVectors: value });
        break;
      case 'webgl-max-vertex-uniform-vectors':
        if (this.state.initProfile.maxVertexuniformvectors === value) {
          this.setState({ profileSaved: true });
        } else {
          this.setState({ profileSaved: false });
        }
        this.setState({ maxVertexuniformvectors: value });
        break;
      case 'webgl-max-texture-image-units':
        if (this.state.initProfile.maxTextureImageUnits === value) {
          this.setState({ profileSaved: true });
        } else {
          this.setState({ profileSaved: false });
        }
        this.setState({ maxTextureImageUnits: value });
        break;
      case 'webgl-max-varying-vectors':
        if (this.state.initProfile.maxVaryingVectors === value) {
          this.setState({ profileSaved: true });
        } else {
          this.setState({ profileSaved: false });
        }
        this.setState({ maxVaryingVectors: value });
        break;
      case 'webgl-max-vertex-texture-image-units':
        if (this.state.initProfile.maxVertexTextureImageUnits === value) {
          this.setState({ profileSaved: true });
        } else {
          this.setState({ profileSaved: false });
        }
        this.setState({ maxVertexTextureImageUnits: value });
        break;
      case 'webgl-max-vertex-uniform-components':
        if (this.state.initProfile.maxVertexUniformComponents === value) {
          this.setState({ profileSaved: true });
        } else {
          this.setState({ profileSaved: false });
        }
        this.setState({ maxVertexUniformComponents: value });
        break;
      case 'webgl-max-vertex-uniform-blocks':
        if (this.state.initProfile.maxVertexUniformBlocks === value) {
          this.setState({ profileSaved: true });
        } else {
          this.setState({ profileSaved: false });
        }
        this.setState({ maxVertexUniformBlocks: value });
        break;
      case 'webgl-max-vertex-output-components':
        if (this.state.initProfile.maxVertexOutputComponents === value) {
          this.setState({ profileSaved: true });
        } else {
          this.setState({ profileSaved: false });
        }
        this.setState({ maxVertexOutputComponents: value });
        break;
      case 'webgl-max-varying-components':
        if (this.state.initProfile.maxVaryingComponents === value) {
          this.setState({ profileSaved: true });
        } else {
          this.setState({ profileSaved: false });
        }
        this.setState({ maxVaryingComponents: value });
        break;
      case 'webgl-max-interleaved-components':
        if (this.state.initProfile.maxTransformFeedbackInterleavedComponents === value) {
          this.setState({ profileSaved: true });
        } else {
          this.setState({ profileSaved: false });
        }
        this.setState({
          maxTransformFeedbackInterleavedComponents: value
        });
        break;
      case 'webgl-max-separate-attribs':
        if (this.state.initProfile.maxTransformFeedbackSeparateAttribs === value) {
          this.setState({ profileSaved: true });
        } else {
          this.setState({ profileSaved: false });
        }
        this.setState({ maxTransformFeedbackSeparateAttribs: value });
        break;
      case 'webgl-max-separate-components':
        if (this.state.initProfile.maxTransformFeedbackSeparateComponents === value) {
          this.setState({ profileSaved: true });
        } else {
          this.setState({ profileSaved: false });
        }
        this.setState({
          maxTransformFeedbackSeparateComponents: value
        });
        break;
      case 'webgl-max-fragment-uniform-components':
        if (this.state.initProfile.maxFragmentUniformComponents === value) {
          this.setState({ profileSaved: true });
        } else {
          this.setState({ profileSaved: false });
        }
        this.setState({ maxFragmentUniformComponents: value });
        break;
      case 'webgl-max-fragment-uniform-blocks':
        if (this.state.initProfile.maxFragmentUniformBlocks === value) {
          this.setState({ profileSaved: true });
        } else {
          this.setState({ profileSaved: false });
        }
        this.setState({ maxFragmentUniformBlocks: value });
        break;
      case 'webgl-max-fragment-input-components':
        if (this.state.initProfile.maxFragmentInputComponents === value) {
          this.setState({ profileSaved: true });
        } else {
          this.setState({ profileSaved: false });
        }
        this.setState({ maxFragmentInputComponents: value });
        break;
      case 'webgl-max-combined-uniform-blocks':
        if (this.state.initProfile.maxCombinedUniformBlocks === value) {
          this.setState({ profileSaved: true });
        } else {
          this.setState({ profileSaved: false });
        }
        this.setState({ maxCombinedUniformBlocks: value });
        break;
      case 'webgl-combined-vertex-uniform-components':
        if (this.state.initProfile.maxCombinedVertexUniformComponents === value) {
          this.setState({ profileSaved: true });
        } else {
          this.setState({ profileSaved: false });
        }
        this.setState({ maxCombinedVertexUniformComponents: value });
        break;
      case 'webgl-min-program-texel-offset':
        if (this.state.initProfile.minProgramTexelOffset === value) {
          this.setState({ profileSaved: true });
        } else {
          this.setState({ profileSaved: false });
        }
        this.setState({ minProgramTexelOffset: value });
        break;
      case 'webgl-max-program-texel-offset':
        if (this.state.initProfile.maxProgramTexelOffset === value) {
          this.setState({ profileSaved: true });
        } else {
          this.setState({ profileSaved: false });
        }
        this.setState({ maxProgramTexelOffset: value });
        break;
      case 'webgl-max-draw-buffers-2':
        if (this.state.initProfile.maxDrawBuffers === value) {
          this.setState({ profileSaved: true });
        } else {
          this.setState({ profileSaved: false });
        }
        this.setState({ maxDrawBuffers: value });
        break;
      case 'webgl-max-color-attachments':
        if (this.state.initProfile.maxColorAttachments === value) {
          this.setState({ profileSaved: true });
        } else {
          this.setState({ profileSaved: false });
        }
        this.setState({ maxColorAttachments: value });
        break;
      case 'webgl-max-samples':
        if (this.state.initProfile.maxSamples === value) {
          this.setState({ profileSaved: true });
        } else {
          this.setState({ profileSaved: false });
        }
        this.setState({ maxSamples: value });
        break;
      case 'webgl-max-3d-texture-size':
        if (this.state.initProfile.max3dTextureSize === value) {
          this.setState({ profileSaved: true });
        } else {
          this.setState({ profileSaved: false });
        }
        this.setState({ max3dTextureSize: value });
        break;
      case 'webgl-max-array-texture-layers':
        if (this.state.initProfile.maxArrayTextureLayers === value) {
          this.setState({ profileSaved: true });
        } else {
          this.setState({ profileSaved: false });
        }
        this.setState({ maxArrayTextureLayers: value });
        break;
      case 'webgl-max-texture-lod-bias':
        if (this.state.initProfile.maxTextureLodBias === value) {
          this.setState({ profileSaved: true });
        } else {
          this.setState({ profileSaved: false });
        }
        this.setState({ maxTextureLodBias: value });
        break;
      case 'webgl-max-uniform-buffer-bindings':
        if (this.state.initProfile.maxUniformBufferBindings === value) {
          this.setState({ profileSaved: true });
        } else {
          this.setState({ profileSaved: false });
        }
        this.setState({ maxUniformBufferBindings: value });
        break;
      case 'webgl-max-uniform-block-size':
        if (this.state.initProfile.maxUniformBlockSize === value) {
          this.setState({ profileSaved: true });
        } else {
          this.setState({ profileSaved: false });
        }
        this.setState({ maxUniformBlockSize: value });
        break;
      case 'webgl-uniform-buffer-offset-alignment':
        if (this.state.initProfile.uniformBufferOffsetAlignment === value) {
          this.setState({ profileSaved: true });
        } else {
          this.setState({ profileSaved: false });
        }
        this.setState({ uniformBufferOffsetAlignment: value });
        break;
      case 'webgl-max-combined-fragment-uniform-components':
        if (this.state.initProfile.maxCombinedFragmentUniformComponents === value) {
          this.setState({ profileSaved: true });
        } else {
          this.setState({ profileSaved: false });
        }
        this.setState({ maxCombinedFragmentUniformComponents: value });
        break;
      case 'webgl-max-view-port-dims':
        if (this.state.initProfile.maxViewportDims === value) {
          this.setState({ profileSaved: true });
        } else {
          this.setState({ profileSaved: false });
        }
        this.setState({ maxViewportDims: value });
        break;
      default:
        return;
    }
  };

  changeGeoInputs = (e) => {
    let id = e.currentTarget.id;
    let value = e.currentTarget.value;
    switch (id) {
      case 'proxy-accept-language':
        if (this.state.initProfile.accept_language === value) {
          this.setState({ profileSaved: true });
        } else {
          this.setState({ profileSaved: false });
        }
        this.setState({ accept_language: value });
        break;

      case 'proxy-lang-1':
        if (this.state.initProfile.language_Const1 === value) {
          this.setState({ profileSaved: true });
        } else {
          this.setState({ profileSaved: false });
        }
        this.setState({ language_Const1: value });
        break;

      case 'proxy-lang-2':
        if (this.state.initProfile.languageConst1 === value) {
          this.setState({ profileSaved: true });
        } else {
          this.setState({ profileSaved: false });
        }
        this.setState({ languageConst1: value });
        break;
      case 'proxy-time-region':
        if (this.state.initProfile.sysTimeRegion === value) {
          this.setState({ profileSaved: true });
        } else {
          this.setState({ profileSaved: false });
        }
        this.setState({ sysTimeRegion: value });
        break;

      case 'proxy-lat':
        if (this.state.initProfile.gpslat === value) {
          this.setState({ profileSaved: true });
        } else {
          this.setState({ profileSaved: false });
        }
        this.setState({ gpslat: value });
        break;

      case 'proxy-long':
        if (this.state.initProfile.gpslon === value) {
          this.setState({ profileSaved: true });
        } else {
          this.setState({ profileSaved: false });
        }
        this.setState({ gpslon: value });
        break;
      default:
        break;
    }
  };

  checkProxy = () => {
    let newVal = !this.state.chProxy;
    this.setState({ chProxy: newVal });
    this.setState({ profileSaved: false });
  };

  validateProxy = (e) => {
    const form = e.currentTarget;
    let valid = form.checkValidity();
    return valid;
  };

  validateProfileName = (e) => {
    const form = e.currentTarget;
    let valid = form.checkValidity();
    return valid;
  };

  proxyCheckReady = () => {
    if (this.state.proxyPortValid && this.state.proxyIpValid && this.state.proxyProtocolValid) {
      this.setState({ proxyReady: true });
    } else {
      this.setState({ proxyReady: false });
    }
  };

  moreSettingsChecked = () => {
    this.setState({ moreSettings: !this.state.moreSettings });
  };

  audioNoiseSwitchChecked = () => {
    let newVal = !this.state.audioNoiseSwitch;
    this.setState({ audioNoiseSwitch: newVal });

    if (newVal === false) {
      let audioNoise = 0;
      this.setState({ audioNoise: audioNoise });
    } else {
      let audioNoise = 1;
      this.setState({ audioNoise: audioNoise });
    }
    this.setState({ profileSaved: false });
  };

  webglNoiseSwitchChecked = () => {
    let newVal = !this.state.webglNoiseSwitch;
    this.setState({ webglNoiseSwitch: newVal });

    if (newVal === false) {
      let webglNoise = 0;
      this.setState({ webglNoise: webglNoise });
    } else {
      let webglNoise = 1;
      this.setState({ webglNoise: webglNoise });
    }
    this.setState({ profileSaved: false });
  };

  MySwitch = () => {
    let newVal = !this.state.canvasNoiseSwitch;
    this.setState({ canvasNoiseSwitch: newVal });

    if (newVal === false) {
      let canvasNoise = 0;
      this.setState({ canvasNoise: canvasNoise });
    } else {
      let canvasNoise = 1;
      this.setState({ canvasNoise: canvasNoise });
    }
    this.setState({ profileSaved: false });
  };

  fontsSpoofSwitchChecked = () => {
    let newVal = !this.state.fontsSpoofSwitch;
    this.setState({ fontsSpoofSwitch: newVal });

    if (newVal === false) {
      let fontsSpoof = 0;
      this.setState({ fontsSpoof: fontsSpoof });
    } else {
      let fontsSpoof = 1;
      this.setState({ fontsSpoof: fontsSpoof });
    }
    this.setState({ profileSaved: false });
  };

  canvasNoiseSwitchChecked = () => {
    let newVal = !this.state.canvasNoiseSwitch;
    this.setState({ canvasNoiseSwitch: newVal });

    if (newVal === false) {
      let canvasNoise = 0;
      this.setState({ canvasNoise: canvasNoise });
    } else {
      let canvasNoise = 1;
      this.setState({ canvasNoise: canvasNoise });
    }
    this.setState({ profileSaved: false });
  };

  spoofingScreenSwitchChecked = () => {
    let newVal = !this.state.spoofingScreenSwitch;
    this.setState({ spoofingScreenSwitch: newVal, profileSaved: false });
  };

  doNotTrackSwitchChecked = () => {
    let newVal = !this.state.doNotTrackSwitch;
    this.setState({ doNotTrackSwitch: newVal });

    if (newVal === false) {
      let doNotTrack = 0;
      this.setState({ doNotTrack: doNotTrack });
    } else {
      let doNotTrack = 1;
      this.setState({ doNotTrack: doNotTrack });
    }
    this.setState({ profileSaved: false });
  };

  ObserverWebRTCSwitchChecked = () => {
    let newVal = !this.state.ObserverWebRTCSwitch;
    this.setState({ ObserverWebRTCSwitch: newVal });

    if (newVal === false) {
      let ObserverWebRTC = false;
      this.setState({ ObserverWebRTC: ObserverWebRTC });
    } else {
      let ObserverWebRTC = true;
      this.setState({ ObserverWebRTC: ObserverWebRTC });
    }
    this.setState({ profileSaved: false });
  };

  enable911SwitchChecked = () => {
    let newVal = !this.state.enable911Switch;
    this.setState({ enable911Switch: newVal });

    if (newVal === false) {
      let enable911 = 0;
      this.setState({ enable911: enable911 });
      this.setState({ proxyIpValid: false });
      this.setState({ proxyProtocolValid: false });
      this.setState({ proxyReady: false });
    } else {
      let enable911 = 1;
      this.setState({ proxyReady: true });
      this.setState({ proxyIpValid: true });
      this.setState({ proxyProtocolValid: true });
      this.setState({ enable911: enable911 });
    }
    this.setState({ profileSaved: false });
  };

  netscapeToJson = () => {
    let netscape = {};
    netscape.value = '';
    let arrObjects = [];
    let cookieJSON = this.state.cookie;
    let arrayOfLines = cookieJSON.split('\n');
    let i = 0;
    for (i = 0; i < arrayOfLines.length; i++) {
      let kuka = arrayOfLines[i].split('\t');
      let cook = {};
      cook.domain = kuka[0];
      cook.expirationDate = parseInt(kuka[4]);

      if (kuka[1] == 'FALSE') cook.httpOnly = false;
      if (kuka[1] == 'TRUE') cook.httpOnly = true;

      cook.name = kuka[5];
      cook.path = kuka[2];

      if (kuka[3] == 'FALSE') cook.secure = false;
      if (kuka[3] == 'TRUE') cook.secure = true;

      cook.value = kuka[6];

      arrObjects[i] = cook;
    }

    let cookieStr = JSON.stringify(arrObjects);
    this.setState({ convertedCookie: cookieStr });
  };

  setChangeCookieType = (value) => {
    this.setState({ isUsingNetscape: value });
  };

  render() {
    if (this.state.userProfileInfo === 'need_auth') {
      return <Redirect to="/account" />;
    }

    const { t } = this.props;
    return (
      <div>
        <DashboardTabs
          toRandomProfile={this.toRandomProfile}
          chos={this.state.chos}
          baseProfileInfo={this.state.baseProfileInfo}
          currentProfile={this.state.currentProfile}
          changeForm={this.changeForm}
          oses={this.state.oses}
          browsers={this.state.browsers}
          chbrowser={this.state.chbrowser}
          loadingBaseFilter={this.state.loadingBaseFilter}
          chwidthXheight={this.state.chwidthXheight}
          widthXheight={this.state.widthXheight}
          chunmaskedRendererWebgl={this.state.chunmaskedRendererWebgl}
          unmaskedRendererWebgls={this.state.unmaskedRendererWebgls}
          currentProfileReady={this.state.currentProfileReady}
          fromCheckerLoaded={this.state.fromCheckerLoaded}
          becomeProfile={this.becomeProfile}
          serverBaseError={this.state.serverBaseError}
          serverUserError={this.state.serverUserError}
          profileReady={this.state.profileReady}
          loadingBaseProcess={this.state.loadingBaseProcess}
          userProfileName={this.state.userProfileName}
          userProfileInfo={this.state.userProfileInfo}
          userProfileReady={this.state.userProfileReady}
          becomeUserProfile={this.becomeUserProfile}
          deleteProfileFromBase={this.deleteProfileFromBase}
          fromUserLoaded={this.state.fromUserLoaded}
          loadingUserProcess={this.state.loadingUserProcess}
        />
        <ProfileSettings
          createUserProfile={this.createUserProfile}
          moreSettingsChecked={this.moreSettingsChecked}
          moreSettings={this.state.moreSettings}
          profileNameValid={this.state.profileNameValid}
          changeInputs={this.changeProfileName}
          checkProxy={this.checkProxy}
          profileName={this.state.profileName}
          chProxy={this.state.chProxy}
          proxyReady={this.state.proxyReady}
          profileReady={this.state.profileReady}
          profileSaved={this.state.profileSaved}
          fromCheckerLoaded={this.state.fromCheckerLoaded}
          serverUserError={this.state.serverUserError}
          isUserProfileLoading={this.state.isUserProfileLoading}
        />
        <div className={!this.state.chProxy ? 'd-none' : ''}>
          <Proxy
            chProxy={this.state.chProxy}
            proxyProtocolValid={this.state.proxyProtocolValid}
            proxyIpValid={this.state.proxyIpValid}
            proxyPortValid={this.state.proxyPortValid}
            loadingBaseProcess={this.state.loadingBaseProcess}
            loadingUserProcess={this.state.loadingUserProcess}
            enable911Switch={this.state.enable911Switch}
            checkercode={this.state.checkercode}
            fromCheckerLoaded={this.state.fromCheckerLoaded}
            ProxyChecker={this.ProxyChecker}
            proxyIp={this.state.proxyIp}
            changeInputs={this.changeProxyInputs}
            proxyPort={this.state.proxyPort}
            proxyProtocol={this.state.proxyProtocol}
            proxyUser={this.state.proxyUser}
            proxyPassword={this.state.proxyPassword}
            enable911SwitchChecked={this.enable911SwitchChecked}
          />
        </div>

        <div style={{ marginTop: 30 }} className={!this.state.moreSettings ? 'd-none' : ''}>
          <div className="alert alert-dismissible fade show alert-yellow" role="alert">
            <strong>{t('Dashboard.config.Attention.title')}</strong> {t('Dashboard.config.Attention.text')}
          </div>
          <Tabs defaultActiveKey="navigator" id="uncontrolled-tab-example" style={{ marginBottom: 10 }}>
            <Tab
              eventKey="navigator"
              title={
                <span className={s.advansed_mode__tabs}>
                  {t('Dashboard.config.advanced.navigate')}
                  <i className="material-icons options-icon">public</i>
                </span>
              }
              style={{ marginBottom: 40 }}
            >
              <NavigationWebKit
                changeInputs={this.changeNavigationInputs}
                userAgentConst={this.state.userAgentConst}
                vendorSubConst={this.state.vendorSubConst}
                appVersionConst={this.state.appVersionConst}
                appNameConst={this.state.appNameConst}
                appCodeNameConst={this.state.appCodeNameConst}
                productConst={this.state.productConst}
                productSubConst={this.state.productSubConst}
                vendorConst={this.state.vendorConst}
                buildIDConst={this.state.buildIDConst}
                platformConst={this.state.platformConst}
                oscpuConst={this.state.oscpuConst}
                //??
                uaFullversion={this.state.uaFullversion}
                brands={this.state.brands}
                platformUaData={this.state.platformUaData}
                platformVersionUaData={this.state.platformVersionUaData}
              />
            </Tab>
            <Tab
              eventKey="device"
              title={
                <span className={s.advansed_mode__tabs}>
                  {t('Dashboard.config.advanced.dh')}
                  <i className="material-icons options-icon">memory</i>
                </span>
              }
              style={{ marginBottom: 40 }}
            >
              <DeviceHardware
                changeInputs={this.changeDeviceInputs}
                deviceMemoryConst={this.state.deviceMemoryConst}
                hardwareConcurrencyConst={this.state.hardwareConcurrencyConst}
                fonts={this.state.fonts}
              />
            </Tab>
            <Tab
              eventKey="screen"
              title={
                <span className={s.advansed_mode__tabs}>
                  {t('Dashboard.config.advanced.screen')}
                  <i className="material-icons options-icon">desktop_mac</i>
                </span>
              }
              style={{ marginBottom: 40 }}
            >
              <Scren
                changeInputs={this.changeScreenInputs}
                widthvar={this.state.widthvar}
                heightvar={this.state.heightvar}
                availHeighthvar={this.state.availHeighthvar}
                availWidthvar={this.state.availWidthvar}
                colorDepthvar={this.state.colorDepthvar}
                pixelDepthvar={this.state.pixelDepthvar}
                availLeftvar={this.state.availLeftvar}
                availTopvar={this.state.availTopvar}
                spoofingScreenSwitchChecked={this.spoofingScreenSwitchChecked}
                spoofingScreenSwitch={this.state.spoofingScreenSwitch}
              />
            </Tab>
            <Tab
              eventKey="webgl"
              title={
                <span className={s.advansed_mode__tabs}>
                  {t('Dashboard.config.advanced.webgl')}
                  <i className="material-icons options-icon">developer_board</i>
                </span>
              }
              style={{ marginBottom: 40 }}
            >
              <WebGLCanvas
                changeInputs={this.changeWebGLInputs}
                aliasedLineWidthRange={this.state.aliasedLineWidthRange}
                alphaBits={this.state.alphaBits}
                blueBits={this.state.blueBits}
                glVersion={this.state.glVersion}
                max3dTextureSize={this.state.max3dTextureSize}
                maxArrayTextureLayers={this.state.maxArrayTextureLayers}
                maxColorAttachments={this.state.maxColorAttachments}
                maxCombinedFragmentUniformComponents={this.state.maxCombinedFragmentUniformComponents}
                maxCombinedTextureImageUnits={this.state.maxCombinedTextureImageUnits}
                maxCombinedUniformBlocks={this.state.maxCombinedUniformBlocks}
                maxCombinedVertexUniformComponents={this.state.maxCombinedVertexUniformComponents}
                maxCubeMapTextureSize={this.state.maxCubeMapTextureSize}
                maxDrawBuffers={this.state.maxDrawBuffers}
                maxFragmentInputComponents={this.state.maxFragmentInputComponents}
                maxFragmentUniformBlocks={this.state.maxFragmentUniformBlocks}
                maxFragmentUniformComponents={this.state.maxFragmentUniformComponents}
                maxFragmentUniformVectors={this.state.maxFragmentUniformVectors}
                maxProgramTexelOffset={this.state.maxProgramTexelOffset}
                webglVendor={this.state.webglVendor}
                webglFloatPrecision={this.state.webglFloatPrecision}
                maxRenderBufferSize={this.state.maxRenderBufferSize}
                maxSamples={this.state.maxSamples}
                maxTextureImageUnits={this.state.maxTextureImageUnits}
                maxTextureLodBias={this.state.maxTextureLodBias}
                maxTextureMaxAnisotropyExt={this.state.maxTextureMaxAnisotropyExt}
                maxTextureSize={this.state.maxTextureSize}
                maxTransformFeedbackInterleavedComponents={this.state.maxTransformFeedbackInterleavedComponents}
                maxTransformFeedbackSeparateAttribs={this.state.maxTransformFeedbackSeparateAttribs}
                webglRenderer={this.state.webglRenderer}
                maxTransformFeedbackSeparateComponents={this.state.maxTransformFeedbackSeparateComponents}
                maxUniformBlockSize={this.state.maxUniformBlockSize}
                maxUniformBufferBindings={this.state.maxUniformBufferBindings}
                maxVaryingComponents={this.state.maxVaryingComponents}
                maxVaryingVectors={this.state.maxVaryingVectors}
                maxVertexattribs={this.state.maxVertexattribs}
                maxVertexOutputComponents={this.state.maxVertexOutputComponents}
                maxVertexTextureImageUnits={this.state.maxVertexTextureImageUnits}
                unmaskedVendorWebgl={this.state.unmaskedVendorWebgl}
                minProgramTexelOffset={this.state.minProgramTexelOffset}
                maxViewportDims={this.state.maxViewportDims}
                maxVertexUniformBlocks={this.state.maxVertexUniformBlocks}
                maxVertexUniformComponents={this.state.maxVertexUniformComponents}
                maxVertexuniformvectors={this.state.maxVertexuniformvectors}
                shadinglanguageversion={this.state.shadinglanguageversion}
                uniformBufferOffsetAlignment={this.state.uniformBufferOffsetAlignment}
                unmaskedRendererWebgl={this.state.unmaskedRendererWebgl}
                aliasedPointSizeRange={this.state.aliasedPointSizeRange}
              />
            </Tab>
            <Tab
              eventKey="noise"
              title={
                <span className={s.advansed_mode__tabs}>
                  {t('Dashboard.config.advanced.noise')}
                  <i className="material-icons options-icon">network_check</i>
                </span>
              }
              style={{ marginBottom: 40 }}
            >
              <Noise
                changeInputs={this.changeNoiseInputs}
                fontsSpoofSwitchChecked={this.fontsSpoofSwitchChecked}
                fontsSpoofSwitch={this.state.fontsSpoofSwitch}
                canvasNoiseSwitchChecked={this.canvasNoiseSwitchChecked}
                canvasNoiseSwitch={this.state.canvasNoiseSwitch}
                webglNoiseSwitchChecked={this.webglNoiseSwitchChecked}
                webglNoiseSwitch={this.state.webglNoiseSwitch}
                audioNoiseSwitchChecked={this.audioNoiseSwitchChecked}
                audioNoiseSwitch={this.state.audioNoiseSwitch}
                doNotTrackSwitchChecked={this.doNotTrackSwitchChecked}
                doNotTrackSwitch={this.state.doNotTrackSwitch}
                audiohash={this.state.audiohash}
                rects={this.state.rects}
                webglNoiseR={this.state.webglNoiseR}
                webglNoiseG={this.state.webglNoiseG}
                webglNoiseB={this.state.webglNoiseB}
                webglNoiseA={this.state.webglNoiseA}
                webglNoiseString1={this.state.webglNoiseString1}
                webglNoiseString2={this.state.webglNoiseString2}
                audioNoiseConst1={this.state.audioNoiseConst1}
                audioNoiseConst2={this.state.audioNoiseConst2}
                audioNoiseConst3={this.state.audioNoiseConst3}
                audioNoiseConst4={this.state.audioNoiseConst4}
                ObserverWebRTCSwitch={this.state.ObserverWebRTCSwitch}
                ObserverWebRTCSwitchChecked={this.ObserverWebRTCSwitchChecked}
                seed={this.state.seed}
                WebRTCIP={this.state.WebRTCIP}
                WebRTClocalIP={this.state.WebRTClocalIP}
              />
            </Tab>
            <Tab
              eventKey="geosettings"
              title={
                <span className={s.advansed_mode__tabs}>
                  {t('Dashboard.config.advanced.geo')}
                  <i className="material-icons options-icon">my_location</i>
                </span>
              }
              style={{ marginBottom: 40 }}
            >
              <GEOSettings
                changeInputs={this.changeGeoInputs}
                initProfile={this.state.initProfile}
                gpslon={this.state.gpslon}
                gpslat={this.state.gpslat}
                sysTimeRegion={this.state.sysTimeRegion}
                languageConst1={this.state.languageConst1}
                language_Const1={this.state.language_Const1}
                accept_language={this.state.accept_language}
              />
            </Tab>
            <Tab
              eventKey="cookie"
              title={
                <span className={s.advansed_mode__tabs}>
                  {t('Dashboard.config.advanced.cookie')} <i className="material-icons options-icon">av_timer</i>
                </span>
              }
              style={{ marginBottom: 40 }}
            >
              <Cookie cookie={this.state.cookie} changeInputs={this.changeCookieInput} isUsingNetscape={this.state.isUsingNetscape} setChangeCookieType={this.setChangeCookieType} />
            </Tab>
            <Tab
              eventKey="comment"
              title={
                <span className={s.advansed_mode__tabs}>
                  {t('Dashboard.config.advanced.note')}
                  <i className="material-icons options-icon">comment</i>
                </span>
              }
              style={{ marginBottom: 40 }}
            >
              <Comment note={this.state.note} handleSetNote={this.handleSetNote} />
            </Tab>
          </Tabs>
        </div>
      </div>
    );
  }
}
export default withTranslation()(Dashboard);
