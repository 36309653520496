import React from 'react';
import preloader from '../../images/aezakmi.svg';

import s from './Preloader.module.css';

export let Prealoader = (props) => (
  <div className={s.preloader_container}>
    <img src={preloader} alt="Preloader" />
  </div>
);
