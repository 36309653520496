// Core
import React from 'react';

// Sryles
import { Form, Jumbotron, Button } from 'react-bootstrap';
import { Container, Input } from '@material-ui/core';

// Components
import Manifest from './PaySistemUtils/Manifest';

// Other
import md5 from 'js-md5';

import { useTranslation } from 'react-i18next';

const Payssion = (props) => {
  const { checkedPaySys, invoiceID, selectedGood, mail, isInvoiceIdLoading } = props;
  const payssionMb5Str = md5(`live_9adc0f1288fa4c9d|alipay_cn|${selectedGood.price}|USD|${invoiceID}|jd7G1SQjFVnyK9tZSIuIUJNyfq2wTh3d`);
  const payssionMb5StrForBTC = md5(`live_9adc0f1288fa4c9d|bitcoin|${selectedGood.price}|USD|${invoiceID}|jd7G1SQjFVnyK9tZSIuIUJNyfq2wTh3d`);

  const { t } = useTranslation();

  const alipay = checkedPaySys === 'alipay' && (
    <Form style={{ textAlign: 'left' }} hidden={checkedPaySys !== 'alipay'} name="payssion_payment" action="https://www.payssion.com/payment/create.html" method="post">
      <fieldset disabled={isInvoiceIdLoading}>
        <Input type="hidden" name="api_key" value="live_9adc0f1288fa4c9d" />
        <Input type="hidden" name="api_sig" value={payssionMb5Str} />
        <Input type="hidden" name="pm_id" value="alipay_cn" />
        <Input type="hidden" name="order_id" value={invoiceID} />
        <Input type="hidden" name="description" value="Aezakmi browser" />
        <Input type="hidden" name="amount" value={selectedGood.price} />
        <Input type="hidden" name="payer_email" value={mail} />
        <Input type="hidden" name="currency" value="USD" />
        <Input type="hidden" name="return_url" value="https://aezakmi.run/successpayment" />
        <Button className="pay_button" variant="success" type="submit" value="Pay">
          {t('Checkout.pay')}
        </Button>
      </fieldset>
    </Form>
  );
  const bitcoin = checkedPaySys === 'bitcoin' && (
    <Form disabled={isInvoiceIdLoading} style={{ textAlign: 'left' }} hidden={checkedPaySys !== 'bitcoin'} name="payssion_payment" action="https://www.payssion.com/payment/create.html" method="post">
      <fieldset disabled={isInvoiceIdLoading}>
        <Input type="hidden" name="api_key" value="live_9adc0f1288fa4c9d" />
        <Input type="hidden" name="api_sig" value={payssionMb5StrForBTC} />
        <Input type="hidden" name="pm_id" value="bitcoin" />
        <Input type="hidden" name="order_id" value={invoiceID} />
        <Input type="hidden" name="description" value="Aezakmi browser" />
        <Input type="hidden" name="amount" value={selectedGood.price} />
        <Input type="hidden" name="payer_email" value={mail} />
        <Input type="hidden" name="currency" value="USD" />
        <Input type="hidden" name="return_url" value="https://aezakmi.run/successpayment" />
        <Button className="pay_button" variant="success" type="submit" value="Pay">
          {t('Checkout.pay')}
        </Button>
      </fieldset>
    </Form>
  );
  return (
    <Jumbotron fluid>
      <Container>
        <Manifest checkedPaySys={checkedPaySys} invoiceID={invoiceID} isInvoiceIdLoading={isInvoiceIdLoading} />
        {alipay}
        {bitcoin}
      </Container>
    </Jumbotron>
  );
};

export default Payssion;
