import { Tab, Tabs } from 'react-bootstrap';
import React from 'react';
import YourProfileTabs from './YourProfilesTab';
import CreateNewProfileTab from './CreateNewProfile-tab';

import { useTranslation } from 'react-i18next';

const DashboardTabs = ({
  chos,
  changeForm,
  oses,
  browsers,
  chbrowser,
  loadingBaseFilter,
  chwidthXheight,
  widthXheight,
  chunmaskedRendererWebgl,
  unmaskedRendererWebgls,
  currentProfileReady,
  fromCheckerLoaded,
  becomeProfile,
  serverUserError,
  profileReady,
  loadingBaseProcess,
  userProfileName,
  userProfileInfo,
  serverBaseError,
  userProfileReady,
  becomeUserProfile,
  deleteProfileFromBase,
  fromUserLoaded,
  loadingUserProcess,
  currentProfile,
  baseProfileInfo,
  toRandomProfile
}) => {
  const { t } = useTranslation();
  return (
    <Tabs defaultActiveKey="newProfile" id="uncontrolled-tab-example" style={{ marginBottom: 10 }}>
      <Tab eventKey="newProfile" title={<span>{t('Dashboard.tabs.new')} </span>} style={{ marginBottom: 40 }}>
        <CreateNewProfileTab
          toRandomProfile={toRandomProfile}
          oses={oses}
          browsers={browsers}
          chbrowser={chbrowser}
          chos={chos}
          changeForm={changeForm}
          loadingBaseFilter={loadingBaseFilter}
          chwidthXheight={chwidthXheight}
          widthXheight={widthXheight}
          chunmaskedRendererWebgl={chunmaskedRendererWebgl}
          unmaskedRendererWebgls={unmaskedRendererWebgls}
          currentProfileReady={currentProfileReady}
          fromCheckerLoaded={fromCheckerLoaded}
          becomeProfile={becomeProfile}
          serverBaseError={serverBaseError}
          profileReady={profileReady}
          currentProfile={currentProfile}
          loadingBaseProcess={loadingBaseProcess}
          baseProfileInfo={baseProfileInfo}
        />
      </Tab>
      <Tab eventKey="yourProfile" title={<span>{t('Dashboard.tabs.old')}</span>} style={{ marginBottom: 40 }}>
        <YourProfileTabs
          userProfileName={userProfileName}
          changeForm={changeForm}
          userProfileInfo={userProfileInfo}
          userProfileReady={userProfileReady}
          fromCheckerLoaded={fromCheckerLoaded}
          becomeUserProfile={becomeUserProfile}
          deleteProfileFromBase={deleteProfileFromBase}
          serverUserError={serverUserError}
          fromUserLoaded={fromUserLoaded}
          loadingUserProcess={loadingUserProcess}
        />
      </Tab>
    </Tabs>
  );
};

export default DashboardTabs;
