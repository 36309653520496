import React from 'react';

import question from '../../../images/common/question.svg';
import s from './Label.module.css';
import { OverlayTrigger, Popover } from 'react-bootstrap';

import { useTranslation } from 'react-i18next';

const Label = ({ children, title, text }) => {
  const { t } = useTranslation();
  const popover = (
    <Popover id="popover-basic">
      <Popover.Title as="h3">{title}</Popover.Title>
      <Popover.Content>
        {`${t('Helpers.more')} `} {<a href={text}>{text}</a>}
      </Popover.Content>
    </Popover>
  );
  return (
    <div className={s.label__container}>
      <label>{children}</label>
      <OverlayTrigger placement="right" delay={{ show: 250, hide: 1300 }} overlay={popover}>
        <img src={question} alt={'question'} />
      </OverlayTrigger>
    </div>
  );
};

export default Label;
