// Components
import React from 'react';
import { Route } from 'react-router-dom';
import { Dashboard, Account, Intercassa, Paddle, Payssion, Checkout, Capitalist, Massgen, Enot } from '../container';
import Header from '../container/Header';

export const RoutesWithHeader = () => (
  <>
    <Route
      exact
      path="/account/checkout"
      component={() => (
        <>
          <Header />
          <Checkout />
        </>
      )}
    />
    <Route
      exact
      path="/massgen"
      component={() => (
        <>
          <Header />
          <Massgen />
        </>
      )}
    />
    <Route
      exact
      path="/account"
      component={() => (
        <>
          <Header />
          <Account />
        </>
      )}
    />
    <Route
      path="/dashboard"
      component={() => (
        <>
          <Header />
          <Dashboard />
        </>
      )}
    />
    <Route
      exact
      path={['/checkout/visa', '/checkout/paypal']}
      component={() => (
        <>
          <Header />
          <Paddle />
        </>
      )}
    />
    <Route
      exact
      path="/checkout/alipay"
      component={() => (
        <>
          <Header />
          <Payssion />
        </>
      )}
    />
    {/* <Route
      exact
      path={['/checkout/qiwi', '/checkout/advcash', '/checkout/perfectmoney']}
      component={() => (
        <>
          <Header />
          <Intercassa />
        </>
      )}
    /> */}
    <Route
      exact
      path={['/checkout/capitalist']}
      component={() => (
        <>
          <Header />
          <Capitalist />
        </>
      )}
    />
    <Route
      exact
      path={['/checkout/visa-ru', '/checkout/sbp', '/checkout/usdt', '/checkout/bitcoin']}
      component={() => (
        <>
          <Header />
          <Enot />
        </>
      )}
    />
  </>
);

export default RoutesWithHeader;
