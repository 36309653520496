import Dashboard from '../views/Dashboard/Dashboard';
import withLocation from '../hoc/withSettingLocation';
import WithAuthRedirect from '../hoc/withAuthRedirect';
import { connect } from 'react-redux';
import { compose } from 'redux';

const mapStateToProps = ({ user }) => ({
  login: user.login
});

export default compose(WithAuthRedirect, withLocation, connect(mapStateToProps))(Dashboard);
