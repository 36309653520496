import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import axios from 'axios';
import { MDBBtn, MDBInput } from 'mdbreact';

class Reset extends Component {
  state = {
    password: '',
    submit_password: '',
    resMessage: '',
    token: this.props.match.params.token
  };

  changeInputs = (e) => {
    let id = e.currentTarget.id;
    let value = e.currentTarget.value;
    switch (id) {
      case 'password':
        this.setState({ password: value });
        break;
      case 'submit_password':
        this.setState({ submit_password: value });
        break;
      default:
        break;
    }
  };

  onSendMail = (e) => {
    e.preventDefault();
    if (this.state.password !== this.state.submit_password) {
      this.setState({ resMessage: 'Error: Passwords do not match' });
      return;
    }
    if (this.state.password === '' || this.state.submit_password === '') {
      this.setState({ resMessage: 'Error: Password is empty' });
      return;
    }
    axios
      .post(
        process.env.REACT_APP_PUBLIC_API + '/api/reset/',
        {
          password: this.state.password,
          token: this.state.token
        },
        {
          headers: {
            Authorization: 'FORGOT-FORGOT-FORGOT'
          }
        }
      )
      .then((res) => {
        this.props.history.push('/');
      })
      .catch((err) => {
        if (err.response) {
          if (err.response.status === 404) {
            this.setState({
              auth: false,
              resMessage: 'User not found',
              firstStart: false
            });
            return;
          }
        }
        this.setState({
          auth: false,
          resMessage: 'server is unavailable',
          firstStart: false
        });
      });
  };

  render() {
    return (
      <div style={{ marginTop: 10 }}>
        <div className={'text-center'} style={{ margin: '0 auto' }}>
          <a href="https://aezakmi.run">
            <div className={'logo-div'} style={{ marginBottom: 20 }}></div>
          </a>
          <h3 style={{ marginBottom: 50 }}>Cheat code for your anonymity</h3>
        </div>

        <div className="card card-cascade wider mt-2 mb-3 auth-card">
          <div className="card-body card-body-cascade text-center">
            <div className={'form-header'}>
              <h4>
                <i className="material-icons" style={{ color: 'white' }}>
                  lock
                </i>{' '}
                LOGIN
              </h4>
            </div>
            <form onSubmit={this.onSendMail}>
              <MDBInput
                className={!this.state.firstStart && !this.state.auth ? 'form-control is-invalid' : ''}
                value={this.state.password}
                name="password"
                onChange={this.changeInputs}
                type="password"
                id="password"
                label="Password"
                required
              />

              <MDBInput
                className={!this.state.firstStart && !this.state.auth ? 'form-control is-invalid' : ''}
                value={this.state.submit_password}
                name="submit_password"
                onChange={this.changeInputs}
                type="password"
                id="submit_password"
                label="Submit Password"
                required
              >
                <div className="invalid-feedback">
                  <span>{this.state.resMessage}</span>
                </div>
              </MDBInput>
              <MDBBtn className={'hover-button'} color="yellow" type="submit" style={{ maxWidth: '50%', margin: '0 auto' }}>
                Reset
              </MDBBtn>
            </form>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(Reset);
