export const accountData = [
  {
    name: 'CJ MONTHLY',
    price: 69,
    license: 0
  },
  {
    name: 'BALLAS MONTHLY',
    price: 139,
    license: 1
  },
  {
    name: 'GROOVE STREET MONTHLY',
    price: 249,
    license: 2
  }
];

export const accountDataSixMonths = [
  {
    name: 'CJ 6 MONTHS',
    price: 345,
    license: 3
  },
  {
    name: 'BALLAS 6 MONTHS',
    price: 695,
    license: 4
  },
  {
    name: 'GROOVE STREET 6 MONTHS',
    price: 1245,
    license: 5
  }
];

export const accountDataYear = [
  {
    name: 'CJ 12 MONTHS',
    price: 690,
    license: 6
  },
  {
    name: 'BALLAS 12 MONTHS',
    price: 1390,
    license: 7
  },
  {
    name: 'GROOVE STREET 12 MONTHS',
    price: 2490,
    license: 7
  }
];
