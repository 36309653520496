import axios from 'axios';

import { PromoActions } from './';

const Actions = {
  setUserDataSuccess: (userObj) => ({
    type: 'USER:SET_DATA_SUCCESS',
    payload: userObj
  }),
  setUserDataFailure: () => ({
    type: 'USER:SET_DATA_FAILURE'
  }),
  setUserDataOnLOgout: () => ({ type: 'USER:SET_DATA_LOGOUT' }),
  fetchUserData: () => async (dispatch) => {
    const token = localStorage.getItem('token');
    await axios
      .get(process.env.REACT_APP_PUBLIC_API + '/api/getMe', {
        headers: { authorization: token }
      })
      .then((res) => {
        const { data } = res;
        // Config obj for user reducer
        const userObj = {
          login: data.login,
          mail: data.mail,
          licenseType: data.license,
          licenseStart: data.licenseStart,
          licenseStop: data.licenseStop,
          isLicenseActive: data.isLicenseActive,
          cancelSub: data.cancelSub
        };

        dispatch(Actions.setUserDataSuccess(userObj));
        dispatch(PromoActions.setPromoDataSuccess(data.promocode));
      })
      .catch((err) => {
        dispatch(Actions.setUserDataFailure());
        localStorage.removeItem('token');
      });
  }
};

export default Actions;
