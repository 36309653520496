import React from 'react';
import { Card, Col, Form, Row } from 'react-bootstrap';
import { MDBBtn } from 'mdbreact';
import { Label } from '../UI';
import MySwitch from '../UI/MySwith';

import { useTranslation } from 'react-i18next';

const Proxy = ({
  chProxy,
  proxyProtocolValid,
  proxyIpValid,
  proxyPortValid,
  loadingBaseProcess,
  loadingUserProcess,
  enable911Switch,
  fromCheckerLoaded,
  ProxyChecker,
  checkercode,
  proxyIp,
  changeInputs,
  proxyPort,
  proxyProtocol,
  proxyUser,
  proxyPassword,
  enable911SwitchChecked
}) => {
  const { t } = useTranslation();

  return (
    <Row>
      <Col md={12}>
        <Card>
          <h4 className={'text-center'}>{t('Dashboard.config.proxy.name')}</h4>

          <MDBBtn
            style={{
              maxWidth: 450,
              margin: '0 auto',
              marginBottom: 15,
              marginTop: 5
            }}
            color="yellow"
            className={'hover-button'}
            disabled={!chProxy || !proxyProtocolValid || !proxyIpValid || !proxyPortValid || loadingBaseProcess || loadingUserProcess || enable911Switch || checkercode === 0 || fromCheckerLoaded}
            onClick={() => ProxyChecker()}
          >
            {t('Dashboard.config.proxy.button')}
          </MDBBtn>
          <h4 className={'text-center'}>
            {checkercode !== 0 && checkercode !== 88 && checkercode !== '' ? (
              <span className="text-danger">{t('Dashboard.config.proxy.status.bad')}</span>
            ) : checkercode === 0 ? (
              <span className="text-success">{t('Dashboard.config.proxy.status.ok')}</span>
            ) : checkercode === 88 ? (
              <span className="text-danger">Too many requests</span>
            ) : fromCheckerLoaded ? (
              <span className="text-warning">{t('Dashboard.config.proxy.status.load')}</span>
            ) : (
              <span className="text-danger">{t('Dashboard.config.proxy.status.not')}</span>
            )}
          </h4>
          <Form noValidate validated={false}>
            <Row>
              <Form.Group as={Col} md="2">
                <Label title={t('Helpers.proxy.protocol.title')} text={t('Helpers.proxy.protocol.text')}>
                  {t('Dashboard.config.proxy.protocol')}
                </Label>
                <Form.Control
                  className="prof-select"
                  isInvalid={!proxyProtocolValid && chProxy}
                  required
                  disabled={!chProxy || fromCheckerLoaded || enable911Switch}
                  as="select"
                  onChange={(e) => changeInputs(e)}
                  value={proxyProtocol}
                  id="proxy-protocol"
                  type="text"
                >
                  <option></option>
                  <option>http</option>
                  <option>https</option>
                  <option>socks4</option>
                  <option>socks5</option>
                </Form.Control>
                <Form.Control.Feedback type="invalid">{t('Dashboard.config.proxy.error_protocol')}</Form.Control.Feedback>
              </Form.Group>

              <Form.Group as={Col} md="2">
                <Label title={t('Helpers.proxy.address.title')} text={t('Helpers.proxy.address.text')}>
                  {t('Dashboard.config.proxy.address')}
                </Label>
                <Form.Control
                  isInvalid={!proxyIpValid && chProxy}
                  required
                  disabled={!chProxy || fromCheckerLoaded || enable911Switch}
                  onChange={(e) => changeInputs(e)}
                  value={proxyIp}
                  id="proxy-ip"
                  type="text"
                />
                <Form.Control.Feedback type="invalid">{proxyIp === '' ? t('Dashboard.config.proxy.error_address') : t('Dashboard.config.proxy.incorrect_address')}</Form.Control.Feedback>
              </Form.Group>

              <Form.Group as={Col} md="2">
                <Label title={t('Helpers.proxy.port.title')} text={t('Helpers.proxy.port.text')}>
                  {t('Dashboard.config.proxy.port')}
                </Label>
                <Form.Control
                  isInvalid={!proxyPortValid && chProxy}
                  required
                  disabled={!chProxy || fromCheckerLoaded}
                  onChange={(e) => changeInputs(e)}
                  value={proxyPort}
                  id="proxy-port"
                  type="text"
                />
                <Form.Control.Feedback type="invalid">{t('Dashboard.config.proxy.error_port')}</Form.Control.Feedback>
              </Form.Group>

              <Form.Group as={Col} md="2">
                <Label title={t('Helpers.proxy.user.title')} text={t('Helpers.proxy.user.text')}>
                  {t('Dashboard.config.proxy.user')}
                </Label>
                <Form.Control
                  disabled={!chProxy || fromCheckerLoaded || proxyProtocol === 'socks4' || proxyProtocol === 'socks5'}
                  onChange={(e) => changeInputs(e)}
                  value={proxyUser}
                  id="proxy-user"
                  type="text"
                />
              </Form.Group>

              <Form.Group as={Col} md="2">
                <Label title={t('Helpers.proxy.password.title')} text={t('Helpers.proxy.password.text')}>
                  {t('Dashboard.config.proxy.password')}
                </Label>
                <Form.Control
                  disabled={!chProxy || fromCheckerLoaded || proxyProtocol === 'socks4' || proxyProtocol === 'socks5'}
                  onChange={(e) => changeInputs(e)}
                  value={proxyPassword}
                  id="proxy-password"
                  type="text"
                />
              </Form.Group>
              <Form.Group
                as={Col}
                md="1"
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  flexDirection: 'column'
                }}
              >
                <Label title={t('Helpers.proxy.911.title')} text={t('Helpers.proxy.911.text')} className="d-flex align-items-center">
                  911
                </Label>
                <MySwitch onChange={enable911SwitchChecked} checked={enable911Switch} />
              </Form.Group>
            </Row>
          </Form>
        </Card>
      </Col>
    </Row>
  );
};

export default Proxy;
