// Core
import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import axios from 'axios';

// Styles
import { MDBBtn, MDBInput } from 'mdbreact';
import './AuthForm.css';

// Components
import AuthHeader from './components/Header';
import Auth from './components/Auth';

// Utils
import { withCookies } from 'react-cookie';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';

//Redux
import { UserActions } from '../../redux/actions';
import { useDispatch } from 'react-redux';

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const AuthForm = (props) => {
  const query = useQuery();
  const dispatch = useDispatch();

  useEffect(() => {
    const enableToken = query.get('token');

    if (enableToken) {
      localStorage.setItem('token', enableToken);
      dispatch(UserActions.fetchUserData());
      history.push('/account');
    }
  }, []);

  const [error, setError] = useState('');
  const { history } = props;

  const toSignUp = () => {
    history.push('/signup');
  };

  const handleSubmit = async (login, password, setSubmitting) => {
    setSubmitting(true);
    axios
      .post(process.env.REACT_APP_PUBLIC_API + '/api/auth', {
        login,
        password
      })
      .then((res) => {
        localStorage.setItem('token', res.data.token);
        dispatch(UserActions.fetchUserData());
        history.push('/account');
      })
      .catch((err) => {
        if (err.response) {
          if (err.response.status === 401) {
            setError(err.response.data.message);
            setSubmitting(false);
            return;
          }
        }
        setError('Server is unavailable');
        setSubmitting(false);
      });
  };

  return (
    <div style={{ marginTop: 10 }}>
      <Formik
        initialValues={{ login: '', password: '' }}
        validationSchema={Yup.object().shape({
          login: Yup.string().required('This field must be required'),
          password: Yup.string().required('This field must be required')
        })}
        onSubmit={async (values, { setSubmitting }) => {
          const { login, password } = values;
          await handleSubmit(login, password, setSubmitting);
        }}
      >
        {(props) => {
          const { login, password, handleSubmit, handleChange, isSubmitting, touched, errors, handleBlur } = props;
          return (
            <>
              <AuthHeader />
              <Auth text={'Forgot your password?'} link={'/forgot'} title={'Login'}>
                <Form onSubmit={(e) => handleSubmit(e)}>
                  <MDBInput value={login} name="login" onChange={handleChange} type="text" id="login" label="Login" onBlur={handleBlur}>
                    {errors.login && touched.login && <div className={'error'}>{errors.login}</div>}
                  </MDBInput>
                  <MDBInput style={{ marginTop: 5 }} value={password} name="password" onChange={handleChange} type="password" id="password" label="Password" onBlur={handleBlur}>
                    {errors.password && touched.password && <div className={'error'}>{errors.password}</div>}
                  </MDBInput>
                  <div className={'error'}>{error}</div>
                  <MDBBtn type="submit" className={'hover-button margin-top-10'} color="yellow" style={{ width: 150, margin: '0 auto' }} disabled={isSubmitting}>
                    Log in
                  </MDBBtn>
                </Form>
                <MDBBtn className={'hover-button margin-top-10'} color="black" onClick={() => toSignUp()} style={{ width: 150, margin: '0 auto' }}>
                  Sign Up
                </MDBBtn>
              </Auth>
            </>
          );
        }}
      </Formik>
    </div>
  );
};

export default withCookies(AuthForm);
