import React from 'react';
import { Card, Col, Form, Row } from 'react-bootstrap';

const NavigationWebKit = ({
  changeInputs,
  userAgentConst,
  vendorSubConst,
  appVersionConst,
  appNameConst,
  appCodeNameConst,
  productConst,
  productSubConst,
  vendorConst,
  buildIDConst,
  platformConst,
  oscpuConst,
  uaFullversion,
  brands,
  platformUaData,
  platformVersionUaData
}) => {
  return (
    <Row>
      <Col md={12}>
        <Card className="options-card">
          <h4 className={'text-center matrix-text'}>Navigator</h4>
          <Row>
            <Col md={3}>
              <Form.Label>User agent</Form.Label>
              <Form.Control onChange={(e) => changeInputs(e)} value={userAgentConst} id="navigator-user-agent" type="text" />
            </Col>
            <Col md={3}>
              <Form.Label>App version</Form.Label>
              <Form.Control onChange={(e) => changeInputs(e)} value={appVersionConst} id="navigator-app-version" type="text" />
            </Col>
            <Col md={3}>
              <Form.Label>App name</Form.Label>
              <Form.Control onChange={(e) => changeInputs(e)} value={appNameConst} id="navigator-app-name" type="text" />
            </Col>
            <Col md={3}>
              <Form.Label>App code name</Form.Label>
              <Form.Control onChange={(e) => changeInputs(e)} value={appCodeNameConst} id="navigator-app-code-name" type="text" />
            </Col>
          </Row>
          <Row>
            <Col md={3}>
              <Form.Label>Product</Form.Label>
              <Form.Control onChange={(e) => changeInputs(e)} value={productConst} id="navigator-product" type="text" />
            </Col>
            <Col md={3}>
              <Form.Label>Product sub</Form.Label>
              <Form.Control onChange={(e) => changeInputs(e)} value={productSubConst} id="navigator-product-sub" type="text" />
            </Col>
            <Col md={3}>
              <Form.Label>Vendor</Form.Label>
              <Form.Control onChange={(e) => changeInputs(e)} value={vendorConst} id="navigator-vendor" type="text" />
            </Col>
            <Col md={3}>
              <Form.Label>Vendor sub</Form.Label>
              <Form.Control onChange={(e) => changeInputs(e)} value={vendorSubConst} id="navigator-vendor-sub" type="text" />
            </Col>
          </Row>
          <Row>
            <Col md={3}>
              <Form.Label>Brands</Form.Label>
              <Form.Control onChange={(e) => changeInputs(e)} value={brands} id="navigator-brands" type="text" />
            </Col>
            <Col md={3}>
              <Form.Label>Platform</Form.Label>
              <Form.Control onChange={(e) => changeInputs(e)} value={platformConst} id="navigator-platform" type="text" />
            </Col>
            <Col md={3}>
              <Form.Label>PlatformUaData</Form.Label>
              <Form.Control onChange={(e) => changeInputs(e)} value={platformUaData} id="navigator-platformUaData" type="text" />
            </Col>
          </Row>
          <Row>
            <Col md={3}>
              <Form.Label>PlatformVersion</Form.Label>
              <Form.Control onChange={(e) => changeInputs(e)} value={platformVersionUaData} id="navigator-platformVersionUaData" type="text" />
            </Col>
            <Col md={3}>
              <Form.Label>uaFullVersion</Form.Label>
              <Form.Control onChange={(e) => changeInputs(e)} value={uaFullversion} id="navigator-uaFullversion" type="text" />
            </Col>
          </Row>
        </Card>
      </Col>
    </Row>
  );
};

export default NavigationWebKit;
