import React from 'react';
import { NavLink } from 'react-router-dom';

import Agreement from './Agreement';

const Auth = ({ children, text, link, title, isSignUp = false }) => {
  return (
    <div className="card card-cascade wider mt-2 mb-3 auth-card">
      <div className="card-body card-body-cascade text-center">
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
          }}
          className={'form-header'}
        >
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center'
            }}
          >
            <i className="material-icons" style={{ color: 'white', height: 26 }}>
              lock
            </i>{' '}
            <h4 style={{ marginBottom: 0 }}>{title.toUpperCase()}</h4>
          </div>
        </div>
        {children}
      </div>
      <hr />
      {isSignUp && <Agreement />}
      {!isSignUp && (
        <NavLink to={link} activeClassName="active" style={{ maxWidth: '50%', marginLeft: 'auto' }}>
          {text}
        </NavLink>
      )}
    </div>
  );
};

export default Auth;
