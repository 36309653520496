import React, { useState, useEffect } from 'react';
import { MDBBtn } from 'mdbreact';
import { Col, Row, Card, Form } from 'react-bootstrap';
import MySwitch from '../UI/MySwith';

import { Label } from '../UI';

import { useTranslation } from 'react-i18next';

const ProfileSettings = ({
  createUserProfile,
  profileNameValid,
  changeInputs,
  checkProxy,
  profileName,
  chProxy,
  proxyReady,
  profileReady,
  moreSettings,
  moreSettingsChecked,
  profileSaved,
  fromCheckerLoaded,
  serverUserError,
  isUserProfileLoading
}) => {
  const handleCreateUserProfile = () => createUserProfile();
  const [buttonText, setButtonText] = useState('SAVE_FENGERPINT_DEFAULT');
  const [buttonColor, setButtonColor] = useState('yellow');

  // Внизу описаны 2 метода для изменения кнопки.
  // Они завязаны на 3х параметрах [serverBaseError, profileReady, isUserProfileLoading]
  // serverBaseError - Нету интернета или плохой ответ
  // profileReady - Профиль сгенерирован
  // isUserProfileLoading - Профиль в процессе генерации

  // Данная функция меняет цвет кнопки в зависимости от текущего стейта
  const handleUpdateButtonColor = () => {
    return serverUserError ? setButtonColor('red') : profileSaved ? setButtonColor('success') : isUserProfileLoading ? setButtonColor('grey') : setButtonColor('yellow');
  };

  // Данная функция меняет текст кнопки в зависимости от текущего стейта
  const handleUpdateButtonText = () => {
    return serverUserError
      ? setButtonText('SAVE_FENGERPINT_ERROR')
      : profileSaved
      ? setButtonText('SAVE_FENGERPINT_SUCCESS')
      : isUserProfileLoading
      ? setButtonText('SAVE_FENGERPINT_LOADING')
      : setButtonText('SAVE_FENGERPINT_DEFAULT');
  };

  // При изменении одного их трех указанных параметров
  // Запускает обе функции по смене цвета + текста
  useEffect(() => {
    handleUpdateButtonText();
    handleUpdateButtonColor();
  }, [serverUserError, profileSaved, isUserProfileLoading]);

  const { t } = useTranslation();
  return (
    <Card>
      <Row className={'d-flex align-items-center'}>
        <Col md={1}></Col>
        <Col md={5}>
          <Form.Control
            isInvalid={!profileNameValid}
            style={{ marginTop: 3 }}
            onChange={(e) => changeInputs(e)}
            placeholder={t('Dashboard.config.placeholder')}
            value={profileName}
            id="profile-name"
            type="text"
          />
        </Col>
        <Col md={4}>
          <MDBBtn
            block
            color={buttonColor}
            className={'hover-button'}
            disabled={(chProxy && !proxyReady) || !profileNameValid || profileName.length === 0 || !profileReady || profileSaved || fromCheckerLoaded}
            onClick={handleCreateUserProfile}
          >
            {t(buttonText)}
          </MDBBtn>
        </Col>
      </Row>

      <div>
        <Row style={{ marginTop: 20, paddingLeft: 15 }}>
          <Col sm={8} md={5} lg={3} style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Label title={t('Helpers.proxy.title')} text={t('Helpers.proxy.text')}>
              {t('Dashboard.config.proxy.title')}
            </Label>
            <MySwitch onChange={() => checkProxy()} checked={chProxy} disabled={fromCheckerLoaded} />
          </Col>
        </Row>
        <Row style={{ paddingLeft: 15 }}>
          <Col sm={8} md={5} lg={3} style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Label title={t('Helpers.advansed.title')} text={t('Helpers.advansed.text')}>
              {t('Dashboard.config.advanced.title')}
            </Label>
            <MySwitch onChange={() => moreSettingsChecked()} checked={moreSettings} />
          </Col>
        </Row>
      </div>
    </Card>
  );
};
export default ProfileSettings;
