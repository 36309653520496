import React from 'react';
import AccountItem from './AccountItems/AccountItem';
import { accountData, accountDataSixMonths, accountDataYear } from './AccountItems/AccountData';

const AccountSubsList = ({ handlePurchase }) => {
  const monthlySubs = accountData.map(({ name, price, license }) => <AccountItem key={license} name={name} price={price} license={license} handlePurchase={handlePurchase} />);

  const sixMonthSubs = accountDataSixMonths.map(({ name, price, license }) => (
    <AccountItem key={license} isSale={true} name={name} price={price} license={license} handlePurchase={handlePurchase} countOfMounts={1} color={'#008000'} />
  ));

  const yearSubs = accountDataYear.map(({ name, price, license }) => (
    <AccountItem key={license} isSale={true} name={name} price={price} license={license} handlePurchase={handlePurchase} countOfMounts={2} color={'#90ee90'} />
  ));

  return (
    <section style={{ paddingTop: 0 }} className="pricing">
      <div className="container">
        <div className="row" style={{ marginBottom: 20 }}>
          {monthlySubs}
        </div>
        <div className="row" style={{ marginBottom: 20 }}>
          {sixMonthSubs}
        </div>
        <div className="row" style={{ marginBottom: 20 }}>
          {yearSubs}
        </div>
      </div>
    </section>
  );
};

export default AccountSubsList;
