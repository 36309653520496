import React from 'react';
import Massgen from '../views/Massgen';
import withLocation from '../hoc/withSettingLocation';
import WithAuthRedirect from '../hoc/withAuthRedirect';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { NavLink } from 'react-router-dom';

import { Card } from 'react-bootstrap';
import { MDBBtn } from 'mdbreact';

const MassgenContainer = (props) => {
  if (props.licenseType === 'passenger') {
    return (
      <Card>
        <h1 className="buy_sunscription">Ooops...</h1>
        <h1 className="buy_sunscription">it's look like</h1>
        <h1 className="buy_sunscription">you don't have subsription</h1>

        <div>You can fix it in account page</div>
        <NavLink to="/account">
          <MDBBtn color="yellow">Buy Subscription</MDBBtn>
        </NavLink>
      </Card>
    );
  }
  return <Massgen {...props} />;
};

const mapStateToProps = ({ user }) => ({
  login: user.login,
  licenseType: user.licenseType
});

export default compose(WithAuthRedirect, withLocation, connect(mapStateToProps))(MassgenContainer);
