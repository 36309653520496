// Core
import React from 'react';
import { connect } from 'react-redux';

// Redux
import { CheckoutActions } from '../redux/actions';

// Components
import Intercassa from '../views/PaySystems/Intercassa';

// HOC
import { compose } from 'redux';
import withLocation from '../hoc/withSettingLocation';
import withAuthRedirect from '../hoc/withAuthRedirect';

const IntercassaContiner = (props) => {
  return <Intercassa {...props} />;
};

const mapStateToProps = ({ checkout }) => ({
  selectedGood: checkout.selectedGood,
  invoiceID: checkout.invoiceID,
  checkedPaySys: checkout.checkedPaySys,
  isInvoiceIdLoading: checkout.isInvoiceIdLoading
});

export default compose(withLocation, connect(mapStateToProps, CheckoutActions), withAuthRedirect)(IntercassaContiner);
