import React from 'react';
import { Col, Row, Card, Form } from 'react-bootstrap';

const DeviceHardware = ({ changeInputs, deviceMemoryConst, hardwareConcurrencyConst, fonts }) => {
  return (
    <Row>
      <Col md={12}>
        <Card>
          <h4 className={'text-center matrix-text'}>Device info</h4>
          <Row>
            <Col md={6}>
              <Form.Label>Memory</Form.Label>
              <Form.Control onChange={(e) => changeInputs(e)} value={deviceMemoryConst} id="device-memory" type="text" />
            </Col>
            <Col md={6}>
              <Form.Label>Hardware Concurrency</Form.Label>
              <Form.Control onChange={(e) => changeInputs(e)} value={hardwareConcurrencyConst} id="navigator-hardware-concurrency" type="text" />
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <Form.Label>Fonts</Form.Label>
              <Form.Control id="device-fonts" onChange={(e) => changeInputs(e)} value={fonts} type="text" />
            </Col>
          </Row>
        </Card>
      </Col>
    </Row>
  );
};

export default DeviceHardware;
