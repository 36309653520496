// Core
import React, { useState } from 'react';
import { connect } from 'react-redux';

// Redux
import { CheckoutActions } from '../redux/actions';

// Components
import Paddle from '../views/PaySystems/Paddle';

// HOC
import { compose } from 'redux';
import withLocation from '../hoc/withSettingLocation';
import withAuthRedirect from '../hoc/withAuthRedirect';

const VALIDATE_MAIL = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

const mapStateToProps = ({ user, checkout }) => ({
  checkedPaySys: checkout.checkedPaySys,
  invoiceID: checkout.invoiceID,
  cancelSub: user.cancelSub,
  selectedGood: checkout.selectedGood,
  isInvoiceIdLoading: checkout.isInvoiceIdLoading,
  mail: user.mail,
  validMail: checkout.validMail
});

const PaddleContainer = (props) => {
  const [notifyMail, updateNotifyMail] = useState(props.mail);
  const [validateMail, setValidateIsValidate] = useState(true);

  const handleUpdateNotifyMail = (e) => {
    updateNotifyMail(e.currentTarget.value);
    let validateMail = VALIDATE_MAIL.test(e.currentTarget.value);
    setValidateIsValidate(validateMail);
  };

  return <Paddle {...props} notifyMail={notifyMail} updateNotifyMail={handleUpdateNotifyMail} validateMail={validateMail} />;
};

export default compose(withLocation, connect(mapStateToProps, CheckoutActions), withAuthRedirect)(PaddleContainer);
