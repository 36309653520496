export const initialState = {
  login: null,
  mail: null,
  licenseType: null,
  cancelSub: null,
  isAuth: false,
  isLicenseActive: false,
  licenseStart: null,
  licenseStop: null
};

const UserReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'USER:SET_DATA_SUCCESS':
      return {
        ...state,
        isAuth: true,
        ...action.payload
      };

    case 'USER:SET_DATA_LOGOUT':
      return {
        login: null,
        mail: null,
        licenseType: null,
        cancelSub: null,
        isLicenseActive: false,
        licenseStart: null,
        licenseStop: null,
        isAuth: false
      };

    default:
      return {
        ...state
      };
  }
};

export default UserReducer;
