import React from 'react';

import s from './Footer.module.css';
import logo from '../../images/aezakmi.svg';

import { useTranslation } from 'react-i18next';

const Footer = () => {
  const { t } = useTranslation();
  return (
    <div className={s.footer__container}>
      <div className={s.footer__logo_container}>
        <img src={logo} alt="logo" />
      </div>
      <div className={s.footer__links_container}>
        <div className={s.footer__links_item}>
          <div>AEZAKMI</div>
          <ul>
            <li>
              <a href={t('Footer.aezakmi.proxy.link')}>{t('Footer.aezakmi.proxy.title')}</a>
            </li>
            <li>
              <a href={t('Footer.aezakmi.partners.link')}>{t('Footer.aezakmi.partners.title')}</a>
            </li>
          </ul>
        </div>
        <div className={s.footer__links_item}>
          <div>SUPPORT</div>
          <ul>
            <li>
              <a href={t('Footer.support.support.link')}>{t('Footer.support.support.title')}</a>
            </li>
            <li>
              <a href={t('Footer.support.help.link')}>{t('Footer.support.help.title')}</a>
            </li>
          </ul>
        </div>
        <div className={s.footer__links_item}>
          <div>DOWNLOAD</div>
          <ul>
            <li>
              <a href={'https://aezakmi.run/windows_download'}>{t('Download.windows')}</a>
            </li>
            <li>
              <a href={'https://aezakmi.run/downloadmacos'}>{t('Download.macos')}</a>
            </li>
            <li>
              <a href={t('Footer.download.windows.link')}>{t('Footer.download.windows.title')}</a>
            </li>
            <li>
              <a href={t('Footer.download.macos.link')}>{t('Footer.download.macos.title')}</a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};
export default Footer;
