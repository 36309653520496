// Components
import React from 'react';
import { Route } from 'react-router-dom';
import { Auth, SignUp } from '../container';
import Forgot from '../views/Forgot/Forgot';
import Reset from '../views/Reset';

export const RoutesWithoutHeader = () => (
  <>
    <Route exact path={['/', '/auth']} component={Auth} />
    <Route exact path="/signup" component={SignUp} />
    <Route exact path="/signupru" component={SignUp} />
    <Route exact path="/reset/:token" component={Reset} />
    <Route path="/forgot" component={Forgot} />
  </>
);

export default RoutesWithoutHeader;
