import React from 'react';
import { Alert } from 'react-bootstrap';
import { selectTime } from '../../../utils/helpers/selectTime';
import { useTranslation } from 'react-i18next';

const Header = ({ license, error, price, desc }) => {
  // Set current time for payment
  const selectedTime = selectTime(license);
  const { t } = useTranslation();
  return (
    <>
      <Alert style={{ background: '#F8BF64' }} variant='warning'>
        <span style={{ fontSize: 16, fontWeight: 500, color: '#000' }}>{t('Checkout.ifrus')}</span>
        &nbsp;
        <Alert.Link style={{ color: '#000', textDecoration: 'underline' }} href='https://t.me/aezakmisupport'>@aezakmisupport</Alert.Link>
      </Alert>
      <h1 style={{ paddingTop: '20px', fontWeight: '900' }}>
        Total: {price}$ per {selectedTime}
      </h1>
      <h3>{desc}</h3>
      <div className={'row'} hidden={!error}>
        {error}
      </div>
    </>
  );
};

export default Header;
