import React, { Component } from 'react';

import './css/bootstrap.css';
import 'mdbreact/dist/css/mdb.css';
import './css/App.css';
import 'antd/dist/antd.css';

import { WithHeader, WithoutHeader } from './routes';
import Footer from './views/Footer/Footer';
import ScrollToTop from './views/common/ScrollToTop';
import { Col, Container } from 'react-bootstrap';

import { connect } from 'react-redux';
import { compose } from 'redux';

import { AppActions } from './redux/actions';

import { CookiesProvider } from 'react-cookie';
import { Prealoader } from './utils/preloader/Preloader';
import { Switch } from 'react-router-dom';

class App extends Component {
  componentDidMount() {
    this.props.initializeApp();
  }

  render() {
    if (!this.props.initialized) {
      return (
        <div className="preloader">
          <Prealoader />
        </div>
      );
    }

    return (
      <CookiesProvider>
        <ScrollToTop>
          <Switch>
            <>
              <Container>
                <Col className="app__conatainer" md={12}>
                  <WithHeader />
                  <WithoutHeader />
                </Col>
              </Container>
            </>
          </Switch>
        </ScrollToTop>
        <Footer />
      </CookiesProvider>
    );
  }
}

let mapStateToProps = ({ app }) => ({
  initialized: app.initialized
});

export default compose(connect(mapStateToProps, AppActions))(App);
