import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import Header from '../views/Header/Header';

const mapStateToProps = ({ user, account }) => ({
  login: user.login,
  isLicenseActive: user.isLicenseActive
});

const HeaderContainer = (props) => {
  return <Header {...props} />;
};

export default compose(connect(mapStateToProps), withRouter)(HeaderContainer);
