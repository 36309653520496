import Account from '../views/Account/Account';
import { connect } from 'react-redux';
import { compose } from 'redux';
import WithAuthRedirect from '../hoc/withAuthRedirect';
import withLocation from '../hoc/withSettingLocation';
import { CheckoutActions } from '../redux/actions/';

const mapStateToProps = ({ user, checkout }) => ({
  licenseType: user.licenseType,
  licenseStop: user.licenseStop,
  login: user.login,
  cancelSub: user.cancelSub,
  goods: checkout.goods,
  isLicenseActive: user.isLicenseActive
});

export default compose(withLocation, connect(mapStateToProps, CheckoutActions), WithAuthRedirect)(Account);
