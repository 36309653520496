import React, { useEffect, useState, useRef } from 'react';

import { Button, Tooltip, Overlay } from 'react-bootstrap';
import s from './Token.module.css';

import { useTranslation } from 'react-i18next';

const Token = () => {
  const token = useRef();

  const { t } = useTranslation();

  useEffect(() => {
    const data = localStorage.getItem('token');
    token.current = data;
  }, []);

  // ToolTip
  const [show, setShow] = useState(false);
  const [tooltipText, setTooltipText] = useState(null);
  const target = useRef(null);

  const handleClose = () =>
    setTimeout(() => {
      setShow(false);
    }, 3000);

  const handleCopy = () => {
    const text = token.current;

    navigator.clipboard.writeText(text).then(
      function () {
        setShow(true);
        setTooltipText(t('API.success'));
        handleClose();
      },
      function () {
        setShow(true);
        setTooltipText(t('API.error'));
        handleClose();
      }
    );
  };

  return (
    <div className={s.token_container}>
      <span>Token API</span>
      <div>
        <Button ref={target} onClick={handleCopy}>
          {t('API.copy')}
        </Button>
        <Overlay target={target.current} show={show} placement="bottom">
          {(props) => (
            <Tooltip variant={'success'} {...props}>
              {tooltipText}
            </Tooltip>
          )}
        </Overlay>
      </div>
    </div>
  );
};

export default Token;
