const initialState = {
  enablePromo: false,
  promoCheck: false,
  promoValue: '',
  serverBaseError: false
};

const PromoReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'PROMO:SET_DATA_SUCCESS':
      return {
        ...state,
        enablePromo: action.enablePromo !== '' ? false : true
      };
    case 'PROMO:SET_SERVER_BASE_ERROR':
      return {
        ...state,
        serverBaseError: action.payload
      };
    case 'PROMO:SET_USED_PROMO':
      return {
        ...state,
        promoCheck: true
      };
    case 'PROMO:UPDATE_PROMO_VALUE':
      return {
        ...state,
        promoValue: action.payload
      };
    default:
      return {
        ...state
      };
  }
};

export default PromoReducer;
