import React, { useState, useEffect, useMemo } from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import { MDBBtn } from 'mdbreact';
import MySwitch from '../../UI/MySwith';
import { Label } from '../../UI';
import TabItem from './TabItem';

import json from '../../../json/preProfiles.json';

import { useTranslation } from 'react-i18next';

const CreateNewProfileTab = ({
  oses,
  browsers,
  chbrowser,
  chos,
  changeForm,
  chwidthXheight,
  widthXheight,
  chunmaskedRendererWebgl,
  unmaskedRendererWebgls,
  currentProfileReady,
  fromCheckerLoaded,
  becomeProfile,
  serverBaseError,
  profileReady,
  loadingBaseProcess,
  toRandomProfile
}) => {
  const { t } = useTranslation();

  // Выбор будет создаваться рандлмный профиль или нет
  const [random, setRandom] = useState(false);
  const [googleMode, setGoogleMode] = useState(false);
  const [realScreen, setRealScreen] = useState(false);

  const [buttonText, setButtonText] = useState('GENERATE_DEFAULT');
  const [buttonColor, setButtonColor] = useState('yellow');

  // Внизу описаны 2 метода для изменения кнопки.
  // Они завязаны на 3х параметрах [serverBaseError, profileReady, loadingBaseProcess]
  // serverBaseError - Нету интернета или плохой ответ
  // profileReady - Профиль сгенерирован
  // loadingBaseProcess - Профиль в процессе генерации

  // Данная функция меняет цвет кнопки в зависимости от текущего стейта
  const handleUpdateButtonColor = () => {
    return serverBaseError ? setButtonColor('red') : profileReady ? setButtonColor('success') : loadingBaseProcess ? setButtonColor('grey') : setButtonColor('yellow');
  };

  // Данная функция меняет текст кнопки в зависимости от текущего стейта
  const handleUpdateButtonText = () => {
    return serverBaseError
      ? setButtonText('GENERATE_ERROR')
      : profileReady
      ? setButtonText('GENERATE_SUCCESS')
      : loadingBaseProcess
      ? setButtonText('GENERATE_LOADING')
      : setButtonText('GENERATE_DEFAULT');
  };

  // При изменении одного их трех указанных параметров
  // Запускает обе функции по смене цвета + текста
  useEffect(() => {
    handleUpdateButtonText();
    handleUpdateButtonColor();
  }, [serverBaseError, profileReady, loadingBaseProcess]);

  const handleGenerateFingerprint = () => becomeProfile(googleMode, realScreen);

  const handleSetRandom = () => {
    setGoogleMode(false);
    setRealScreen(false);
    setRandom((prev) => !prev);
    toRandomProfile();
  };

  const handleSetGoogleMode = () => {
    setRandom(false);
    setGoogleMode((prev) => !prev);
  };

  const handleSetRealScreen = () => {
    setRandom(false);
    setRealScreen((prev) => !prev);
  };

  const getDataFromJSON = (json) => {
    const str = JSON.stringify(json);
    const data = JSON.parse(str);
    let arr = [];
    data.map(({ screen }) => {
      const height = screen.height;
      const width = screen.width;
      let res = `${width}x${height}`;
      if (arr.indexOf(res) !== -1) return arr;
      return arr.push(res);
    });
    return arr;
  };

  const diagonals = useMemo(() => getDataFromJSON(json), [json]);

  const handleChangeForm = (e) => {
    changeForm(e, random, realScreen);
  };

  return (
    <Row>
      <Col md={12}>
        <Card>
          <Row>
            <Col sm={8} md={5} lg={3} style={{ display: 'flex', justifyContent: 'space-between' }}>
              <Label title={t('Helpers.random.title')} text={t('Helpers.random.text')}>
                {t('Dashboard.config.random')}
              </Label>
              <MySwitch onChange={handleSetRandom} checked={random} />
            </Col>
          </Row>

          <Row>
            <Col sm={8} md={5} lg={3} style={{ display: 'flex', justifyContent: 'space-between' }}>
              <Label title="Real Screen" text="Real Screen">
                {t('Dashboard.config.realScreen')}
              </Label>
              <MySwitch onChange={handleSetRealScreen} checked={realScreen} />
            </Col>
          </Row>

          <Row>
            <Col sm={8} md={5} lg={3} style={{ display: 'flex', justifyContent: 'space-between' }}>
              <Label title="Google Mode" text={t('GOOGLE_MODE_LINK')}>
                {t('Dashboard.config.googleMode')}
              </Label>
              <MySwitch onChange={handleSetGoogleMode} checked={googleMode} />
            </Col>
          </Row>

          {googleMode && <div style={{ color: 'red' }}>{t('GOOGLE_MODE_NOTIFICATION')}</div>}

          <Row>
            <TabItem onChange={handleChangeForm} disabled={random} value={chos} title={t('Dashboard.config.system')} transtaionType="system" data={oses} id={'os'} />
            <TabItem onChange={handleChangeForm} disabled={random} value={chbrowser} title={t('Dashboard.config.browser')} transtaionType="browser" data={browsers} id={'browser'} />
          </Row>
          <Row>
            <TabItem
              title={t('Dashboard.config.screen')}
              onChange={handleChangeForm}
              disabled={realScreen}
              transtaionType="screen"
              value={chwidthXheight}
              data={random ? diagonals : widthXheight}
              rand={random ? true : false}
              id={'ScreenResolution'}
            />

            <TabItem
              title={t('Dashboard.config.videocard')}
              transtaionType="videocard"
              onChange={handleChangeForm}
              disabled={random}
              value={chunmaskedRendererWebgl}
              data={unmaskedRendererWebgls}
              id={'unmaskedRendererWebgl'}
            />
          </Row>
          <Row>
            <Col md={4}></Col>
            <Col md={4}>
              <MDBBtn color={buttonColor} style={{ marginTop: 15 }} className="text-center hover-button" block disabled={!currentProfileReady || fromCheckerLoaded} onClick={handleGenerateFingerprint}>
                {t(buttonText)}
              </MDBBtn>
            </Col>
            <Col md={4}></Col>
          </Row>
        </Card>
      </Col>
    </Row>
  );
};

export default CreateNewProfileTab;
