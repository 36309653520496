// Core
import React from 'react';

// Styles
import Button from 'react-bootstrap/Button';
import s from './AccountItem.module.css';

import { useTranslation } from 'react-i18next';

const AccountItem = ({ name, price, handlePurchase, license, countOfMounts = 1, isSale = false, color = '#90ee90' }) => {
  const { t } = useTranslation();
  const colorPicker =
    color === '#90ee90' ? (
      <div className={s.wrap_green}>
        <span className={s.ribbon_green}>
          <span>
            {countOfMounts} {t('Tariff.free')}
          </span>
        </span>
      </div>
    ) : (
      <div className={s.wrap_red}>
        <span className={s.ribbon_red}>
          <span>
            {countOfMounts} {t('Tariff.free')}
          </span>
        </span>
      </div>
    );
  const ribbon = isSale ? colorPicker : null;
  return (
    <div className="col-lg-4" style={{ position: 'relative' }}>
      <div style={{ height: '190px' }} className="card mb-5 mb-lg-0">
        <div className="card-body">
          {ribbon}
          <h5 className="card-title text-muted text-uppercase text-center" style={{ fontSize: 17, whiteSpace: 'nowrap' }}>
            {name}
          </h5>
          <h6 className="card-price text-center">${price}</h6>
          <hr />
        </div>
        <Button
          style={{
            margin: '0 auto',
            fontWeight: 'bold',
            marginTop: -20,
            zIndex: 99
          }}
          variant="warning"
          onClick={() => {
            handlePurchase(license);
          }}
        >
          {t('Tariff.buy')}
        </Button>
      </div>
    </div>
  );
};

export default AccountItem;
