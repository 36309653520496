// Core
import React from 'react';

// Styles
import { Card, Col, Row } from 'react-bootstrap';
import s from './Cookie.module.css';

const Comment = ({ note, handleSetNote }) => {
  const [error, setError] = React.useState(false);

  const handleValidateNote = (e) => {
    setError(false);
    const regexp = /[а-яё]/i;
    const value = e.currentTarget.value;
    if (regexp.test(value)) {
      setError(true);
      return;
    }
    handleSetNote(value);
  };
  return (
    <>
      <Row>
        <Col md={12}>
          <Card className={s.card}>
            <Row className="justify-content-center">
              <h5 style={{ fontWeight: 'bold' }}>COMMENT</h5>
            </Row>
            <Row className="justify-content-center">
              <span style={{ fontWeight: 'bold' }}>{error && 'Russian Language is not supported'}</span>
            </Row>

            <textarea placeholder="Enter comment" maxLength="180" rows="10" value={note} onChange={handleValidateNote} id="cookie" type="text" />
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default Comment;
