// Core
import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';
import { Formik, Form } from 'formik';
import axios from 'axios';

// Styles
import { MDBBtn, MDBInput } from 'mdbreact';

// Components
import AuthHeader from '../Auth/components/Header';
import Auth from '../Auth/components/Auth';

// Other
import { withCookies } from 'react-cookie';
import * as Yup from 'yup';

const Forgot = (props) => {
  const [serverResponse, setServerResponse] = useState('');

  const { history } = props;

  const toSingIn = () => {
    history.push('/auth');
  };

  const handleSendMail = async (login, mail, setSubmitting) => {
    axios
      .post(
        process.env.REACT_APP_PUBLIC_API + '/api/forgot',
        {
          login,
          mail
        },
        {
          headers: {
            Authorization: 'FORGOT-FORGOT-FORGOT'
          }
        }
      )
      .then((res) => {
        setServerResponse(res.data.message);
      })
      .catch((err) => {
        if (err.response) {
          if (err.response.status === 404) {
            setServerResponse(err.response.data.message);
            setSubmitting(false);
            return;
          }
        }
        setServerResponse('Server is unavailable');
      });
  };
  return (
    <div style={{ marginTop: 10 }}>
      <Formik
        initialValues={{
          login: '',
          mail: '',
          telegram: '',
          password: '',
          submitPassword: ''
        }}
        validationSchema={Yup.object().shape({
          login: Yup.string().required('This field must be required'),
          mail: Yup.string().email('Mail must be a valid email').required('This field must be required')
        })}
        onSubmit={async (values, { setSubmitting, props }) => {
          const { login, mail } = values;
          await handleSendMail(login, mail, setSubmitting);
        }}
      >
        {(props) => {
          const { login, mail, errors, handleSubmit, handleChange, isSubmitting, touched, handleBlur } = props;

          const disabled = isSubmitting || errors.mail || errors.login;

          return (
            <>
              <AuthHeader />
              <Auth text="Dont have account?" link="/signup" title="Forgot">
                <Form onSubmit={(e) => handleSubmit(e)}>
                  <MDBInput value={login} name="login" onChange={handleChange} type="text" id="login" label="Login" onBlur={handleBlur}>
                    {errors.login && touched.login && <div className={'error'}>{errors.login}</div>}
                  </MDBInput>
                  <MDBInput style={{ marginTop: 5 }} value={mail} name="mail" onChange={handleChange} type="text" id="mail" label="Mail" onBlur={handleBlur}>
                    {errors.mail && touched.mail && <div className={'error'}>{errors.mail}</div>}
                  </MDBInput>
                  <div className={'error'}>{serverResponse}</div>
                  <MDBBtn type="submit" className={'hover-button margin-top-10'} color="yellow" style={{ width: 150, margin: '0 auto' }} disabled={disabled}>
                    Reset
                  </MDBBtn>
                </Form>
                <MDBBtn type="submit" className={'hover-button margin-top-10'} color="black" style={{ width: 150, margin: '0 auto' }} disabled={isSubmitting} onClick={() => toSingIn()}>
                  Sign In
                </MDBBtn>
              </Auth>
            </>
          );
        }}
      </Formik>
    </div>
  );
};

export default withCookies(withRouter(Forgot));
