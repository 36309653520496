// Core
import React from 'react';

// Styles
import s from './Promo.module.css';
import { Button } from 'react-bootstrap';
import Input from '@material-ui/core/Input';
import { useTranslation } from 'react-i18next';

const Promo = ({ enablePromo, promoValue, selectedGood, promoCheck, updatePromoValue, onPromo }) => {
  const { t } = useTranslation();
  return (
    <div className={s.container}>
      <Input value={promoValue} onChange={(e) => updatePromoValue(e)} placeholder={t('Promos.placeholder')} />
      <Button
        size="sm"
        type="submit"
        disabled={promoValue.length === 0 || promoCheck}
        onClick={() => {
          onPromo(enablePromo, promoValue, selectedGood);
        }}
        variant="warning"
      >
        {t('Promos.button')}
      </Button>
    </div>
  );
};

export default Promo;
