import React from 'react';
import { Card, Col, Form, Row } from 'react-bootstrap';

const WebGLCanvas = ({
  changeInputs,
  unmaskedVendorWebgl,
  unmaskedRendererWebgl,
  webglRenderer,
  webglVendor,
  shadinglanguageversion,
  glVersion,
  aliasedLineWidthRange,
  aliasedPointSizeRange,
  alphaBits,
  blueBits,
  maxDrawBuffers,
  maxTextureMaxAnisotropyExt,
  maxVertexattribs,
  maxCombinedTextureImageUnits,
  maxCubeMapTextureSize,
  maxRenderBufferSize,
  maxTextureSize,
  maxFragmentUniformVectors,
  maxVertexuniformvectors,
  maxTextureImageUnits,
  maxVaryingVectors,
  maxVertexTextureImageUnits,
  maxVertexUniformComponents,
  maxVertexUniformBlocks,
  maxVertexOutputComponents,
  maxVaryingComponents,
  maxTransformFeedbackInterleavedComponents,
  maxTransformFeedbackSeparateAttribs,
  maxTransformFeedbackSeparateComponents,
  maxFragmentUniformComponents,
  maxFragmentUniformBlocks,
  maxFragmentInputComponents,
  maxCombinedUniformBlocks,
  maxCombinedVertexUniformComponents,
  minProgramTexelOffset,
  maxProgramTexelOffset,
  maxColorAttachments,
  maxSamples,
  max3dTextureSize,
  maxArrayTextureLayers,
  maxTextureLodBias,
  maxUniformBufferBindings,
  maxUniformBlockSize,
  uniformBufferOffsetAlignment,
  maxViewportDims,
  webglFloatPrecision,
  maxCombinedFragmentUniformComponents
}) => {
  return (
    <Row>
      <Col md={12}>
        <Card>
          <h4 className={'text-center matrix-text'}>WebGL</h4>
          <Row>
            <Col md={6}>
              <Form.Label>Unmasked Vendor</Form.Label>
              <Form.Control onChange={(e) => changeInputs(e)} value={unmaskedVendorWebgl} id="webgl-unmasked-vendor" type="text" />
            </Col>
            <Col md={6}>
              <Form.Label>Unmasked Renderer</Form.Label>
              <Form.Control onChange={(e) => changeInputs(e)} value={unmaskedRendererWebgl} id="webgl-unmasked-renderer" type="text" />
            </Col>
          </Row>
          <Row>
            <Col md={3}>
              <Form.Label>Renderer info</Form.Label>
              <Form.Control onChange={(e) => changeInputs(e)} value={webglRenderer} id="webgl-renderer-info" type="text" />
            </Col>
            <Col md={3}>
              <Form.Label>Vendor info</Form.Label>
              <Form.Control onChange={(e) => changeInputs(e)} value={webglVendor} id="webgl-vendor-info" type="text" />
            </Col>
            <Col md={3}>
              <Form.Label>Shading Language Version</Form.Label>
              <Form.Control onChange={(e) => changeInputs(e)} value={shadinglanguageversion} id="webgl-shading-language-version" type="text" />
            </Col>
            <Col md={3}>
              <Form.Label>GL Version</Form.Label>
              <Form.Control onChange={(e) => changeInputs(e)} value={glVersion} id="webgl-gl-version" type="text" />
            </Col>
          </Row>
          <Row>
            <Col md={3}>
              <Form.Label>Aliased Line Width Range</Form.Label>
              <Form.Control onChange={(e) => changeInputs(e)} value={aliasedLineWidthRange} id="webgl-aliased-line-width-range" type="text" />
            </Col>
            <Col md={3}>
              <Form.Label>Aliased Point Size Range</Form.Label>
              <Form.Control onChange={(e) => changeInputs(e)} value={aliasedPointSizeRange} id="webgl-aliased-point-size-range" type="text" />
            </Col>
            <Col md={3}>
              <Form.Label>Alpha bits</Form.Label>
              <Form.Control onChange={(e) => changeInputs(e)} value={alphaBits} id="webgl-alpha-bits" type="text" />
            </Col>
            <Col md={3}>
              <Form.Label>Blue bits</Form.Label>
              <Form.Control onChange={(e) => changeInputs(e)} value={blueBits} id="webgl-blue-bits" type="text" />
            </Col>
          </Row>
          <Row>
            <Col md={3}>
              <Form.Label>Max draw buffers</Form.Label>
              <Form.Control onChange={(e) => changeInputs(e)} value={maxDrawBuffers} id="webgl-max-draw-buffers" type="text" />
            </Col>
            <Col md={3}>
              <Form.Label>Max anisotropy</Form.Label>
              <Form.Control onChange={(e) => changeInputs(e)} value={maxTextureMaxAnisotropyExt} id="webgl-max-anisotropy" type="text" />
            </Col>
            <Col md={3}>
              <Form.Label>Max vertex attributes</Form.Label>
              <Form.Control onChange={(e) => changeInputs(e)} value={maxVertexattribs} id="webgl-max-vertex-attributes" type="text" />
            </Col>
            <Col md={3}>
              <Form.Label>Max combined texture image units</Form.Label>
              <Form.Control onChange={(e) => changeInputs(e)} value={maxCombinedTextureImageUnits} id="webgl-max-combined-texture-image-units" type="text" />
            </Col>
          </Row>
          <Row>
            <Col md={3}>
              <Form.Label>Max cube map texture size</Form.Label>
              <Form.Control onChange={(e) => changeInputs(e)} value={maxCubeMapTextureSize} id="webgl-max-cube-map-texture-size" type="text" />
            </Col>
            <Col md={3}>
              <Form.Label>Max render buffer size</Form.Label>
              <Form.Control onChange={(e) => changeInputs(e)} value={maxRenderBufferSize} id="webgl-max-render-buffer-size" type="text" />
            </Col>
            <Col md={3}>
              <Form.Label>Max texture size</Form.Label>
              <Form.Control onChange={(e) => changeInputs(e)} value={maxTextureSize} id="webgl-max-texture-size" type="text" />
            </Col>
            <Col md={3}>
              <Form.Label>Max fragment uniform vectors</Form.Label>
              <Form.Control onChange={(e) => changeInputs(e)} value={maxFragmentUniformVectors} id="webgl-max-fragment-uniform-vectors" type="text" />
            </Col>
          </Row>
          <Row>
            <Col md={3}>
              <Form.Label>Max vertex uniform vectors</Form.Label>
              <Form.Control onChange={(e) => changeInputs(e)} value={maxVertexuniformvectors} id="webgl-max-vertex-uniform-vectors" type="text" />
            </Col>
            <Col md={3}>
              <Form.Label>Max texture image units</Form.Label>
              <Form.Control onChange={(e) => changeInputs(e)} value={maxTextureImageUnits} id="webgl-max-texture-image-units" type="text" />
            </Col>
            <Col md={3}>
              <Form.Label>Max varying vectors</Form.Label>
              <Form.Control onChange={(e) => changeInputs(e)} value={maxVaryingVectors} id="webgl-max-varying-vectors" type="text" />
            </Col>
            <Col md={3}>
              <Form.Label>Max vertex texture image units</Form.Label>
              <Form.Control onChange={(e) => changeInputs(e)} value={maxVertexTextureImageUnits} id="webgl-max-vertex-texture-image-units" type="text" />
            </Col>
          </Row>
          <Row>
            <Col md={3}>
              <Form.Label>Max vertex uniform components</Form.Label>
              <Form.Control onChange={(e) => changeInputs(e)} value={maxVertexUniformComponents} id="webgl-max-vertex-uniform-components" type="text" />
            </Col>
            <Col md={3}>
              <Form.Label>Max vertex uniform blocks</Form.Label>
              <Form.Control onChange={(e) => changeInputs(e)} value={maxVertexUniformBlocks} id="webgl-max-vertex-uniform-blocks" type="text" />
            </Col>
            <Col md={3}>
              <Form.Label>Max vertex output components</Form.Label>
              <Form.Control onChange={(e) => changeInputs(e)} value={maxVertexOutputComponents} id="webgl-max-vertex-output-components" type="text" />
            </Col>
            <Col md={3}>
              <Form.Label>Max varying components</Form.Label>
              <Form.Control onChange={(e) => changeInputs(e)} value={maxVaryingComponents} id="webgl-max-varying-components" type="text" />
            </Col>
          </Row>
          <Row>
            <Col md={3}>
              <Form.Label>Max interleaved components</Form.Label>
              <Form.Control onChange={(e) => changeInputs(e)} value={maxTransformFeedbackInterleavedComponents} id="webgl-max-interleaved-components" type="text" />
            </Col>
            <Col md={3}>
              <Form.Label>Max separate attribs</Form.Label>
              <Form.Control onChange={(e) => changeInputs(e)} value={maxTransformFeedbackSeparateAttribs} id="webgl-max-separate-attribs" type="text" />
            </Col>
            <Col md={3}>
              <Form.Label>Max separate components</Form.Label>
              <Form.Control onChange={(e) => changeInputs(e)} value={maxTransformFeedbackSeparateComponents} id="webgl-max-separate-components" type="text" />
            </Col>
            <Col md={3}>
              <Form.Label>Max fragment uniform components</Form.Label>
              <Form.Control onChange={(e) => changeInputs(e)} value={maxFragmentUniformComponents} id="webgl-max-fragment-uniform-components" type="text" />
            </Col>
          </Row>

          <Row>
            <Col md={3}>
              <Form.Label>Max fragment uniform blocks</Form.Label>
              <Form.Control onChange={(e) => changeInputs(e)} value={maxFragmentUniformBlocks} id="webgl-max-fragment-uniform-blocks" type="text" />
            </Col>
            <Col md={3}>
              <Form.Label>Max fragment input components</Form.Label>
              <Form.Control onChange={(e) => changeInputs(e)} value={maxFragmentInputComponents} id="webgl-max-fragment-input-components" type="text" />
            </Col>

            <Col md={3}>
              <Form.Label>Max combined uniform blocks</Form.Label>
              <Form.Control onChange={(e) => changeInputs(e)} value={maxCombinedUniformBlocks} id="webgl-max-combined-uniform-blocks" type="text" />
            </Col>
            <Col md={3}>
              <Form.Label>Max combined vertex uniform components</Form.Label>
              <Form.Control onChange={(e) => changeInputs(e)} value={maxCombinedVertexUniformComponents} id="webgl-combined-vertex-uniform-components" type="text" />
            </Col>
          </Row>
          <Row>
            <Col md={3}>
              <Form.Label>Min program texel offset</Form.Label>
              <Form.Control onChange={(e) => changeInputs(e)} value={minProgramTexelOffset} id="webgl-min-program-texel-offset" type="text" />
            </Col>
            <Col md={3}>
              <Form.Label>Max program texel offset</Form.Label>
              <Form.Control onChange={(e) => changeInputs(e)} value={maxProgramTexelOffset} id="webgl-max-program-texel-offset" type="text" />
            </Col>
            <Col md={3}>
              <Form.Label>Max draw buffers 2</Form.Label>
              <Form.Control onChange={(e) => changeInputs(e)} value={maxDrawBuffers} id="webgl-max-draw-buffers-2" type="text" />
            </Col>
            <Col md={3}>
              <Form.Label>Max color attachments</Form.Label>
              <Form.Control onChange={(e) => changeInputs(e)} value={maxColorAttachments} id="webgl-max-color-attachments" type="text" />
            </Col>
          </Row>
          <Row>
            <Col md={3}>
              <Form.Label>Max samples</Form.Label>
              <Form.Control onChange={(e) => changeInputs(e)} value={maxSamples} id="webgl-max-samples" type="text" />
            </Col>
            <Col md={3}>
              <Form.Label>Max 3d texture size</Form.Label>
              <Form.Control onChange={(e) => changeInputs(e)} value={max3dTextureSize} id="webgl-max-3d-texture-size" type="text" />
            </Col>
            <Col md={3}>
              <Form.Label>Max array texture layers</Form.Label>
              <Form.Control onChange={(e) => changeInputs(e)} value={maxArrayTextureLayers} id="webgl-max-array-texture-layers" type="text" />
            </Col>
            <Col md={3}>
              <Form.Label>Max texture LOD bias</Form.Label>
              <Form.Control onChange={(e) => changeInputs(e)} value={maxTextureLodBias} id="webgl-max-texture-lod-bias" type="text" />
            </Col>
          </Row>
          <Row>
            <Col md={3}>
              <Form.Label>Max uniform buffer bindings</Form.Label>
              <Form.Control onChange={(e) => changeInputs(e)} value={maxUniformBufferBindings} id="webgl-max-uniform-buffer-bindings" type="text" />
            </Col>
            <Col md={3}>
              <Form.Label>Max uniform block size</Form.Label>
              <Form.Control onChange={(e) => changeInputs(e)} value={maxUniformBlockSize} id="webgl-max-uniform-block-size" type="text" />
            </Col>
            <Col md={3}>
              <Form.Label>Uniform buffer offset alignment</Form.Label>
              <Form.Control onChange={(e) => changeInputs(e)} value={uniformBufferOffsetAlignment} id="webgl-uniform-buffer-offset-alignment" type="text" />
            </Col>
            <Col md={3}>
              <Form.Label>Max view port dims</Form.Label>
              <Form.Control onChange={(e) => changeInputs(e)} value={maxViewportDims} id="webgl-max-view-port-dims" type="text" />
            </Col>
          </Row>
          <Row>
            <Col md={10}>
              <Form.Label>WebGL extentions</Form.Label>
              <Form.Control onChange={(e) => changeInputs(e)} value={webglFloatPrecision.toString().replace(/['""]/g, '')} id="webgl-support" type="text" />
            </Col>
            <Col md={2}>
              <Form.Label>Max combined fragment uniform components</Form.Label>
              <Form.Control onChange={(e) => changeInputs(e)} value={maxCombinedFragmentUniformComponents} id="webgl-max-combined-fragment-uniform-components" type="text" />
            </Col>
          </Row>
        </Card>
      </Col>
    </Row>
  );
};

export default WebGLCanvas;
