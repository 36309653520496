// Core
import React from 'react';

// Styles
import { DropdownButton, Dropdown } from 'react-bootstrap';

// Translate
import i18next from 'i18next';

import { useTranslation } from 'react-i18next';

const SelectLanguage = () => {
  const handleClick = (lang) => i18next.changeLanguage(lang);
  const { t } = useTranslation();

  return (
    <DropdownButton style={{ marginRight: 10 }} title={t('Account.3')} size="sm" variant="warning">
      <Dropdown.Item onClick={() => handleClick('en')}>English</Dropdown.Item>
      <Dropdown.Item onClick={() => handleClick('chi')}>中文</Dropdown.Item>
      <Dropdown.Item onClick={() => handleClick('ru')}>Русский</Dropdown.Item>
      <Dropdown.Item onClick={() => handleClick('pt')}>Português</Dropdown.Item>
    </DropdownButton>
  );
};

export default SelectLanguage;
