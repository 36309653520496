export const selectTime = (license) => {
  switch (license) {
    case 1:
      return 'month';
    case 2:
      return 'month';
    case 3:
      return 'month';
    case 4:
      return '6 month';
    case 5:
      return '6 month';
    case 6:
      return '6 month';
    case 7:
      return 'year';
    case 8:
      return 'year';
    case 9:
      return 'year';
    default:
      return 'month';
  }
};
