// Core
import React from 'react';

//Components
import License from './License/License';
import AccountSubsList from './AccountSubsList';
import Token from './Token/Token';

const Paddle = window.Paddle;

const Account = (props) => {
  const { login, goods, toPurchase, cancelSub, isLicenseActive, licenseStop, licenseType, isLoading } = props;

  const handlePurchase = (goodID) => {
    toPurchase(goodID, goods, login);
    props.history.push('/account/checkout');
  };

  const removeSub = () => {
    Paddle.Checkout.open({
      override: cancelSub,
      successCallback: (data, err) => {
        // initAccountState();
        // TODO: Get data
        // getME
      }
    });
  };
  return (
    <div>
      <License isLicenseActive={isLicenseActive} licenseStop={licenseStop} licenseType={licenseType} cancelSub={cancelSub} removeSub={removeSub} isLoading={isLoading} />
      <Token />

      <AccountSubsList handlePurchase={handlePurchase} />
    </div>
  );
};

export default Account;
