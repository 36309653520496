import React from 'react';
import { Form, useFormik, FormikProvider } from 'formik';
import * as axios from 'axios';
import * as Yup from 'yup';
import json from '../../json/preProfiles.json';
import { useTranslation } from 'react-i18next';

import s from './Massgen.module.css';
import { Card } from 'react-bootstrap';

import { CreateNotification } from '../UI';

import MySwitch from '../../views/UI/MySwith';

// And now we can use these
const Massgen = (props) => {
  const { t } = useTranslation();

  const MassgenSchema = Yup.object().shape({
    profilesNames: Yup.string()
      .required(t('MASSGEN.REQUIRED'))
      .matches(/(^[a-zA-Z0-9_\-\(\)]{1,30}$)/, {
        message: t('MASSGEN.INVALID_NAME'),
        excludeEmptyString: true
      }),
    count: Yup.string()
      .max(3, t('MASSGEN.NO_MORE_THEN_100'))
      .required(t('MASSGEN.REQUIRED'))
      .matches(/^\d{1,13}$/, {
        message: t('MASSGEN.ENTER_NUNBER'),
        excludeEmptyString: true
      }),
    screen: Yup.string().required(t('MASSGEN.REQUIRED')),
    videocard: Yup.string().required(t('MASSGEN.REQUIRED'))
  });

  const [serverSuccess, setServerSuccess] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);
  const [serverError, setServerError] = React.useState(false);

  const [errors, setErrors] = React.useState(false);
  const [canvasNoise, setCanvasNoise] = React.useState(true);
  const [webglNoise, setWebglNoise] = React.useState(true);
  const [audioNoise, setAudioNoise] = React.useState(true);
  const [doNotTrack, setDoNotTrack] = React.useState(false);
  const [ObserverWebRTC, setObserverWebRTC] = React.useState(true);

  const handleChandgeCanvasNoise = () => setCanvasNoise(!canvasNoise);
  const handleChandgeWebglNoise = () => setWebglNoise(!webglNoise);
  const handleChandgeAudioNoise = () => setAudioNoise(!audioNoise);
  const handleChandgeDoNotTrack = () => setDoNotTrack(!doNotTrack);
  const handleChangeObserverWebRTC = () => setObserverWebRTC(!ObserverWebRTC);

  const getDataFromJSON = (json) => {
    const str = JSON.stringify(json);
    const data = JSON.parse(str);
    let arr = [];
    data.map(({ screen }) => {
      const height = screen.height;
      const width = screen.width;
      let res = `${width}x${height}`;
      if (arr.indexOf(res) !== -1) return arr;
      return arr.push(res);
    });
    return arr;
  };

  const getVideocards = (json) => {
    const data = JSON.parse(JSON.stringify(json));
    const width = +formik.values.screen.split('x')[0];
    const height = +formik.values.screen.split('x')[1];
    let videocards = [];
    /*
    // Для начала сортируем массив
    // Достаем из него только те профиля,
    // Которые подходят под актуальное разрешение экрана выбранное пользователем 
    */
    data
      .filter(({ screen }) => screen.width === width && screen.height === height)
      .map(({ webglparams }) => {
        /*
        // Достаем видеокарту из отсортированного массива с отпечатками 
        */
        const { UNMASKED_RENDERER_WEBGL } = webglparams.webgl2.EXTENSIONS.WEBGL_debug_renderer_info;
        /*
        // Проверяем есть ли у нас уже такая видеокарта в списке актуальных видеокард 
        */
        const data = videocards.find((el) => el === UNMASKED_RENDERER_WEBGL);
        if (!data) {
          videocards = [...videocards, UNMASKED_RENDERER_WEBGL];
        }
      });

    return videocards;
  };

  const handleSubmit = async (values) => {
    let body;
    try {
      // Разделяем прокси регуляркой по строчке
      const proxy = values.proxyStr === '' ? undefined : values.proxyStr.split(/\r?\n/);

      const cookies = values.cookies === '' ? undefined : values.cookies.split(/\r?\n/);

      let base64Cookies = [];

      if (cookies) {
        cookies.forEach((el) => {
          base64Cookies = [...base64Cookies, Buffer.from(el).toString('base64')];
        });
      }

      // Counts приходит как строка, должная отправляться как число
      // Проверям на кол-во отпечатков, если больше 2 то идем дальше.
      if (+values.count <= 2) return setErrors(t('MASSGEN.MORE_THEN_2'));

      // Составляем запрос из основных папрметров.
      // { count, profileName, canvasNoize, webglNoize, audioNoize,
      // doNotTrack, enable911, ObserverWebRTC, timestamp }

      const width = +values.screen.split('x')[0];
      const height = +values.screen.split('x')[1];
      body = {
        count: +values.count,
        profileName: values.profilesNames,
        canvasNoise: canvasNoise,
        webglNoise: webglNoise,
        audioNoise: audioNoise,
        doNotTrack: doNotTrack,
        enable911: false,
        proxy: proxy,
        cookies: base64Cookies,
        videocard: values.videocard,
        width: width,
        height: height,
        ObserverWebRTC: true,
        timestamp: new Date().getTime()
      };
    } catch (err) {
      return setErrors(t('MASSGEN.GENERATE_ERROR'));
    }

    setErrors(false);
    setIsLoading(true);
    setServerError(false);
    setServerSuccess(false);
    const token = localStorage.getItem('token');
    axios
      .post(`${process.env.REACT_APP_PUBLIC_API}/api/create_multiply_profile`, body, {
        headers: {
          authorization: token
        }
      })
      .then((data) => {
        setIsLoading(false);
        setServerSuccess(true);
        CreateNotification({
          title: t('MASSGEN.SUCCESS_TITLE'),
          description: t('MASSGEN.GENERATE_SUCCESS'),
          type: 'success'
        });
        return;
      })
      .catch((err) => {
        if (err.response) {
          const { message } = err.response.data;
          setIsLoading(false);
          setServerError(true);

          const responseText =
            message === 'Access is denied'
              ? 'MASSGEN.MASSGEN_GENERATE_ERRROR_PASSENGER'
              : message === 'The number of prints exceeds your capacity.'
              ? 'MASSGEN.MASSGEN_GENERATE_ERRROR_COUNT'
              : 'MASSGEN.GENERATE_ERROR';

          CreateNotification({
            title: t('MASSGEN.ERROR'),
            description: t(responseText),
            type: 'error'
          });
        }
      });
  };
  const formik = useFormik({
    initialValues: {
      profilesNames: '',
      count: '',
      screen: '',
      videocard: '',
      cookies: '',
      proxyStr: ''
    },
    validationSchema: MassgenSchema,
    onSubmit: (values) => {
      handleSubmit(values);
    }
  });

  const diagonals = React.useMemo(() => getDataFromJSON(json), [json]);
  const videocards = React.useMemo(() => getVideocards(json), [json, formik.values.screen]);

  return (
    <Card>
      <FormikProvider value={formik}>
        <Form style={{ display: 'flex', flexDirection: 'column' }}>
          <div className={s.massgen_step_container}>
            <div className={s.massgen_step_counter}>1</div>
            <div style={{ fontSize: 19, fontWeight: 500 }}>{t('MASSGEN.BASIC_SETTINGS')}</div>
          </div>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              flexWrap: 'wrap',
              marginBottom: 10
            }}
          >
            <div
              style={{
                marginRight: 20,
                position: 'relative',
                display: 'flex',
                flexDirection: 'column'
              }}
              className={`form-group ${formik.errors.profilesNames && formik.touched.profilesNames ? s.field_error : ''}`}
            >
              <label htmlFor="profilesNames">{t('MASSGEN.FINGERPRINT_NAME')}</label>
              <input style={{ maxWidth: 300 }} {...formik.getFieldProps('profilesNames')} type="text" className="form-control" id="profilesNames" placeholder={t('MASSGEN.FINGERPRINT_NAME')}></input>
              {formik.errors.profilesNames && formik.touched.profilesNames ? <div className={s.field_error__text}>{formik.errors.profilesNames}</div> : null}
            </div>

            <div
              style={{
                marginRight: 20,
                position: 'relative',
                display: 'flex',
                flexDirection: 'column'
              }}
              className={`form-group ${formik.errors.count && formik.touched.count ? s.field_error : ''}`}
            >
              <label htmlFor="profilesNames">{t('MASSGEN.FINGERPRINT_COUNT')}</label>
              <input style={{ maxWidth: 300 }} type="text" className="form-control" id="count" placeholder={t('MASSGEN.FINGERPRINT_COUNT')} {...formik.getFieldProps('count')}></input>
              {formik.errors.count && formik.touched.count ? <div className={s.field_error__text}>{formik.errors.count}</div> : null}
            </div>

            <div
              style={{
                marginRight: 20,
                position: 'relative',
                display: 'flex',
                flexDirection: 'column'
              }}
              className={`form-group ${formik.errors.screen && formik.touched.screen ? s.field_error : ''}`}
            >
              <label htmlFor="screen">{t('MASSGEN.SCREEN_RESOLUTION')}</label>
              <select {...formik.getFieldProps('screen')} id="screen" className={`custom-select sources ${s.select}`}>
                <option value="" disabled selected>
                  {t('MASSGEN.SCREEN_RESOLUTION')}
                </option>
                {diagonals &&
                  diagonals.map((item, i) => (
                    <option value={item} key={i}>
                      {item}
                    </option>
                  ))}
              </select>
              {formik.errors.screen && formik.touched.screen ? <div className={s.field_error__text}>{formik.errors.screen}</div> : null}
            </div>

            <div
              style={{
                position: 'relative',
                display: 'flex',
                flexDirection: 'column',
                width: 300
              }}
              className={`form-group ${formik.errors.videocard && formik.touched.videocard ? s.field_error : ''}`}
            >
              <label htmlFor="screen">{t('MASSGEN.VIDEOCARD')}</label>
              <select disabled={!formik.values.screen} {...formik.getFieldProps('videocard')} id="videocard" className={`custom-select sources ${s.select}`}>
                <option value="" disabled selected>
                  {t('MASSGEN.VIDEOCARD')}
                </option>
                {videocards &&
                  videocards.map((item, i) => (
                    <option value={item} key={i}>
                      {item}
                    </option>
                  ))}
              </select>
              {formik.errors.videocard && formik.touched.videocard ? <div className={s.field_error__text}>{formik.errors.videocard}</div> : null}
            </div>
          </div>

          <div className={s.massgen_step_container}>
            <div className={s.massgen_step_counter}>2</div>
            <div style={{ fontSize: 19, fontWeight: 500 }}>{t('MASSGEN.ADV_MODE')}</div>
          </div>
          <div className={s.switch_container}>
            <div className={s.switch_group}>
              <div className={s.switch_item}>
                <span>Canvas Noise</span>
                <div className={s.switch_item__switcher}>
                  <MySwitch onChange={handleChandgeCanvasNoise} checked={canvasNoise} />
                </div>
              </div>
              <div className={s.switch_item}>
                <span>WebGl Noise</span>
                <div className={s.switch_item__switcher}>
                  <MySwitch onChange={handleChandgeWebglNoise} checked={webglNoise} />
                </div>
              </div>
            </div>

            <div className={s.switch_group}>
              <div className={s.switch_item}>
                <span>Audio Noise</span>
                <div className={s.switch_item__switcher}>
                  <MySwitch onChange={handleChandgeAudioNoise} checked={audioNoise} />
                </div>
              </div>
              <div className={s.switch_item}>
                <span>Do Not Track</span>
                <div className={s.switch_item__switcher}>
                  <MySwitch onChange={handleChandgeDoNotTrack} checked={doNotTrack} />
                </div>
              </div>
            </div>
            <div className={s.switch_group}>
              <div className={s.switch_item}>
                <span>ObserverWebRTC</span>
                <div className={s.switch_item__switcher}>
                  <MySwitch onChange={handleChangeObserverWebRTC} checked={ObserverWebRTC} />
                </div>
              </div>
            </div>
          </div>

          <div className={s.massgen_step_container}>
            <div className={s.massgen_step_counter}>3</div>
            <div style={{ fontSize: 19, fontWeight: 500 }}>{t('MASSGEN.COOKIE_SETTINGS')}</div>
          </div>

          <div className={s.information_text}>{t('MASSGEN.COOKIE_SETTINGS_TEXT')}</div>

          <textarea style={{ borderRadius: 7, minHeight: 150 }} name="cookies" value={formik.values.cookies} onChange={formik.handleChange}></textarea>

          <div className={s.massgen_step_container}>
            <div className={s.massgen_step_counter}>4</div>
            <div style={{ fontSize: 19, fontWeight: 500 }}>{t('MASSGEN.PROXY_SETTINGS')}</div>
          </div>

          <div className={s.information_text}>{t('MASSGEN.PROXY_SETTINGS_TEXT')}</div>

          <textarea style={{ borderRadius: 7, minHeight: 150 }} name="proxyStr" value={formik.values.proxyStr} onChange={formik.handleChange}></textarea>

          <button className={s.create_btn} disabled={isLoading} type="submit">
            {serverError ? t('MASSGEN.GENERATE_ERROR') : serverSuccess ? t('MASSGEN.GENERATE_SUCCESS') : isLoading ? t('MASSGEN.GENERATE_LOADING') : t('MASSGEN.GENERATE_DEFAULT')}
          </button>
          {errors && (
            <div style={{ marginTop: 10 }} className={s.field_error}>
              {errors}
            </div>
          )}
        </Form>
      </FormikProvider>
    </Card>
  );
};

export default Massgen;
