import React from 'react';
import { Row, Col } from 'react-bootstrap';
import Promo from './PormoCode/Promo';
import { useTranslation } from 'react-i18next';

const Promos = ({ checkedPaySys, onPromo, enablePromo, promoValue, selectedGood, promoCheck, updatePromoValue }) => {
  const { t } = useTranslation();
  const { license } = selectedGood;
  const promo = (checkedPaySys === 'visa' || checkedPaySys === 'paypal') ? (
    <div style={{ marginBottom: '30px', marginTop: '29px' }}>
      <span style={{ fontWeight: 500 }}>{t('Promos.paddle')}</span>
    </div>
  ) : (
    <Promo enablePromo={enablePromo} promoValue={promoValue} selectedGood={selectedGood} promoCheck={promoCheck} updatePromoValue={updatePromoValue} onPromo={onPromo} />
  )
  return (
    <div
      style={{
        minHeight: '50px',
        display: 'flex',
        alignItems: 'center'
      }}
    >
      {(license === 1 || license === 2 || license === 3 || license === 4 || license === 5 || license === 6) && (
        <>
          <Row>
            <Col>{promo}</Col>
          </Row>
        </>
      )}
    </div>
  );
};

export default Promos;
