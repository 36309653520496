import React from 'react';
import { Col, Row, Card, Form } from 'react-bootstrap';
import MySwitch from '../../UI/MySwith';

const Scren = ({ changeInputs, widthvar, heightvar, availHeighthvar, availWidthvar, colorDepthvar, pixelDepthvar, availLeftvar, availTopvar, spoofingScreenSwitchChecked, spoofingScreenSwitch }) => {
  return (
    <Row>
      <Col md={12}>
        <Card>
          <h4 className={'text-center matrix-text'}>Screen</h4>
          <Row>
            <Col md={2}>
              <Form.Label className="d-flex align-items-center">Spoofing Screen</Form.Label>
              <MySwitch onChange={spoofingScreenSwitchChecked} checked={spoofingScreenSwitch} />
            </Col>
          </Row>
          <Row>
            <Col md={3}>
              <Form.Label>Width</Form.Label>
              <Form.Control onChange={(e) => changeInputs(e)} value={widthvar} id="screen-width" type="text" />
            </Col>
            <Col md={3}>
              <Form.Label>Height</Form.Label>
              <Form.Control onChange={(e) => changeInputs(e)} value={heightvar} id="screen-height" type="text" />
            </Col>
            <Col md={3}>
              <Form.Label>Avail height</Form.Label>
              <Form.Control onChange={(e) => changeInputs(e)} value={availHeighthvar} id="screen-avail-height" type="text" />
            </Col>
            <Col md={3}>
              <Form.Label>Avail width</Form.Label>
              <Form.Control onChange={(e) => changeInputs(e)} value={availWidthvar} id="screen-avail-width" type="text" />
            </Col>
          </Row>
          <Row>
            <Col md={3}>
              <Form.Label>Color depth</Form.Label>
              <Form.Control onChange={(e) => changeInputs(e)} value={colorDepthvar} id="screen-color-depth" type="text" />
            </Col>
            <Col md={3}>
              <Form.Label>Pixel depth</Form.Label>
              <Form.Control onChange={(e) => changeInputs(e)} value={pixelDepthvar} id="screen-pixel-depth" type="text" />
            </Col>
          </Row>
          <Row>
            <Col md={3}>
              <Form.Label>Avail left</Form.Label>
              <Form.Control onChange={(e) => changeInputs(e)} value={availLeftvar} id="screen-avail-left" type="text" />
            </Col>
            <Col md={3}>
              <Form.Label>Avail top</Form.Label>
              <Form.Control onChange={(e) => changeInputs(e)} value={availTopvar} id="screen-avail-top" type="text" />
            </Col>
          </Row>
          <Row>
            <Col md={3}>
              <Form.Label>Outer width</Form.Label>
              <Form.Control onChange={(e) => changeInputs(e)} value={availWidthvar} id="screen-outer-width" type="text" />
            </Col>
            <Col md={3}>
              <Form.Label>Outer height</Form.Label>
              <Form.Control onChange={(e) => changeInputs(e)} value={availHeighthvar} id="screen-outer-height" type="text" />
            </Col>
          </Row>
        </Card>
      </Col>
    </Row>
  );
};

export default Scren;
