import React from 'react';
import { NavLink } from 'react-router-dom';

// Styles
import { Navbar } from 'react-bootstrap';
import 'material-design-icons/iconfont/material-icons.css';
import s from './Header.module.css';
import { useDispatch } from 'react-redux';
import SelectLanguage from '../SelectLanguage.jsx';
import { useTranslation } from 'react-i18next';

const Header = (props) => {
  const dispatch = useDispatch();

  const { t } = useTranslation();

  const logOut = () => {
    dispatch({ type: 'USER:SET_DATA_LOGOUT' });
    localStorage.removeItem('token');
    return props.history.push('/auth');
  };
  return (
    <Navbar className={s.container} expand="lg">
      <Navbar.Brand>
        <img style={{ maxHeight: 50 }} src="LOGO.png" rounded="true" align="center" alt="logo" />
      </Navbar.Brand>

      <Navbar.Toggle aria-controls="responsive-navbar-nav" />
      <Navbar.Collapse style={{ justifyContent: 'space-between' }} id="responsive-navbar-nav">
        <div className={s.navbar_container}>
          <div className={s.navigation}>
            <NavLink to="/dashboard" activeClassName={s.active} className={s.link}>
              {t('Account.2')}
            </NavLink>
            <div className="new_feature_container">
              <NavLink to="/massgen" activeClassName={s.active} className={`${s.link} new_feature`}>
                {t('Account.4')}
              </NavLink>
            </div>
            <NavLink to="/account" activeClassName={s.active} className={s.link}>
              {t('Account.1')}
            </NavLink>
            <a className={s.link} href={t('Account.helpDocksLink')}>
              FAQ
            </a>
          </div>

          <div className={s.logout}>
            <SelectLanguage />
            <span>{props.login}</span>
            <button className={'log-out-btn'} onClick={() => logOut()}>
              <i className="material-icons" style={{ color: 'black' }}>
                exit_to_app
              </i>
            </button>
          </div>
        </div>
      </Navbar.Collapse>
    </Navbar>
  );
};

export default Header;
