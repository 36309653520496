import user from './user';
import promo from './promo';
import app from './app';
import checkout from './checkout';
import { combineReducers } from 'redux';

export default combineReducers({
  user,
  promo,
  checkout,
  app
});
