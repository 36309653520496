import React from 'react';
import { Row, Form, Col } from 'react-bootstrap';
import { useDispatch } from 'react-redux';

import PayMethodItem from './PayMethodItem';

import { paypal, mastercard, visa, alipay, bitcoin, capitalist, enot } from '../../Account/images';
import bitcoinEnot from '../../../images/Enot/bitcoin.svg';
import sbpEnot from '../../../images/Enot/sbp.svg';
import usdtEnot from '../../../images/Enot/usdt.svg';
import visaPaddle from '../../../images/Paddle/visa.png';
import payPalPaddle from '../../../images/Paddle/paypal.png';
import visaEnot from '../../../images/Enot/visa.svg';

const PayMethods = ({ selectedGood, checkedPaySys }) => {
  const dispacth = useDispatch();

  const setPaySys = (payload) => {
    dispacth({ type: 'SET:CHECKED_PAY_SYSTEM', payload });
  };

  return (
    <>
      {/* <Row style={{ justifyContent: 'start', alignItems: 'center', maxWidth: 500 }}>
        <PayMethodItem margin={'20px'} name="visa" height="35" setPaySys={setPaySys} image={visaPaddle} checkedPaySys={checkedPaySys} desc="For non-RU customers" />
        <PayMethodItem margin={'20px'} name="paypal" height="20" setPaySys={setPaySys} image={payPalPaddle} checkedPaySys={checkedPaySys} />
      </Row> */}
      <Row style={{ justifyContent: 'start', alignItems: 'center', maxWidth: 500 }}>
        <PayMethodItem margin={'20px'} name="visa-ru" height="35" setPaySys={setPaySys} image={visaEnot} checkedPaySys={checkedPaySys} />
        <PayMethodItem margin={'20px'} name="sbp" height="35" setPaySys={setPaySys} image={sbpEnot} checkedPaySys={checkedPaySys} />
      </Row>
      <Row style={{ justifyContent: 'start', alignItems: 'center', maxWidth: 500 }}>
        <PayMethodItem margin={'20px'} name="bitcoin" height="35" setPaySys={setPaySys} image={bitcoinEnot} checkedPaySys={checkedPaySys} />
        <PayMethodItem margin={'20px'} name="usdt" height="35" setPaySys={setPaySys} image={usdtEnot} checkedPaySys={checkedPaySys} />
      </Row>
      <Row style={{ justifyContent: 'start', alignItems: 'center', maxWidth: 500 }}>
        <PayMethodItem margin={'20px'} name="capitalist" height="35" setPaySys={setPaySys} image={capitalist} checkedPaySys={checkedPaySys} />
        <PayMethodItem margin={'25px'} name="alipay" height="25" setPaySys={setPaySys} image={alipay} checkedPaySys={checkedPaySys} />
      </Row>
    </>
  );
};

export default PayMethods;
